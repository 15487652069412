.link {
  text-decoration: none;
  transition: all var(--animation-standard);
  transition-property: color, fill, opacity;
}

.primary.dark,
.primary.light {
  color: var(--carrot-2);
  fill: var(--carrot-2);
}

.primary.dark.high-contrast,
.primary.light.high-contrast {
  color: var(--carrot-3);
  fill: var(--carrot-3);
}

.secondary.dark {
  color: var(--kale-3);
  fill: var(--kale-3);
}

.secondary.light {
  color: var(--white);
  fill: var(--white);
}

.tertiary.dark,
.tertiary.light {
  color: var(--charcoal-1);
  fill: var(--charcoal-1);
}

.link:not(.disabled):hover,
.link:not(.disabled).hover {
  color: var(--carrot-3);
  fill: var(--carrot-3);
}

.link:active,
.link.active {
  color: var(--carrot-3);
  fill: var(--carrot-3);
}

.link.disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
