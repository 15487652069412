.container {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.horizontalSnap {
  box-sizing: border-box;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
}

.message,
.dots {
  text-align: center;
  margin: 0 auto;
}

.arrow-button {
  position: absolute;
  background: transparent;
  border: none;
  height: 72px;
  width: 72px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  z-index: 1;
}

.arrow-button:disabled {
  opacity: 0.25;
}

.forward-button {
  right: 0;
}

.is-not-wide .message {
  margin-top: var(--margin-sm);
}

.is-not-wide .dots {
  margin-top: var(--margin-md);
}

.is-wide .message {
  margin-top: var(--margin-md);
}

.is-wide .dots {
  margin-top: var(--margin-md);
}
