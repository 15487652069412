.root {
  position: fixed;
  overflow-y: auto;
}

.root ul {
  margin: 0;
  padding: 0;
  margin-top: var(--margin-xl);
}

.root ul li {
  margin: 0;
  padding: 0;
}

.root ul li > * {
  display: block;
  padding-left: var(--margin-md);
  margin-bottom: var(--margin-md);
}

.activeLink {
  box-shadow: inset 2px 0px var(--carrot-3);
}
