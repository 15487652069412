.formContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.inputSection {
  @media (--viewport-md) {
    display: grid;
    column-gap: var(--margin-md);
    grid-template-columns: 1fr 1fr;
  }
}

.inputSection > div {
  max-width: inherit;
  grid-column: auto / span 1;
}

.inputSection > div:nth-last-child(1),
.inputSection > div:nth-last-child(2) {
  grid-column: auto / span 2;
  max-width: inherit;
}
.inputSection > div:last-child {
  grid-row: auto / span 4;
}

.messagesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
}

.linkContainer {
  background-color: var(--carrot-0);
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: var(--margin-sm);
  align-items: center;

  @media (--viewport-xl) {
    align-items: center;
    background-color: unset;
    margin: unset;
  }

  .submitButton {
    @media (--viewport-xl) {
      margin-top: var(--margin-sm);
      margin-left: var(--margin-sm);
    }
  }
}

.linkContainerUpdate {
  background-color: var(--kale-0);

  @media (--viewport-xl) {
    background-color: unset;
  }
}

.successContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: var(--margin-md) 0;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: var(--margin-md);

  @media (--viewport-xl) {
    align-items: start;
  }
}
