.accordion {
  width: 100%;
}

/* accordion panel override */
.accordion > div {
  border-top: unset;
  border-bottom: 1px solid var(--blueberry-1) !important;
}

.accordion > div:first-child > button:first-child {
  margin-top: 0;
}

.accordion button {
  text-align: left;
}
