.container {
  background-color: var(--blueberry-0);
  margin-top: calc(var(--tfd-spacing-xxl) * -1);
}

.svgContainerMedium {
  display: inline-flex;
  width: 72px;
  height: 72px;
  align-items: center;
  justify-content: center;
  background-color: var(--oyster-0);
  svg {
    height: 48px;
  }
}

.browseIndicationsButton {
  margin-top: var(--margin-lg);
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
}

.card {
  overflow: hidden;
  background-color: white;
  height: 100%;
  border-radius: var(--tfd-border-radius-lg);
}

.cardContent {
  height: 100%;
}

.card img {
  width: 100%;
}

.cardTextContainer {
  padding: var(--margin-md);
  height: 100%;
}

.viewVeterinaryGuideButton,
.cardButton {
  /* Overwrite the corgi-x styles */
  display: flex !important;
  gap: var(--margin-xs);
  align-items: center;
}

.cardButton {
  width: 100%;
  gap: 10px;
  margin-top: var(--margin-md);
}

.viewVeterinaryGuideButton svg {
  width: 20px;
}

.cardButton svg {
  width: 12px;
  margin-top: 3px;
}

@media (--viewport-lg) {
  .heading {
    /* This number is not in the CORGI-X system */
    margin-bottom: 28px;
  }

  .browseIndicationsButton {
    margin-top: var(--margin-xl);
  }

  .topOverviewImageContainer {
    display: flex;
    justify-content: flex-end;
  }

  .card {
    max-width: unset;
  }

  .cardTextContainer {
    flex: 1;
    padding: var(--margin-lg);
  }

  .cardImageContainer {
    width: 186px;
    flex-shrink: 0;
    height: auto;
  }

  .cardImageContainer img {
    vertical-align: bottom;
    height: 100%;
    object-fit: cover;
  }
}
