.root {
  --span-1: 8.333%;
  --offset-1: calc(1 - 0.0833);
  --span-2: 16.666%;
  --offset-2: calc(1 - 0.1666);
  --span-3: 25%;
  --offset-3: calc(1 - 0.25);
  --span-4: 33.333%;
  --offset-4: calc(1 - 0.3333);
  --span-5: 41.666%;
  --offset-5: calc(1 - 0.4166);
  --span-6: 50%;
  --offset-6: calc(1 - 0.5);
  --span-7: 58.333%;
  --offset-7: calc(1 - 0.5833);
  --span-8: 66.666%;
  --offset-8: calc(1 - 0.6666);
  --span-9: 75%;
  --offset-9: calc(1 - 0.75);
  --span-10: 83.333%;
  --offset-10: calc(1 - 0.8333);
  --span-11: 91.666%;
  --offset-11: calc(1 - 0.9166);
  --span-12: 100%;
  --offset-12: 0;

  box-sizing: border-box;
  flex: 0 0 auto;
}

.flex {
  display: flex;
}

@media (--viewport-xs) {
  .col-xs-1 {
    width: calc(var(--span-1) - var(--column-gap) * var(--offset-1));
  }
  .col-xs-2 {
    width: calc(var(--span-2) - var(--column-gap) * var(--offset-2));
  }
  .col-xs-3 {
    width: calc(var(--span-3) - var(--column-gap) * var(--offset-3));
  }
  .col-xs-4 {
    width: calc(var(--span-4) - var(--column-gap) * var(--offset-4));
  }
  .col-xs-5 {
    width: calc(var(--span-5) - var(--column-gap) * var(--offset-5));
  }
  .col-xs-6 {
    width: calc(var(--span-6) - var(--column-gap) * var(--offset-6));
  }
  .col-xs-7 {
    width: calc(var(--span-7) - var(--column-gap) * var(--offset-7));
  }
  .col-xs-8 {
    width: calc(var(--span-8) - var(--column-gap) * var(--offset-8));
  }
  .col-xs-9 {
    width: calc(var(--span-9) - var(--column-gap) * var(--offset-9));
  }
  .col-xs-10 {
    width: calc(var(--span-10) - var(--column-gap) * var(--offset-10));
  }
  .col-xs-11 {
    width: calc(var(--span-11) - var(--column-gap) * var(--offset-11));
  }
  .col-xs-12 {
    width: calc(var(--span-12) - var(--column-gap) * var(--offset-12));
  }
}

@media (--viewport-sm) {
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-sm-1 {
    width: calc(var(--span-1) - var(--column-gap) * var(--offset-1));
  }
  .col-sm-2 {
    width: calc(var(--span-2) - var(--column-gap) * var(--offset-2));
  }
  .col-sm-3 {
    width: calc(var(--span-3) - var(--column-gap) * var(--offset-3));
  }
  .col-sm-4 {
    width: calc(var(--span-4) - var(--column-gap) * var(--offset-4));
  }
  .col-sm-5 {
    width: calc(var(--span-5) - var(--column-gap) * var(--offset-5));
  }
  .col-sm-6 {
    width: calc(var(--span-6) - var(--column-gap) * var(--offset-6));
  }
  .col-sm-7 {
    width: calc(var(--span-7) - var(--column-gap) * var(--offset-7));
  }
  .col-sm-8 {
    width: calc(var(--span-8) - var(--column-gap) * var(--offset-8));
  }
  .col-sm-9 {
    width: calc(var(--span-9) - var(--column-gap) * var(--offset-9));
  }
  .col-sm-10 {
    width: calc(var(--span-10) - var(--column-gap) * var(--offset-10));
  }
  .col-sm-11 {
    width: calc(var(--span-11) - var(--column-gap) * var(--offset-11));
  }
  .col-sm-12 {
    width: calc(var(--span-12) - var(--column-gap) * var(--offset-12));
  }
}

@media (--viewport-md) {
  .col-md-1 {
    width: calc(var(--span-1) - var(--column-gap) * var(--offset-1));
  }
  .col-md-2 {
    width: calc(var(--span-2) - var(--column-gap) * var(--offset-2));
  }
  .col-md-3 {
    width: calc(var(--span-3) - var(--column-gap) * var(--offset-3));
  }
  .col-md-4 {
    width: calc(var(--span-4) - var(--column-gap) * var(--offset-4));
  }
  .col-md-5 {
    width: calc(var(--span-5) - var(--column-gap) * var(--offset-5));
  }
  .col-md-6 {
    width: calc(var(--span-6) - var(--column-gap) * var(--offset-6));
  }
  .col-md-7 {
    width: calc(var(--span-7) - var(--column-gap) * var(--offset-7));
  }
  .col-md-8 {
    width: calc(var(--span-8) - var(--column-gap) * var(--offset-8));
  }
  .col-md-9 {
    width: calc(var(--span-9) - var(--column-gap) * var(--offset-9));
  }
  .col-md-10 {
    width: calc(var(--span-10) - var(--column-gap) * var(--offset-10));
  }
  .col-md-11 {
    width: calc(var(--span-11) - var(--column-gap) * var(--offset-11));
  }
  .col-md-12 {
    width: calc(var(--span-12) - var(--column-gap) * var(--offset-12));
  }
}

@media (--viewport-lg) {
  .col-lg-1 {
    width: calc(var(--span-1) - var(--column-gap) * var(--offset-1));
  }
  .col-lg-2 {
    width: calc(var(--span-2) - var(--column-gap) * var(--offset-2));
  }
  .col-lg-3 {
    width: calc(var(--span-3) - var(--column-gap) * var(--offset-3));
  }
  .col-lg-4 {
    width: calc(var(--span-4) - var(--column-gap) * var(--offset-4));
  }
  .col-lg-5 {
    width: calc(var(--span-5) - var(--column-gap) * var(--offset-5));
  }
  .col-lg-6 {
    width: calc(var(--span-6) - var(--column-gap) * var(--offset-6));
  }
  .col-lg-7 {
    width: calc(var(--span-7) - var(--column-gap) * var(--offset-7));
  }
  .col-lg-8 {
    width: calc(var(--span-8) - var(--column-gap) * var(--offset-8));
  }
  .col-lg-9 {
    width: calc(var(--span-9) - var(--column-gap) * var(--offset-9));
  }
  .col-lg-10 {
    width: calc(var(--span-10) - var(--column-gap) * var(--offset-10));
  }
  .col-lg-11 {
    width: calc(var(--span-11) - var(--column-gap) * var(--offset-11));
  }
  .col-lg-12 {
    width: calc(var(--span-12) - var(--column-gap) * var(--offset-12));
  }
}
@media (--viewport-xl) {
  .col-xl-1 {
    width: calc(var(--span-1) - var(--column-gap) * var(--offset-1));
  }
  .col-xl-2 {
    width: calc(var(--span-2) - var(--column-gap) * var(--offset-2));
  }
  .col-xl-3 {
    width: calc(var(--span-3) - var(--column-gap) * var(--offset-3));
  }
  .col-xl-4 {
    width: calc(var(--span-4) - var(--column-gap) * var(--offset-4));
  }
  .col-xl-5 {
    width: calc(var(--span-5) - var(--column-gap) * var(--offset-5));
  }
  .col-xl-6 {
    width: calc(var(--span-6) - var(--column-gap) * var(--offset-6));
  }
  .col-xl-7 {
    width: calc(var(--span-7) - var(--column-gap) * var(--offset-7));
  }
  .col-xl-8 {
    width: calc(var(--span-8) - var(--column-gap) * var(--offset-8));
  }
  .col-xl-9 {
    width: calc(var(--span-9) - var(--column-gap) * var(--offset-9));
  }
  .col-xl-10 {
    width: calc(var(--span-10) - var(--column-gap) * var(--offset-10));
  }
  .col-xl-11 {
    width: calc(var(--span-11) - var(--column-gap) * var(--offset-11));
  }
  .col-xl-12 {
    width: calc(var(--span-12) - var(--column-gap) * var(--offset-12));
  }
}
