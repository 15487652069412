.root {
  background-color: var(--kale-3);
}

.root h4 {
  font-weight: bold;
  margin-bottom: var(--tfd-spacing-md);
}

.root a {
  color: inherit;
}

.container {
  max-width: var(--tfd-max-width);
  margin: 0 auto;
  padding-bottom: 0;
}

.sections {
  margin: var(--tfd-spacing-lg) 0;
  gap: var(--tfd-spacing-lg);
}

@media (--viewport-lg) {
  .sections {
    margin: var(--tfd-spacing-xl) 0;
  }
}

@media (--viewport-lg) {
  .section:not(:last-child) {
    margin-bottom: 0;
  }
}

ul.itemsList {
  margin: 0;
  list-style: none;
  padding: 0;
}

ul.itemsList > li {
  display: block;
  margin-bottom: var(--tfd-spacing-md);
  padding: 0;
}

ul.itemsList > li:last-child {
  margin-bottom: 0;
}

ul.itemsList > li a {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

ul.itemsList > li a:not(:hover) {
  /* See https://app.shortcut.com/farmersdog/story/96940 */
  color: var(--white);
}

.tagline {
  margin-bottom: var(--tfd-spacing-lg);
}

.hideTagline .sections {
  margin-bottom: var(--tfd-spacing-lg);
}

.legalSection {
  display: block;
}

.tagline {
  margin-bottom: var(--tfd-spacing-lg);
}

@media (--viewport-lg) {
  .tagline {
    margin-bottom: var(--tfd-spacing-xl);
  }
}

.legalSectionContainer {
  margin-top: var(--tfd-spacing-lg);
  margin-bottom: var(--tfd-spacing-sm);
}

@media (--viewport-lg) {
  .legalSectionContainer {
    margin: var(--tfd-spacing-xl) 0;
  }
}

@media (--viewport-lg) {
  .legalSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.legalItemsList {
  margin: 0;
  list-style: none;
  padding: 0;
}

.legalItemsList > li {
  display: block;
  margin-bottom: var(--tfd-spacing-md);
  padding: 0;
  text-align: center;
}

.legalItemsList > .legalItemLink {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

@media (--viewport-lg) {
  .legalItemsList {
    display: flex;
    justify-content: space-between;
    gap: var(--tfd-spacing-md);
  }

  .legalItemsList > li {
    text-align: left;
    margin-bottom: 0;
  }
}
