.calendar {
  padding-top: var(--tfd-spacing-sm);
  /* 48px is the width of the svg */
  width: 48px;
  box-sizing: content-box;
  padding-right: var(--tfd-spacing-md);
  @media (--viewport-lg) {
    padding-right: var(--tfd-spacing-lg);
  }
}

.noIcon.calendar {
  display: none;
  @media (--viewport-md) {
    display: block;
  }
}

a.link {
  margin-top: var(--tfd-spacing-xs);
  white-space: normal;
}

.container {
  flex: 1;
}

.container > div:last-child {
  flex: 1;
}

.container .infoContainer {
  height: 100%;
}

.noPadding {
  padding: 0;
}
