.container {
  width: 100%;
}

.fill {
  transition: var(--animation-standard);
}

.marker {
  transition: var(--animation-standard);
}
