.normal {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.black,
.black strong {
  font-weight: 900;
}

.dynamicBold {
  font-weight: var(--tfd-dynamic-bold-heading);
}
