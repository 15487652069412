.nav {
  composes: nav from './AppBar.css';
}

.nav > * {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.nav .navItem {
  flex: 1;
}

.navItem.callToAction {
  flex: 5 0;
}

.menu {
  display: flex;
  flex-direction: column;
}

.items {
  flex: 1;
  list-style: none;
  padding-left: 0;
}

.logo {
  display: flex;
  justify-content: center;
}

.link {
  display: block;
  padding: var(--margin-md) 0;
  transition: opacity ease-in-out 300ms;
}

@media (--viewport-sm) {
  .link {
    padding: var(--margin-md) 18px;
  }
}

.link.inNav {
  padding: 0;
}

.link.active,
.link.active > * {
  color: var(--carrot-3);
}

.persistentLink {
  display: flex;
  justify-content: flex-end;
}

.shortDivider {
  margin: 0;
  min-width: auto;
}

@media (--viewport-sm) {
  .shortDivider {
    padding: 0 18px;
  }
}

.ctaContainer {
  background-color: var(--white);
  bottom: 0;
  padding: var(--margin-sm) 0;
  position: sticky;
  text-align: center;
  width: 100%;
}

.lastDivider {
  margin-bottom: var(--margin-md);
}

.horizontalNavContainer {
  padding: var(--margin-sm) 0;
  margin-top: var(--margin-sm);
  white-space: nowrap;
}

.horizontalNavContentRow {
  display: flex;
}
