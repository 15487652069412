.container:not(.skip-xs) {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - var(--page-margin-xs) * 2);
}

@media (--viewport-sm) {
  .container:not(.skip-sm) {
    width: calc(100% - var(--page-margin-sm) * 2);
  }
}

@media (--viewport-md) {
  .container:not(.skip-md) {
    width: 630px;
  }
}

@media (--viewport-lg) {
  .container:not(.skip-lg) {
    width: calc(100% - var(--page-margin-lg) * 2);
  }
}

@media (--viewport-xl) {
  .container:not(.skip-xl) {
    width: 1185px;
  }
}

.container.disabled {
  width: unset;
  margin: unset;
}
