.container {
  background: var(--oyster-0);
  border-radius: 12px;
}

.selectorContainer {
  padding: var(--margin-lg);
}

.selectorContainer input {
  background-image: url('./assets/google_on_white.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 53px 16px;
  padding-right: 59px;
  margin-right: var(--margin-sm);
}

.selectorContainer input:focus {
  border-color: var(--charcoal-3);
}

.selectorContainer .practiceList {
  list-style: none;
  padding: 0;
  margin-top: var(--margin-md);
  margin-bottom: 0;
}

.selectorContainer .practiceList li,
.selectorContainer .practiceList > div {
  border-top: 1px solid var(--oyster-2);
  padding: var(--margin-sm) 0;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.selectorContainer .practiceList li span {
  letter-spacing: 0.5px;
}

.selectorContainer .practiceList li.practice {
  cursor: pointer;
}

.selectorContainer .practiceList li.practice:hover {
  background: var(--oyster-0);
  filter: brightness(0.95);
}

.selectorContainer .practiceList li:last-child,
.selectorContainer .practiceList > div {
  border-bottom: 1px solid var(--oyster-2);
}

.selected {
  background: var(--oyster-0);
  filter: brightness(0.95);
}

.selectorContainer .loadMore {
  text-decoration: underline;
  color: var(--carrot-2);
  padding: var(--margin-sm) 0;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--oyster-2);
}

.selectorContainer .loadMore span {
  cursor: pointer;
  width: fit-content;
}

.notAssociatedBlock {
  padding-bottom: var(--margin-lg);
}

.selectorContainer .practiceListFooter,
.notAssociatedBlock {
  text-align: center;
}

.selectorContainer .practiceTitle {
  display: block;
}

.center {
  display: flex;
  align-items: center;
}

.addVetPractice {
  max-width: 175px;
  box-sizing: border-box;
  width: var(--form-element-width);

  /* override default padding for ux overhaul feature */
  padding: 14px 24px !important;
}
