.section {
  text-align: center;
  background-color: white;
  padding: var(--margin-xxl) 0;

  @media (--viewport-lg) {
    padding: var(--margin-4xl) 0;
  }
}

.description {
  margin-bottom: var(--margin-xl);
}

.solutionsContainer {
  margin-top: var(--margin-3xl);

}

.individualSolutionContainer {
  @media (--viewport-lg) {
    display: flex;
  }
}

a.button {
  display: block;

  @media (--viewport-md) {
    display: inline-block;
  }
}


.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
