.container {
  display: grid;
  gap: var(--tfd-spacing-md);
  grid-template-columns: 1fr;
  margin-top: var(--tfd-spacing-md);

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
    margin-top: var(--tfd-spacing-lg);
  }
}

.container > div > div {
  height: 100%;
}

.container section {
  box-sizing: border-box;
  height: 100%;
}

.container h2 {
  margin-top: 0;
}

.container > div:last-child {
  grid-row: 2;

  @media (--viewport-md) {
    grid-column: auto / span 2;
  }
}

.container a {
  text-decoration: underline;
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.tallCard {
  box-sizing: border-box;
  height: 100%;
}
