.container {
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

ul.container {
  list-style-type: none;
  margin-block: unset;
  padding: 0;
}

.container:not(.skip-xs) {
  width: calc(100% - var(--margin-sm) * 2);
  grid-template-columns: repeat(4, 1fr);
  column-gap: var(--grid-column-gap-sm);
}

@media (--viewport-sm) {
  .container:not(.skip-sm) {
    width: calc(100% - var(--margin-md) * 2);
    grid-template-columns: repeat(4, 1fr);
    column-gap: var(--grid-column-gap-sm);
  }
}

@media (--viewport-md) {
  .container:not(.skip-md) {
    width: calc(100% - var(--margin-xl) * 2);
    grid-template-columns: repeat(8, 1fr);
    column-gap: var(--grid-column-gap-md);
  }
}

@media (--viewport-lg) {
  .container:not(.skip-lg) {
    width: calc(100% - var(--margin-xl) * 2);
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--grid-column-gap-md);
  }
}

@media (--viewport-xl) {
  .container:not(.skip-xl) {
    width: 1200px;
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--grid-column-gap-md);
  }
}

.container.disabled {
  width: unset;
  margin: unset;
}
