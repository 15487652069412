.videoClass {
  height: 184px;
  min-height: auto;
}

.videoClass div {
  max-height: 184px;
}

.videoClass div {
  width: 100% !important;
}

.videoClass > div:nth-child(2) {
  min-height: auto;
}

@media screen and (min-width: 400px) and (max-width: 709px) {
  .videoClass {
    height: 250px;
  }

  .videoClass div {
    max-height: 250px;
  }
}

@media screen and (min-width: 500px) and (max-width: 709px) {
  .videoClass {
    height: 300px;
  }

  .videoClass div {
    max-height: 300px;
  }
}

@media (--viewport-md) {
  .videoClass {
    height: 360px;
  }
}

@media (--viewport-md) {
  .videoClass {
    height: 360px;
  }

  .videoClass div {
    max-height: 360px;
  }
}

@media (--viewport-lg) {
  .videoClass div {
    max-height: 561px;
  }
  .videoClass {
    height: 561px;
  }
}

.videoClass img {
  width: 100% !important;
  height: 100% !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}
