.container {
  position: fixed;
  overflow-y: auto;
}

.items {
  display: flex;
  flex-flow: column;
  margin-top: var(--margin-xl);
}

.link {
  padding-left: var(--margin-md);
  margin-bottom: var(--margin-md);
}

.link.active {
  box-shadow: inset 2px 0px var(--carrot-3);
}
