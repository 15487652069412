.slide {
  display: block;
  margin: 0;
  flex: 0 0 auto;
  position: relative;
  box-sizing: border-box;
}

/** scroll snap breaks animation on iOS */
@supports (-webkit-touch-callout: none) {
  .slide {
    scroll-snap-align: none;
  }
}

.slide * {
  backface-visibility: hidden;
  transition: opacity var(--animation-standard);
}

.button {
  width: 100%;
}

.animation-reference {
  pointer-events: none;
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}

.animation-debug {
  background-color: var(--kale-1);
  z-index: 1;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-not-wide.slide {
  width: 84%;
}

.is-not-wide.slide:first-child {
  margin-left: 8%;
}

.is-not-wide.slide:last-child {
  margin-right: 8%;
}

.is-not-wide .animation-reference {
  width: 120%;
}

.is-wide.slide {
  width: 50%;
}

.is-wide.slide:first-child {
  margin-left: 25%;
}

.is-wide.slide:last-child {
  margin-right: 25%;
}

.is-wide .animation-reference {
  width: 200%;
}

.active {
  opacity: 1;
}

.inactive {
  opacity: 0.25;
}

.inactive:focus {
  opacity: 1;
}
