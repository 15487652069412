#tfd-toast {
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: calc(var(--z-index-modal) + 1);
  /* Make sure elements behind can be clicked */
  pointer-events: none;
}

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
