.textsContainer {
  text-align: center;
  row-gap: var(--tfd-spacing-sm);
}

.container {
  max-width: 588px;
}

.container:first-child {
  height: 100%;
}

.mobile {
  display: block;
  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;
  @media (--viewport-lg) {
    display: block;
  }
}
