.container {
  background-color: var(--kale-3);
  padding-bottom: var(--margin-lg);
  padding-top: var(--margin-xl);

  @media (--viewport-lg) {
    padding-bottom: var(--margin-xl);
  }
}

h4 {
  margin-top: 30px;
  margin-bottom: var(--margin-md);

  @media (--viewport-lg) {
    margin: 0;
    margin-bottom: var(--margin-md);
  }
}

.content {
  display: flex;
  flex-direction: column;
  color: var(--white);
  align-items: start;
  text-align: left;

  @media (--viewport-lg) {
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
  }
}

.logo {
  margin: 0 auto 40px auto;

  @media (--viewport-lg) {
    padding-right: 40px;
  }
}

.siteMap,
.joinUs,
.contact {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contact .availability {
  margin-left: var(--tfd-spacing-sm);
}

.sortContent span.sitemap {
  order: 1;
  @media (--viewport-lg) {
    order: initial;
  }
}

.sortContent span.contact {
  order: 3;
  @media (--viewport-lg) {
    order: initial;
  }
}

.sortContent span.joinUs {
  order: 2;
  @media (--viewport-lg) {
    order: initial;
  }
}

.ancillary {
  padding: var(--margin-lg) 0;
  text-align: center;
}
