#tfd-lightbox {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

html.has-lightbox {
  overflow: hidden;
}

html.has-lightbox #tfd-lightbox {
  z-index: var(--z-index-modal);
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backface-visibility: hidden;

  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.background {
  margin: 0;
  border: none;
  appearance: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: rgb(from var(--charcoal-3) r g b / 94%);

  overflow: hidden;
  transition: opacity 100ms linear;
  opacity: 0;
}

.disableAnimation .background {
  transition-duration: 0ms;
}

.content {
  position: relative;
  margin: auto;
  background: var(--white);
  backface-visibility: hidden;

  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  transition-property: opacity, transform;
  transform-origin: center top;

  transform: scale(1);
  opacity: 1;

  /* iOS */
  -webkit-tap-highlight-color: rgb(from var(--black) r g b / 0%);
}

.disableAnimation .content {
  transition-duration: 0ms;
}

.autoBox {
  min-height: 100%;
  display: flex;
  flex: 1;
}
