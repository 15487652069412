.caretIcon {
  transform: rotate(-90deg);
}

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.before {
  text-decoration: line-through;
}

.before,
.after {
  white-space: nowrap;
}
