.infoContainer {
  display: flex;
  flex-direction: column;
  row-gap: var(--margin-md);
  max-width: 880px;
  text-align: center;
}

.logosContainer {
  text-align: center;
  max-width: 420px;
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
