.veterinaryGuideThumbnail {
  width: 100%;
  max-width: 279px;

  @media (--viewport-lg) {
    max-width: 120px;
  }
}

.veterinaryGuideTextContainer {
  @media (--viewport-lg) {
    padding-left: var(--margin-md);
  }
}
