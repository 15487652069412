

/** Center icon offsets */
@value horizontal-offset: -2px;
@value vertical-offset: -1px;

.button {

  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button.default {
  background: none;
}

.button.white-bg {
  border-radius: 50%;
  background: var(--white);
  opacity: 0.8;
}

.right {
  transform: rotate(180deg) translateX(horizontal-offset);
}

.left {
  transform: rotate(0deg) translateX(horizontal-offset);
}

.down {
  transform: rotate(-90deg) translateX(vertical-offset);
}

.up {
  transform: rotate(90deg) translateX(vertical-offset);
}

.sm {
  height: 36px;
  width: 36px;
}

.md {
  height: 48px;
  width: 48px;
}
.lg {
  height: 60px;
  width: 60px;
}
