.option {
  cursor: pointer;
  list-style-type: none;
  padding: var(--tfd-spacing-sm);
}

.option + .option {
  border-top: 2px solid var(--oyster-0);
}

.selected {
  background-color: var(--oyster-0);
}

.option.selected.hover + .option {
  border-top: 2px solid var(--kale-0);
}

.hover {
  background-color: var(--kale-0);
}

.option.hover + .option {
  border-top: 2px solid var(--kale-0);
}

.option + .option.hover {
  border-top: 2px solid var(--kale-0);
}

.invalid {
  color: var(--error-red);
}
