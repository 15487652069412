@media (--viewport-lg) {
  .subNav {
    display: none;
  }
}

.subNav {
  overflow: auto;
  padding: var(--tfd-spacing-sm) 0;
  transition: all var(--tfd-animation-standard);
}

.navItem {
  background-color: transparent;
}

.list {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  padding: 0 var(--tfd-spacing-sm);
}

.listItem:first-child {
  padding-left: var(--tfd-spacing-md);
}

.listItem:last-child {
  padding-right: var(--tfd-spacing-md);
}
