.kale-3 {
  fill: var(--kale-3);
}

.white {
  fill: var(--white);
}

.charcoal-3 {
  fill: var(--charcoal-3);
}

.charcoal-1 {
  fill: var(--charcoal-1);
}

.carrot-2 {
  fill: var(--carrot-2);
}

/** TODO: Remove these legacy colors once they are no longer in use */
.white {
  fill: var(--white);
}

.gunmetal {
  fill: var(--gunmetal);
}

.bluey-gray {
  fill: var(--bluey-gray);
}

.salmon {
  fill: var(--salmon);
}
