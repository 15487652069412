/** 
 * The important statements used in this file are needed so we beat Stripe's
 * inline styles.
 */
.input {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  cursor: text;
  display: inline-block;
  vertical-align: top;
  padding: var(--border-label-padding);
  padding-top: calc(var(--border-label-padding) + 2px);
  border-radius: var(--border-label-radius);
  background: transparent !important;
  background-color: transparent !important;
}

.input iframe {
  border-radius: var(--border-label-radius) !important;
}
