.title {
  text-align: center;

  @media (--viewport-lg) {
    font-size: 28px;
  }
}

.stepsContainer {
  min-height: 164px;
  padding-top: var(--tfd-spacing-sm);

  @media (--viewport-lg) {
    padding-top: 20px;
  }
}

.steps {
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--tfd-spacing-md);

  @media (--viewport-lg) {
    position: relative;
    min-width: 788px;
    flex-wrap: nowrap;
    right: 13%;
  }
}

.accordionContainer {
  padding-top: var(--tfd-spacing-lg);
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  row-gap: var(--tfd-spacing-lg);

  @media (--viewport-lg) {
    row-gap: var(--tfd-spacing-xl);
  }
}

.completeVerificationCard {
  margin-bottom: var(--tfd-spacing-lg);

  @media (--viewport-lg) {
    margin-bottom: var(--tfd-spacing-lg);
  }
}

.ctaDivider {
  @media (--viewport-lg) {
    margin: 48px 0 !important;
  }
}

.showSteps {
  text-decoration: underline;
  cursor: pointer;
  color: var(--kale-3);
  margin-top: var(--tfd-spacing-md);
}

.button {
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
}
