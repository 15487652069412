.buttonContainer,
.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formContainer {
  align-items: center;
  margin-bottom: var(--margin-xl);
  @media (--viewport-lg) {
    display: block;
    width: 674px;
  }
}

.messagesContainer {
  margin: var(--margin-md) 0 var(--margin-sm) 0;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submitButton,
.input {
  width: var(--form-element-width);
}

.submitButton {
  margin: var(--margin-md) 0;
  align-self: center;
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
}

.loginButton {
  margin: 0;
}

.inputSection {
  width: 100%;
  margin: 0 auto;
  text-align: left;
  max-width: var(--form-element-width);
  @media (--viewport-lg) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
}
