.contactIcon {
  width: 30px;
  height: 30px;
}

.email {
  margin-top: 5px;
}

.card {
  margin-bottom: var(--tfd-spacing-md);
}
