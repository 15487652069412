.circle,
.squircle {
  display: inline-block;
}

.circle,
.circle > *,
.circle > picture > img {
  border-radius: 50%;
}

@supports (clip-path: circle()) or (-webkit-clip-path: circle()) {
  .circle,
  .circle > * {
    border-radius: 0;
    clip-path: circle(50% at 50% 50%);
  }
}

.squircle,
.squircle > *,
.squircle > picture > img {
  border-radius: 24px;
}

@supports (clip-path: inset()) or (-webkit-clip-path: inset()) {
  .squircle,
  .squircle > *,
  .squircle > picture > img {
    border-radius: 0;
    clip-path: inset(0 0 0 0 round 24px);
  }
}

.circle > *,
.circle > picture > img,
.squircle > *,
.squircle > picture > img {
  display: block;
}

.xs > *,
.xs > picture > img {
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
}

.sm > *,
.sm > picture > img {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}

.md > *,
.md > picture > img {
  min-width: 72px;
  min-height: 72px;
  max-width: 72px;
  max-height: 72px;
}

.lg > *,
.lg > picture > img {
  min-width: 150px;
  min-height: 150px;
  max-width: 150px;
  max-height: 150px;
}

.xl > *,
.xl > picture > img {
  min-width: 300px;
  min-height: 300px;
  max-width: 300px;
  max-height: 300px;
}
