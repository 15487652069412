.default {
  --dot-size: 9px;
  --bar-height: 2px;
  --background-bar-color: var(--oyster-0);
  --bar-content-spacing: 0 var(--tfd-spacing-sm);
  --dot-active: var(--white);
  --bar-border-radius: 0;
}

.bold {
  --dot-size: 12px;
  --bar-height: 4px;
  --background-bar-color: var(--charcoal-1);
  --bar-content-spacing: 0;
  --dot-active: var(--kale-3);
  --bar-border-radius: 12px;
}

.root {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: var(--dot-size);
}

/* As "background" we display a line that fits
   nicely with the progress bar when using fr < 3 */
.background_bar,
.bar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: var(--bar-height);
  background: var(--background-bar-color);
  border-radius: var(--bar-border-radius);
}

/* The actual progress bar container */
.content {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: var(--bar-content-spacing);
}

.bar {
  transition: width var(--animation-standard);
}
.bar_active {
  width: 0%;
  background: var(--kale-3);
}

.dots {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.dot {
  min-width: var(--dot-size);
  width: var(--dot-size);
  height: var(--dot-size);
  border: var(--tfd-spacing-xxs) solid var(--background-bar-color);
  border-radius: 100%;
  box-sizing: border-box;
  background: var(--white);
  transition: border var(--animation-standard);
}
.dot_active {
  border-color: var(--kale-3);
  background: var(--dot-active);
}
