.filteredFormulas {
  margin-top: var(--tfd-spacing-xl);
  @media (--viewport-lg) {
    margin-top: var(--tfd-spacing-xxl);
  }
}

.errorCallout {
  width: fit-content;
}
