.section {
  width: 100%;
  background-color: var(--oyster-0);

  @media (--viewport-lg) {
    background: inherit;
    width: 92%;
    margin: 0 auto var(--margin-xl);
  }
}

.container {
  padding-top: var(--margin-xl);
  background-color: white;
}

.whatFreshFoodIsContainterMobile {
  background-color: var(--oyster-0);
}

.infoContainer {
  width: 100%;
  background-color: var(--oyster-0);
  display: flex;
  padding: var(--margin-xl) 0;
  justify-content: center;

  @media (--viewport-lg) {
    padding: var(--margin-xl);
  }

  @media (--viewport-lg) {
    width: 48%;
    justify-content: flex-start;
    padding: var(--margin-xl) 0;
  }

  @media (--viewport-xl) {
    width: 48.5%;
  }
}

.textsContainer {
  display: flex;
  flex-direction: column;
  row-gap: var(--tfd-spacing-md);
  justify-content: center;
  text-align: center;
  width: 100%;

  @media (--viewport-lg) {
    padding: 0 40px;
    text-align: left;
  }
}

.foodImageContainer {
  width: 100%;
  background-color: var(--oyster-0);

  @media (--viewport-lg) {
    width: 48%;
  }

  @media (--viewport-xl) {
    width: 48.5%;
  }
}

.foodImage {
  @supports (object-fit: cover) and (object-position: 50% 50%) {
    width: 100%;
    height: 307px;
    object-fit: cover;
    object-position: 50% 50%;
  }

  @media (--viewport-lg) {
    height: 100%;
  }
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
