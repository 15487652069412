.nav-item {
  display: inline-block;
  margin-right: var(--margin-md);
  padding: 0;
  background: var(--white);
  cursor: pointer;
  position: relative;
  appearance: none;
}

.nav-item[disabled] {
  cursor: default;
}

.nav-item.active {
  cursor: default;
  color: var(--carrot-2);
}
