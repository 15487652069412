.root {
  -webkit-transform: translate3d(0, 0, 0);
  position: relative;
  display: inline-block;
  height: 32px;
  width: 32px;
  border: 1.5px solid var(--default-color);
  border-radius: 50%;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: border-color, background-color;
}

.lg {
  height: 32px;
  width: 32px;
}

.md {
  height: 16px;
  width: 16px;
}

.root:after {
  content: '';
  opacity: 0;
  position: absolute;
  top: 0.5px;
  left: 0.5px;
  right: 0.5px;
  bottom: 0.5px;
  border: 2px solid var(--white);
  border-radius: 50%;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: opacity;
}

.root.focused {
  border-color: var(--focused-color);
}

.root.checked {
  background-color: var(--checked-color);
  border-color: var(--checked-color);
}

.root.checked:after {
  opacity: 1;
}

.root.disabled {
  border-color: var(--disabled-color);
}

.root.checked.disabled {
  background-color: var(--disabled-color);
}
