.requestedEvents {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.requestedEvents > div {
  grid-column: span 2;
}

.requestedEvents > div:nth-last-child(2),
.requestedEvents > div:nth-last-child(1) {
  grid-column: span 2;
  @media (--viewport-lg) {
    grid-column: span 1;
  }
}

.svgContainer {
  position: relative;
}

.doggieSvg {
  position: absolute;
  top: -50px;
  right: 35px;
  z-index: 1;
  /* these are the dimensions of the doggie svg, this needs to be hardcoded since at build time webpack is not recognizing the svg dimensions */
  width: 70px;
  height: 67px;

  @media (--viewport-md) {
    right: 58px;
  }
}

.reduceGripGap {
  margin-top: calc(var(--tfd-spacing-md) * -1);
}
