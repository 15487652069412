.heading-72 {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 72px;
  line-height: var(--tfd-heading-72-line-height);
}

.heading-52 {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 52px;
  line-height: var(--tfd-heading-52-line-height);
}

.heading-40 {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 40px;
  line-height: var(--tfd-heading-40-line-height);
}

.heading-28 {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 28px;
  line-height: var(--tfd-heading-28-line-height);
}

.heading-22 {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 22px;
  line-height: var(--tfd-heading-22-line-height);
}

.heading-16 {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 16px;
  line-height: var(--tfd-heading-16-line-height);
}

.heading-12 {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 12px;
  line-height: var(--tfd-heading-12-line-height);
}

/* By default, the font family for the article is set to "tiempos-text". 
However, the variable should be set to "tiempos-headline" for font sizes larger than 20. */
.article-28,
.article-40,
.article-52,
.article-72 {
  --tfd-font-family-serif: var(--tfd-font-family-serif-headline);
  letter-spacing: 0.5px;
}

.article-72 {
  font-size: 72px;
  line-height: var(--tfd-article-72-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.article-52 {
  font-size: 52px;
  line-height: var(--tfd-article-52-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.article-40 {
  font-size: 40px;
  line-height: var(--tfd-article-40-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.article-28 {
  font-size: 28px;
  line-height: var(--tfd-article-28-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.article-20 {
  font-size: 20px;
  line-height: var(--tfd-article-20-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.article-16 {
  font-size: 16px;
  line-height: var(--tfd-article-16-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.article-12 {
  font-size: 12px;
  line-height: var(--tfd-article-12-line-height);
  font-family: var(--tfd-font-family-serif);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}
