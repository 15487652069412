@import url('https://use.typekit.net/tlf2grf.css');

@font-face {
  font-family: tiempos-text;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('../assets/fonts/tiempos-text-web-regular.woff2') format('woff2'),
    url('../assets/fonts/tiempos-text-web-regular.woff') format('woff');
}

@font-face {
  font-family: tiempos-text;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src:
    url('../assets/fonts/tiempos-text-web-regular-italic.woff2') format('woff2'),
    url('../assets/fonts/tiempos-text-web-regular-italic.woff') format('woff');
}

@font-face {
  font-family: tiempos-text;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    url('../assets/fonts/tiempos-text-web-bold.woff2') format('woff2'),
    url('../assets/fonts/tiempos-text-web-bold.woff') format('woff');
}

@font-face {
  font-family: tiempos-text;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src:
    url('../assets/fonts/tiempos-text-web-bold-italic.woff2') format('woff2'),
    url('../assets/fonts/tiempos-text-web-bold-italic.woff') format('woff');
}

@font-face {
  font-family: tiempos-headline;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src:
    url('../assets/fonts/tiempos-headline-regular-italic.woff2') format('woff2'),
    url('../assets/fonts/tiempos-headline-regular-italic.woff') format('woff');
}

@font-face {
  font-family: tiempos-headline;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    url('../assets/fonts/tiempos-headline-semibold.woff2') format('woff2'),
    url('../assets/fonts/tiempos-headline-semibold.woff') format('woff');
}
