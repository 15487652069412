.fieldset {
  padding: 0;
  border: none;
}

.fieldset > label + label {
  margin-top: var(--margin-sm);
}

.fieldset > label + label.badge {
  margin-top: var(--margin-md);
}

@media (--viewport-lg) {
  .two-column > label + label {
    margin-top: 0;
  }

  .two-column > label + label.badge {
    margin-top: 0;
  }

  .two-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--margin-md);
    row-gap: var(--margin-sm);
  }
}

.fieldset.horizontal {
  padding: var(--margin-md) 0;
  min-width: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-y: hidden;
  overflow-x: scroll;
}

.fieldset.fieldset > label {
  flex-shrink: 0;
  margin-top: var(--margin-xs) !important;
  margin-left: 0 !important;
  margin-right: 26px;
  margin-bottom: 0;
}
