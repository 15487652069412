.text {
  position: absolute;
  overflow: hidden;

  top: 24.5px;
  left: 4px;
  right: 8px;

  padding: 0 4px;

  display: block;
  box-sizing: border-box;
  color: var(--charcoal-2);
  cursor: text;
  transition: all 100ms ease-in;
  transition-property: color, transform;
  transform: translateY(-50%);
  transform-origin: top left;
  pointer-events: none;

  font-family: var(--tfd-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.5px;
}

/* Wrapper of the text to render as white background. */
.text span {
  transition: background 100ms ease-in;
  padding: 0 4px;
}

.normal.exited,
.normal.exiting {
  color: var(--charcoal-2);
}

.normal.entered,
.normal.entering {
  color: var(--kale-3);
}

.focus {
  color: var(--kale-3);
}

.invalid {
  color: var(--error-red);
}

.disabled.entering,
.disabled.entered {
  transition: none;
}

.disabled.exiting,
.disabled.exited {
  color: var(--charcoal-2);
}

.entering,
.entered {
  transform: translate(2px, -160%) scale(0.75);
}

.entering span,
.entered span {
  background: var(--white);
}

/** 
 * Do not combine this with other classes. This class must be defined separately
 * since it targets a special webkit class
 */
:-webkit-autofill + .text {
  transform: translate(2px, -160%) scale(0.75);
}
:-webkit-autofill + .text span {
  background: var(--white);
}

.exiting span,
.exited span {
  background: transparent;
  color: var(--charcoal-2);
}

.highlighted {
  color: var(--kale-3);
}

.unmounted {
  transition: none;
}
