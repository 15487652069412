.container {
  background-color: var(--white);
  padding: var(--tfd-spacing-md);
  margin-top: var(--tfd-spacing-md);
  @media (--viewport-lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--tfd-spacing-lg);
  }
}

.links {
  width: 100%;
}

.left {
  @media (--viewport-lg) {
    width: 60%;
  }
}

.browseFormulasButton {
  margin-bottom: var(--tfd-spacing-md);
  @media (--viewport-lg) {
    margin-right: var(--tfd-spacing-md);
    margin-bottom: 0;
  }
}

.dogNextToBox {
  width: 130px;
  height: 130px;
  margin-top: calc(var(--tfd-spacing-md) * -1);
  @media (--viewport-lg) {
    width: 150px;
    height: 150px;
  }
}

.linksAndIcon {
  display: flex;
  align-items: flex-start;
}

.guide {
  display: block !important;
  @media (--viewport-lg) {
    display: inline !important;
  }
}
