.container,
.mobile,
.desktop,
.input,
.button {
  width: 100%;
}

.container {
  text-align: center;
}

.formContainer>div {
  max-width: inherit;

  @media(--viewport-md) {
    max-width: var(--form-element-width);
  }
}


.button {
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
  @media (--viewport-md) {
    width: 160px;
  }
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
