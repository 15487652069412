.card {
  text-align: center;
  max-width: 310px;
  @media (--viewport-lg) {
    width: 100%;
    margin: 0 auto;
    max-width: unset;
  }
}

.divider {
  width: 100px;
  margin: 0 auto;
}

.position {
  color: var(--blueberry-3);
}

.image {
  border-radius: 50%;
  margin-bottom: var(--margin-sm);
}

.name {
  margin: 0 0 var(--margin-xs) 0;
}

.education {
  margin: 0 0 var(--margin-xs) 0;
}

.text,
.position {
  margin: 0;
}
