.container {
  border-color: var(--charcoal-3);
  border: 0;
}

.horizontal {
  min-width: 100%;
  border-top-width: 2px;
}

.vertical {
  border-left-width: 2px;
  min-height: 100%;
  display: inline-block;
}

.kale-3 {
  border-color: var(--kale-3);
}

.white {
  border-color: var(--white);
}

.charcoal-3 {
  border-color: var(--charcoal-3);
}

.charcoal-1 {
  border-color: var(--charcoal-1);
}

.charcoal-0 {
  border-color: var(--charcoal-0);
}

.oyster-0 {
  border-color: var(--oyster-0);
}

.kale-1 {
  border-color: var(--kale-1);
}

.carrot-1 {
  border-color: var(--carrot-1);
}

.chickpea-2 {
  border-color: var(--chickpea-2);
}

.carrot-2 {
  border-color: var(--carrot-2);
}

.carrot-3 {
  border-color: var(--carrot-3);
}
