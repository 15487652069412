.indications {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--tfd-spacing-sm);
}

.indicationList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.indicationList li {
  margin-bottom: var(--tfd-spacing-sm);
  margin-right: var(--tfd-spacing-sm);
  display: inline-block;
}
