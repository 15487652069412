.container {
  max-width: 588px;
}

.textsContainer {
  row-gap: var(--tfd-spacing-sm);

  @media (--viewport-lg) {
    row-gap: var(--tfd-spacing-lg);
  }
}

.title,
.subtitle {
  text-align: center;
}

.title {
  padding: 0 var(--tfd-spacing-sm);
  line-height: 34px;
  @media (--viewport-lg) {
    padding: 0;
    font-size: 40px;
  }
}

.subtitle {
  line-height: 32px;
  @media (--viewport-lg) {
    font-size: 20px;
    line-height: 32px;
  }
}

.picture {
  width: 104px;
  @media (--viewport-lg) {
    width: 156px;
  }
}

.button {
  width: 100%;
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
  @media (--viewport-lg) {
    width: auto;
  }
}
