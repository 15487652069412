.select {
  /** Remove the inheritance of text transform in Firefox. */
  text-transform: none;
  /** Remove the margin in Firefox and Safari */
  margin: 0;
  padding: 12px;
  padding-right: 48px;
  width: 100%;
  height: 100%;
  display: block;
  background: transparent;
  border: none;
  appearance: none;
  border-radius: 4px;
  color: var(--kale-3);
}

.select:focus {
  outline: none;
}

.select:-webkit-autofill {
  box-shadow: inset 0 0 0 9999px var(--white);
}

/** Hide IE 11 select arrow */
.select::-ms-expand {
  display: none;
}

.select.regular {
  font-weight: 400;
}

.select.bold {
  line-height: 26px;
  letter-spacing: 0px;
}
