.text {
  position: absolute;
  top: var(--margin-md);

  /* This is so our transition only uses transform. The actual 12px padding is
  too much for the animated label text */
  left: 4px;
  padding: 0 8px;

  display: block;
  box-sizing: border-box;
  color: var(--charcoal-1);
  cursor: text;
  transition: all 100ms ease-in;
  transition-property: color, transform, background;
  transform: translateY(-50%);
  transform-origin: top left;
  pointer-events: none;
}

.normal.exited,
.normal.exiting {
  color: var(--charcoal-1);
}

.normal.entered,
.normal.entering {
  color: var(--kale-3);
}

.focus {
  color: var(--kale-3);
}

.invalid {
  color: var(--error-red);
}

.disabled.entering,
.disabled.entered {
  transition: none;
}

.disabled.exiting,
.disabled.exited {
  color: var(--charcoal-1);
}

.entering,
.entered {
  background: var(--white);
  transform: translate(2px, -154%) scale(0.75);
}

/** 
 * Do not combine this with other classes. This class must be defined separately
 * since it targets a special webkit class
 */
:-webkit-autofill + .text {
  background: var(--white);
  transform: translate(2px, -154%) scale(0.75);
}

.exiting,
.exited {
  background: transparent;
}
