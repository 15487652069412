.container {
  margin-left: 10px;
  margin-right: 10px;
}

@media (--viewport-sm) {
  .container {
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media (--viewport-lg) {
  .container {
    margin-left: 48px;
    margin-right: 48px;
  }
}
