.title {
  margin: 0 0 var(--margin-md) 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: var(--tfd-spacing-md);

  @media (--viewport-md) {
    align-items: flex-end;
    margin-top: var(--tfd-spacing-lg);
  }
  @media (--viewport-xl) {
    text-align: left;
  }
}
