.container {
  display: flex;
}

.input {
  display: inline-flex;
  vertical-align: middle;
  flex: 0 0 auto;
}

.label {
  display: inline-block;
  vertical-align: middle;

  transition-timing-function: var(--animation-standard-timing-function);
  transition-duration: var(--animation-standard-duration);
  transition-property: color;
  padding-left: var(--margin-xs);
}

.label.disabled {
  color: var(--charcoal-1);
}

.label.kale-3 {
  color: var(--kale-3);
}

.label.charcoal-3 {
  color: var(--charcoal-3);
}

.label.charcoal-1 {
  color: var(--charcoal-1);
}
