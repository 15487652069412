.container {
  background: var(--white);
  border-radius: 24px;
  display: inline-block;
  margin: 0;
  position: relative;
}

.shadow {
  box-shadow: 0 1px 12px 0 rgba(208, 209, 209, 0.3);
}
