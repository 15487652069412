@import 'keyframes/spring-in.css';
@import 'keyframes/spring-out.css';

.appear,
.appearDone,
.enter,
.enterDone,
.exit,
.exitDone {
  /* Export empty classes to avoid `undefined` being added because of
  https://github.com/reactjs/react-transition-group/issues/502 */
}

.appearActive {
  animation-timing-function: linear;
  animation-name: spring-in;
  animation-duration: 600ms;
}

.enterActive {
  animation-timing-function: linear;
  animation-name: spring-in;
  animation-duration: 600ms;
}

.exitActive {
  animation-timing-function: linear;
  animation-name: spring-out;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
}
