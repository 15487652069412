/**
  * This is a generated file. Do not modify directly.
  * Instead use generateKeyframes.js
  */
@keyframes spring-in {
  0% { transform: translate(0px, -100px); }
  2% { transform: translate(0px, -82.76px); }
  4% { transform: translate(0px, -60.58px); }
  6% { transform: translate(0px, -38.26px); }
  8% { transform: translate(0px, -18.89px); }
  10% { transform: translate(0px, -4.01px); }
  12% { transform: translate(0px, 5.99px); }
  14% { transform: translate(0px, 11.54px); }
  16% { transform: translate(0px, 13.49px); }
  18% { transform: translate(0px, 12.91px); }
  20% { transform: translate(0px, 10.78px); }
  22% { transform: translate(0px, 7.97px); }
  24% { transform: translate(0px, 5.1px); }
  26% { transform: translate(0px, 2.59px); }
  28% { transform: translate(0px, 0.64px); }
  30% { transform: translate(0px, -0.69px); }
  32% { transform: translate(0px, -1.44px); }
  34% { transform: translate(0px, -1.72px); }
  36% { transform: translate(0px, -1.66px); }
  38% { transform: translate(0px, -1.4px); }
  40% { transform: translate(0px, -1.05px); }
  42% { transform: translate(0px, -0.68px); }
  44% { transform: translate(0px, -0.35px); }
  46% { transform: translate(0px, -0.1px); }
  48% { transform: translate(0px, 0.08px); }
  50% { transform: translate(0px, 0.18px); }
  52% { transform: translate(0px, 0.22px); }
  56% { transform: translate(0px, 0.18px); }
  58% { transform: translate(0px, 0.14px); }
  60% { transform: translate(0px, 0.09px); }
  62% { transform: translate(0px, 0.05px); }
  64% { transform: translate(0px, 0.01px); }
  66% { transform: translate(0px, -0.01px); }
  100% { transform: translate(0px, 0px); }
}
