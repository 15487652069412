.container {
  margin-top: var(--margin-md);
}

.container div>div {
  margin-left: 0;

}

.checkboxWithTextFullWidth {
  margin: calc(2 * var(--margin-xxs)) 0;
  padding-left: var(--margin-xs);
  max-width: 100%;
}

.checkboxWithText {
  margin: calc(2 * var(--margin-xxs)) 0;
  padding-left: var(--margin-xs);
  max-width: 250px;

  @media (--viewport-lg) {
    max-width: 100%;
  }
}
