.Kale {
  background-color: var(--kale-0);
}
.Kale > button:first-child {
  padding: var(--tfd-spacing-sm);
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: start;
  column-gap: var(--tfd-spacing-xs);
}
.Kale > button:first-child > h3 {
  margin: 0;
}
.Kale > button:first-child > span {
  align-self: center;
}
.Kale > button:first-child > svg {
  flex-shrink: 0;
}
.Kale [role='region'] {
  padding: var(--tfd-spacing-sm) var(--tfd-spacing-md) var(--tfd-spacing-md);
}

.Default {
  border-top: 1px solid var(--charcoal-0);
}
.Default > button:first-child {
  margin: var(--tfd-spacing-md) 0;
}

.Default > button:first-child {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: start;
}
.Default > button:first-child > h3 {
  margin: 0;
}
.Default > button:first-child > span {
  display: flex;
  align-self: center;
}
.Default > button:first-child > svg {
  flex-shrink: 0;
}
.Default:last-child {
  border-bottom: 1px solid var(--charcoal-0);
}
.Default [role='region'] {
  padding-bottom: var(--tfd-spacing-md);
}
