@keyframes bounce-dots {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.container {
  display: inline-flex;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  justify-items: center;
}

.dot {
  display: inline-block;
  margin: 0 1px;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  animation: bounce-dots 1.4s infinite ease-in-out both;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

.carrot .dot {
  background-color: var(--carrot-2);
}

.kale .dot {
  background-color: var(--kale-2);
}

.kale-dark .dot {
  background-color: var(--kale-3);
}

.white .dot {
  background-color: var(--white);
}
