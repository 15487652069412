.formulaCardsContainer {
  display: grid;
  grid-template-columns: 1fr;

  gap: var(--tfd-spacing-md);
  @media (--viewport-lg) {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: var(--tfd-spacing-lg);
  }
}

.contactContainer {
  margin: var(--tfd-spacing-xl) 0 var(--tfd-spacing-xl) 0;
}

.noMatchCallout {
  margin: var(--tfd-spacing-md) 0;
  max-width: max-content;
}

.filterActionHeading {
  width: 100%;
  @media (--viewport-lg) {
    width: auto;
  }
}

.filterList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.filterList li {
  margin-bottom: var(--tfd-spacing-sm);
  display: inline-block;

  @media (--viewport-lg) {
    margin-bottom: 0;
    margin-left: var(--tfd-spacing-sm);
  }
}

.contactUsButton {
  color: var(--blueberry-3) !important;
}
