.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputs {
  display: flex;
  flex-direction: row;
}

.descriptionPadding {
  margin-bottom: var(--margin-xl);
}
