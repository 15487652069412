.container {
  display: block;
  padding: var(--margin-lg);
  margin-bottom: var(--margin-lg);
}

@media (--viewport-lg) {
  .container {
    padding: var(--margin-xl);
  }
}
