.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 var(--margin-md);
  padding: var(--margin-lg) 0;

  @media (--viewport-lg) {
    padding-top: var(--margin-xxl);
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 315px;
  margin-bottom: var(--margin-xs);

  @media (--viewport-lg) {
    max-width: 100%;
    margin-bottom: calc(var(--margin-md) + var(--margin-sm));
  }
}

.loginFormCard {
  margin-top: var(--margin-md);
  margin-bottom: var(--margin-lg);
  @media (--viewport-lg) {
    margin-top: unset;
  }
}
