.container {
  gap: var(--tfd-spacing-md);
  flex-direction: column;
}
@media (--viewport-md) {
  .container {
    flex-direction: row;
  }
}

.photo {
  width: 60px;
  height: 60px;
  border-radius: 43px;
}

.testimonialInformation {
  flex: 1;
}
