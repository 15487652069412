.container {
  display: flex;
  flex-direction: column;
  gap: var(--margin-lg);
}

.addSpacing {
  gap: var(--margin-xl);
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--margin-md);

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }
}

.form > div {
  max-width: 100%;
}
