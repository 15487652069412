.label {
  position: relative;
}

.label.has-value {
  height: auto;
}
.input {
  box-sizing: border-box;
  height: var(--tfd-styledselect-height);
  display: block;
  margin: 0;
  padding: var(--tfd-fieldlabel-padding);
  border: none;
  border-radius: var(--tfd-fieldlabel-radius);
  appearance: none;
  background: transparent;
  width: calc(100% - 48px);
}

.input:focus {
  outline: none;
}

.input.isMultiMaxed {
  display: none;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: var(--tfd-fieldlabel-radius);
  align-items: center;
  overflow: hidden;
  position: relative;
}

.container.isMultiMaxed {
  padding-bottom: var(--tfd-spacing-sm);
}

.button {
  height: var(--tfd-styledselect-height);
  margin: 0;
  padding: 0;
  display: block;
  background: transparent;
  border: none;
  appearance: none;
  border-radius: var(--tfd-fieldlabel-radius);
  box-sizing: border-box;
  position: absolute;
  right: 4px;
  top: 0px;
  line-height: 0;
}

.button:focus {
  outline: none;
}

.button.isMultiMaxed {
  display: none;
}

.buttonIcon {
  display: block;
}

.buttonIcon.isOpen {
  transform: rotate(180deg);
}

.chevron {
  transform: rotate(90deg);
}

.chevron.buttonIcon.isOpen {
  transform: rotate(-90deg);
}
