@value card-width: 40px;
@value card-height: 25px;

.element {
  width: calc(100% - (card-width + var(--border-label-padding)));
}

.icon {
  display: inline-block;
  margin: var(--border-label-padding);
  margin-left: 0;
  width: card-width;
  height: card-height;
}

.amex {
  content: url('./assets/american-express.png');
}

.diners {
  content: url('./assets/diners-club.png');
}

.discover {
  content: url('./assets/discover.png');
}

.jcb {
  content: url('./assets/jcb.png');
}

.unknown {
  content: url('./assets/unknown.png');
}

.visa {
  content: url('./assets/visa.png');
}

.mastercard {
  content: url('./assets/mastercard.png');
}
