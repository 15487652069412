.pageContainer {
  max-width: 630px;
  margin-top: 40px;
}

.center {
  text-align: center;
}

.button {
  margin-left: var(--margin-lg);
}
