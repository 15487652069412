.categoryHeading {
  height: 40px;
}

.unselectedFilter {
  border: 1px solid var(--kale-2);
  background-color: var(--kale-0) !important;
}

.selectedFilter {
  border: 1px solid var(--kale-2);
  background-color: var(--kale-3) !important;
}

.filtersModal {
  width: 100%;
  max-width: unset;
  padding-bottom: 0;
}

.modalHeading {
  margin-top: calc(var(--margin-lg) * -1);
}

.applyFiltersButtonContainer {
  display: flex;
  justify-content: center;

  /* sticky bottom */
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white;
}

.applyFiltersButton {
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
  width: 100%;
  margin: var(--margin-lg) 0;
}

.selectFiltersButton {
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
  width: 100%;
}

.chevron {
  height: 30px;
  width: 30px;
  margin-bottom: -7px;
}

.filterCallout {
  position: relative;
  border: none;
  border-radius: 2px;
  width: 100%;
  margin-top: var(--margin-md);
  margin-bottom: none;
}

.filterCalloutExtensionSvg {
  position: absolute;
  top: -18px;
  right: 20px;
}

.filterCallout p {
  margin: 0;
}

.filterList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.filterList li {
  margin-bottom: var(--tfd-spacing-sm);
}
/* Fix for overlapping filters when using iPad's */
@media (min-width: 1024px) and (max-width: 1240px) {
  .filterText {
    font-size: 12px !important;
  }
}

.dividerContainer {
  margin-top: var(--tfd-spacing-sm);
  margin-bottom: var(--tfd-spacing-md);
}
