.overlay-wrapper {
  position: relative;
  display: inline-block;
}

/* Sets max width via width prop */
.tooltip-wrapper {
  border-radius: 6px;
  display: flex;
  overflow: hidden; /* makes tooltip triangle disappear */
  position: absolute;
  /* visibility: hidden; */
  width: 360px; /* max width; will clip beyond this point*/
  z-index: 1;
}

.tooltip {
  display: inline-block;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
}

.tooltip.light {
  background-color: var(--white);
  border: 1px solid var(--silver);
  color: var(--charcoal-3);
}

.tooltip.dark {
  background-color: var(--charcoal-3);
  color: var(--white);
}

.tooltip-wrapper[aria-hidden='true'] {
  visibility: hidden;
  width: 0;
}

.tooltip-wrapper[aria-hidden='false'] {
  visibility: visible;
}

.tooltip-top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  padding-bottom: var(--margin-xs);
}

.tooltip-top-left {
  bottom: 100%;
  padding-bottom: var(--margin-xs);
}

.tooltip-top-right {
  bottom: 100%;
  left: 100%;
  transform: translateX(-100%);
  justify-content: flex-end;
  padding-bottom: var(--margin-xs);
}

.tooltip-bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  padding-top: var(--margin-xs);
}

.tooltip-bottom-left {
  top: 100%;
  padding-top: var(--margin-xs);
}

.tooltip-bottom-right {
  top: 100%;
  left: 100%;
  transform: translateX(-100%);
  justify-content: flex-end;
  padding-top: var(--margin-xs);
}

.tooltip-left {
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  justify-content: flex-end;
  padding-right: var(--margin-xs);
}

.tooltip-left-top {
  top: 0%;
  right: 100%;
  justify-content: flex-end;
  padding-right: var(--margin-xs);
}

.tooltip-left-bottom {
  right: 100%;
  transform: translateY(-100%);
  justify-content: flex-end;
  padding-right: var(--margin-xs);
}

.tooltip-right {
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  padding-left: var(--margin-xs);
}

.tooltip-right-top {
  top: 0%;
  left: 100%;
  padding-left: var(--margin-xs);
}

.tooltip-right-bottom {
  left: 100%;
  transform: translateY(-100%);
  padding-left: var(--margin-xs);
}
