.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 var(--margin-md);
  padding: var(--margin-lg) 0;

  @media (--viewport-lg) {
    padding-top: var(--margin-xxl);
  }
}

.stethoscope {
  width: 76px;
  height: 80px;
  margin-bottom: var(--margin-sm);
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 315px;
  margin-bottom: var(--margin-xs);

  @media (--viewport-lg) {
    max-width: 100%;
    margin-bottom: calc(var(--margin-md) + var(--margin-sm));
  }
}

.loginFormCard {
  padding: var(--margin-md) var(--margin-sm);
  margin-top: var(--margin-md) !important;
  @media (--viewport-md) {
    padding: var(--margin-lg);
  }
  @media (--viewport-lg) {
    margin-top: unset !important;
  }
}

.registerButton {
  width: var(--form-element-width);
  margin-bottom: var(--margin-xl);
  box-sizing: border-box;
  /* have to override padding for the kale variant button */
  padding: 12px 22px !important;
}
