.section {
  width: 100%;
  overflow: hidden;
  text-align: center;
  position: relative;
  padding: var(--margin-lg) 0;
  background-color: var(--kale-0);

  @media (--viewport-lg) {
    padding: 120px 0;
  }
}

.title {
  position: relative;
  display: inline-block;
}

.dog {
  top: -90px;
  height: 200px;
  width: 275px;
  right: -243px;
  position: absolute;

  @media (--viewport-md) {
    right: -300px;
  }
  @media (--viewport-lg) {
    left: unset;
    top: -165px;
    height: inherit;
    right: -342px;
    width: 313px;
    transform: unset;
  }
}

a.button {
  display: block;

  @media (--viewport-md) {
    display: inline-block;
  }
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
