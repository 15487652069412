.normal {
  font-style: normal;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}
