:root {
  --checkbox-input-size: 26px;
}

.checkbox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: var(--checkbox-input-size);
  width: var(--checkbox-input-size);
  border-radius: 4px;
  border: solid 1px var(--charcoal-0);
  pointer-events: none;
  transition-timing-function: var(--animation-standard-timing-function);
  transition-duration: var(--animation-standard-duration);
  transition-property: border-color, background-color, box-shadow;
}

input:checked ~ .checkbox,
.checked {
  border-color: var(--kale-3);
  background: var(--kale-3);
}

.disabled {
  border-color: var(--charcoal-0);
}

input:checked ~ .checkbox.disabled {
  border-color: var(--charcoal-0);
  background: var(--charcoal-0);
}

.focus {
  border-color: var(--kale-3);      
  box-shadow: 0 0 0 2px var(--charcoal-0 );
}

.checkbox > * {
  opacity: 0;
  transition-timing-function: var(--animation-standard-timing-function);
  transition-duration: var(--animation-standard-duration);
  transition-property: opacity;
}

input:checked ~ .checkbox > *,
.checked > * {
  opacity: 1;
}
