a.link {
  display: flex;
  margin-bottom: var(--tfd-spacing-sm);
}

/* if a value from the media queries is changed, make sure to change the values and match the media queries in this selector .video div*/
.video {
  min-height: auto;
  height: 100px;
  @media (--viewport-xs) {
    height: 150px;
  }
  @media (--viewport-sm) {
    height: 200px;
  }
  @media screen and (min-width: 512px) and (max-width: 600px) {
    height: 250px;
  }
  @media screen and (min-width: 600px) and (max-width: 730px) {
    height: 300px;
  }
  @media (--viewport-md) {
    height: 320px;
  }
  @media (--viewport-xl) {
    height: 375px;
  }
}
/* if a value from the media queries is changed, make sure to change the values and match the media queries in this selector .video */

.video div {
  max-height: 100px;
  @media (--viewport-xs) {
    height: 150px;
  }
  @media (--viewport-sm) {
    max-height: 200px;
  }
  @media screen and (min-width: 512px) and (max-width: 600px) {
    max-height: 250px;
  }
  @media screen and (min-width: 600px) and (max-width: 730px) {
    max-height: 300px;
  }
  @media (--viewport-md) {
    max-height: 320px;
  }
  @media (--viewport-xl) {
    max-height: 375px;
  }
}
/* needed to adjust the width of the thumbnail */
.video img {
  width: 100% !important;
  height: 100% !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}

.video button[aria-label='Play Video'] svg {
  background: var(--carrot-2);
  fill: var('--white');
  border-radius: 45px;
}

.videoContainer {
  border: 1px solid var(--blueberry-2);
}

.videoUpdated button[aria-label='Play Video'] svg {
  background: var(--kale-2);
}

.videoDataContainer {
  margin: var(--tfd-spacing-md) 0;
  @media (--viewport-lg) {
    margin-bottom: var(--tfd-spacing-sm);
    display: flex;
    justify-content: space-between;
  }
  margin-bottom: var(--tfd-spacing-md);
}

.videoDataContainer h4 {
  margin-top: 0;
}

.videoDataContainer > div:nth-child(1) > h3 {
  @media (--viewport-lg) {
    width: 90%;
  }
}

.speakerContainer {
  display: flex;
  flex-direction: column;

  @media (--viewport-lg) {
    flex-direction: row;
    column-gap: var(--tfd-spacing-md);
  }
}

.speakerImageContainer {
  margin-bottom: calc(var(--tfd-spacing-md) - var(--tfd-spacing-xs));
  display: inline-block;
  position: relative;
  width: 72px;
  height: 72px;
  overflow: hidden;
  border-radius: 50%;
}

.speakerImageContainer picture {
  width: 100%;
  height: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  gap: var(--tfd-spacing-md);
  width: 100%;
}
.info > div {
  width: 100%;
}

.speaker {
  flex: 1;
}
