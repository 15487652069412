.content.entering,
.content.entered {
  transform: scale(1);
  opacity: 1;
}
.content.exiting {
  transform: scale(0.8);
  opacity: 0;
}
.content.exited {
  transform: scale(0.8);
  opacity: 0;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(57, 56, 60, 0.94);

  overflow: hidden;

  transition: opacity 100ms linear;
  opacity: 0;
}

.backdrop.entering {
  opacity: 1;
}

.backdrop.entered {
  opacity: 1;
}

.backdrop.exiting {
  opacity: 0;
}

.backdrop.exited {
  opacity: 0;
}

.backdrop.unmounted {
  /* Keep this for satisfying CSS types for transitions */
}

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backface-visibility: hidden;

  z-index: var(--tfd-lightbox-z-index);

  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.disableAnimation .backdrop {
  transition-duration: 0ms;
}

.content {
  position: relative;
  margin: auto;
  background: var(--white);
  backface-visibility: hidden;

  transition: all var(--tfd-animation-standard-duration)
    cubic-bezier(0.19, 1, 0.22, 1);
  transition-property: opacity, transform;
  transform-origin: center top;

  transform: scale(1);
  opacity: 1;
}

.content.entering {
  transform: scale(1);
  opacity: 1;
}

.disableAnimation .content {
  transition-duration: 0ms;
}

.container {
  min-height: 100%;
  display: flex;
  flex: 1;
}
