.root > label {
  display: flex;
  align-items: center;
}

.input {
  --input-padding: var(--margin-sm);
  overflow: visible; /* Show the overflow in IE */
  box-sizing: border-box;
  margin: 0; /* Remove the margin in Firefox and Safari */
  padding: var(--input-padding);
  width: 100%;
  height: 100%;
  display: block;
  background: transparent;
  border: none;
  appearance: none;
  /* border-radius is needed or else autofill box shadow cuts off the border */
  border-radius: var(--tfd-border-radius-sm);
  color: var(--kale-3);
  font-family: var(--tfd-font-family);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.input::placeholder {
  color: var(--charcoal-2);
}

.input:focus {
  outline: none;
}

.input:-webkit-autofill {
  box-shadow: inset 0 0 0 9999px var(--white);
}

.input[type='number'] {
  --stepper-width: 24px;
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
  padding-right: calc(var(--input-padding) + var(--stepper-width));
}

/** Hide the native spin buttons, they are replaced by a component */
.input[type='number']::-webkit-inner-spin-button,
.input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
