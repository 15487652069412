.textarea {
  box-sizing: border-box;
  /** Remove the margin in Firefox and Safari */
  margin: 0;
  padding: 12px;
  width: 100%;
  min-height: 100px;
  height: 100%;
  display: block;
  background: transparent;
  border: none;
  resize: vertical;
  appearance: none;
  /* This is needed or else autofill box shadow cuts off the border */
  border-radius: 4px;
  color: var(--kale-3);
  letter-spacing: 0.5px;
}

.textarea:focus {
  outline: none;
}

.textarea::placeholder {
  color: var(--charcoal-2);
}
.textarea:-webkit-autofill {
  box-shadow: inset 0 0 0 9999px var(--white);
}

.fieldLabel {
  min-height: calc(100px + var(--tfd-fieldlabel-text-offset));
  height: auto;
}
