.root {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.root > button:first-child {
  /* Move a bit on the left to align the icon with the text */
  transform: translateX(-10px);
}
.root > button:last-child {
  /* Move a bit on the right to align the icon with the text */
  transform: translateX(8px);
}
