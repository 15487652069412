.button {
  background: none;
  vertical-align: middle;
}

.as-link {
  background: none;
  vertical-align: baseline;
  letter-spacing: inherit;
  font: inherit;
}

[aria-disabled='true'] {
  cursor: default;
}

/* Loading state */

.loading {
  position: relative;
  display: inline-flex; /* Align activity indicator */
  min-width: 58px; /* Make sure button is large enough for the activity indicator */
}

/* Activity indicator is placed inside this container to cover the button. */

.indicator {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  background-color: inherit;
}
