.right {
  transform: rotate(180deg) translateX(-2px);
}

.left {
  transform: rotate(0deg) translateX(-2px);
}

.down {
  transform: rotate(-90deg) translateX(1px);
}

.up {
  transform: rotate(90deg) translateX(1px);
}
