.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: var(--margin-md);
}

.messagesContainer {
  display: flex;
  flex-direction: column;
  width: var(--form-element-width);
  margin: var(--margin-md) 0;
}

.inputSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submitButton {
  align-self: center;
  width: var(--form-element-width);
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
}

.forgotPasswordButton {
  margin: 0;
  text-align: center;
}

@media (--viewport-lg) {
  .formContainer {
    display: block;
  }
}
