@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.container {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
}

.container > span {
  display: inline-block;
  margin: 0 1px;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  background-color: var(--carrot-2);
  animation: bounce 1.4s infinite ease-in-out both;
}

.container > span:nth-child(1) {
  animation-delay: -0.32s;
}

.container > span:nth-child(2) {
  animation-delay: -0.16s;
}

.light > span {
  background-color: var(--white);
}
