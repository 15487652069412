@value button-size: 12px;
@value button-size-green: 10px;
@value button-margin-green: 5px;

.button {
  width: button-size;
  height: button-size;
  border-radius: 100%;
  transition: all 100ms ease;
  transition-property: background;

  appearance: none;
}

.button + .button {
  margin-left: var(--margin-xs);
}

.button.dark {
  background: var(--oyster-0);
  border: 1px solid var(--charcoal-0);
}

.button.dark.active {
  background: var(--charcoal-0);
  border: 1px solid var(--charcoal-0);
}

.button.light {
  background: var(--oyster-0);
  border: 1px solid var(--oyster-0);
}

.button.light.active {
  background: var(--kale-1);
  border: 1px solid var(--kale-1);
}

.button.green {
  width: button-size-green;
  height: button-size-green;
  background: var(--oyster-1);
}

.button.green.active {
  background: var(--kale-3);
}

.button.green + .button.green {
  margin-left: button-margin-green;
}
