.banner {
  padding: 8px var(--margin-xs) var(--margin-sm) var(--margin-sm);
  @media (--viewport-lg) {
    padding: 4px var(--margin-sm);
  }
}

.bannerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bannerText {
  text-align: center;
  flex-grow: 1;
  padding: 0 10px;
}
