.items {
  display: grid;
  margin: 0;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--tfd-spacing-md);
}

@media (min-width: 500px) {
  .items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (--viewport-md) {
  .items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (--viewport-lg) {
  .items {
    grid-template-columns: repeat(4, 1fr);
  }
}
