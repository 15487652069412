.tenetModal {
  border-radius: 20px;
}

.tenetContainer {
  padding-top: calc(var(--tfd-spacing-lg) * -1);
}

.tenetsContainer {
  display: grid;
  width: 100%;
  gap: var(--tfd-spacing-md);
  grid-template-columns: 1fr;

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
  }
}

.tenetIcon {
  width: 72px;
  height: 72px;
}
