@keyframes spring-in {
  0% {
    transform: translateY(-100px);
  }
  2% {
    transform: translateY(-82.76px);
  }
  4% {
    transform: translateY(-60.58px);
  }
  6% {
    transform: translateY(-38.26px);
  }
  8% {
    transform: translateY(-18.89px);
  }
  10% {
    transform: translateY(-4.01px);
  }
  12% {
    transform: translateY(5.99px);
  }
  14% {
    transform: translateY(11.54px);
  }
  16% {
    transform: translateY(13.49px);
  }
  18% {
    transform: translateY(12.91px);
  }
  20% {
    transform: translateY(10.78px);
  }
  22% {
    transform: translateY(7.97px);
  }
  24% {
    transform: translateY(5.1px);
  }
  26% {
    transform: translateY(2.59px);
  }
  28% {
    transform: translateY(0.64px);
  }
  30% {
    transform: translateY(-0.69px);
  }
  32% {
    transform: translateY(-1.44px);
  }
  34% {
    transform: translateY(-1.72px);
  }
  36% {
    transform: translateY(-1.66px);
  }
  38% {
    transform: translateY(-1.4px);
  }
  40% {
    transform: translateY(-1.05px);
  }
  42% {
    transform: translateY(-0.68px);
  }
  44% {
    transform: translateY(-0.35px);
  }
  46% {
    transform: translateY(-0.1px);
  }
  48% {
    transform: translateY(0.08px);
  }
  50% {
    transform: translateY(0.18px);
  }
  52% {
    transform: translateY(0.22px);
  }
  56% {
    transform: translateY(0.18px);
  }
  58% {
    transform: translateY(0.14px);
  }
  60% {
    transform: translateY(0.09px);
  }
  62% {
    transform: translateY(0.05px);
  }
  64% {
    transform: translateY(0.01px);
  }
  66% {
    transform: translateY(-0.01px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes spring-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.entering {
  animation-timing-function: linear;
  animation-name: spring-in;
  animation-duration: 600ms;
}

.entered {
}

.exiting {
  animation-timing-function: linear;
  animation-name: spring-out;
  animation-duration: 100ms;
  animation-fill-mode: forwards;
}

.unmounted,
.exited {
}
