.container {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;

  display: flex;
  flex-direction: row;

  width: 100%;

  /** Required for Safari */
  -webkit-overflow-scrolling: touch;
  /** Fix IE11 and Edge */
  -ms-overflow-style: none;
  /** Remove scrollbar in Firefox */
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  /** Remove scrollbar for Chrome/Safari */
  display: none;
}

.item {
  scroll-snap-align: center;
}
