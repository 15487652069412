.bordered {
  --badge-number-height: 52px; /* same as the font size */
  --badge-number-offset: -2px; /* align vertically the string */

  --badge-image-height: 80px; /* same as the font size */
  --badge-image-offset: -12px; /* same as the font size */

  display: block;
  margin: var(--tfd-spacing-md) 0;
  position: relative;
  background-color: var(--white);
  border: 1px solid var(--oyster-2);
  border-radius: var(--tfd-border-radius-sm);
  padding: var(--tfd-spacing-xl) var(--tfd-spacing-lg);
}

.bordered.with-number {
  margin-top: calc(var(--badge-number-height) / 2 - var(--badge-number-offset));
}

.bordered.with-image {
  margin-top: calc(var(--badge-image-height) / 2 - var(--badge-image-offset));
}
