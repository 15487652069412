.container {
  position: relative;
  width: 48px;
  height: 48px;
}

.dateContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -36%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.dateContainer span:last-child {
  line-height: 0.7;
}
