.plain {
  display: block;
  margin: var(--tfd-spacing-md) 0;
  position: relative;
  background-color: var(--white);
  border-radius: var(--tfd-border-radius-lg);
}
.plain.level-0 {
  box-shadow: none;
}
.plain.level-1 {
  box-shadow: 0 1px 12px 0 rgba(208, 209, 209, 0.3);
}
