.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.heading {
  margin: 0;
  margin-top: var(--margin-lg);
}

.cta {
  margin: 0;
  margin-top: var(--margin-xl);
}

.text {
  margin: 0;
}


@media (--viewport-xs) {
  .section {
    padding: var(--margin-xxl) var(--margin-md);
  }

  .text {
    margin-top: var(--margin-sm);
  }
}

@media (--viewport-lg) {
  .section {
    padding: var(--margin-4xl) 0;
  }

  .text {
    margin-top: var(--margin-md);
  }
}
