.browseTitleCard {
  background-color: var(--oyster-0);
  border-radius: 29px;
  margin-top: var(--margin-xxl) !important;
  margin-bottom: var(--margin-xl) !important;

  padding: var(--margin-md) !important;
  @media (--viewport-md) {
    padding: var(--margin-xl) !important;
  }
}

.dogsBoxSvg {
  width: 278px;
  height: 186px;
}

.emptyUnderline {
  margin-top: -4px;
  width: 115px;
  height: 4px;
  background: inherit;
}
