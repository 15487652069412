.title {
  margin-bottom: 0%;
}

.formContainer {
  margin-top: 0;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;

  @media (--viewport-md) {
    column-gap: var(--tfd-spacing-md);
    grid-template-columns: 1fr 1fr;
  }
}

.formContainer.splitLastForms {
  grid-template-columns: 1fr 1fr;
  column-gap: var(--tfd-spacing-md);

  @media (--viewport-md) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.formContainer > div {
  max-width: 100%;
}

.splitLastForms > div {
  grid-column: auto / span 2;
}

.splitLastForms > div:last-child,
.splitLastForms > div:nth-last-child(2) {
  grid-column: auto / span 1;
  margin-left: 0;
}

.button {
  padding-left: var(--tfd-spacing-xs);
  display: flex !important;
  align-items: center;
}

.backSection {
  width: 100%;
  background-color: var(--oyster-0);
  border-radius: 12px;
  height: 52px;
  display: flex;
  align-items: center;
}
