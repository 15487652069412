@import 'https://use.typekit.net/tlf2grf.css';

:root {
  --heading-sans: neue-haas-grotesk-display, sans-serif;
}

.family {
  font-family: var(--heading-sans);
  font-stretch: normal;
  letter-spacing: 0.5px;
}
