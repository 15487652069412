.close-button {
  position: absolute;
  top: 12px;
  right: 0px;
}

@media (--viewport-md) {
  .close-button {
    top: 34px;
    right: 28px;
  }
}
