.whiteBackground,
.kaleBackground {
  height: 100%;
}

.whiteBackground {
  background-color: white;
}

.kaleBackground {
  background-color: var(--kale-0);
}

.blueberryBackground {
  background-color: var(--blueberry-0);
}

/* unsure if this is used */
.pageContent {
  margin: var(--tfd-spacing-lg) auto var(--tfd-spacing-lg) auto;

  @media (--viewport-lg) {
    margin: var(--tfd-spacing-xxl) auto var(--tfd-spacing-xxl) auto;
  }
}

.content {
  @media screen and (min-width: 900px) and (max-width: 1392px) {
    width: 720px;
  }
}

.verticalSpacingOverride {
  padding-top: var(--tfd-spacing-xl);
  padding-bottom: var(--tfd-spacing-xl);

  @media (--viewport-lg) {
    padding-top: var(--tfd-spacing-xxl);
    padding-bottom: var(--tfd-spacing-xxl);
  }
}

.fullWidth {
  width: 100%;
}
