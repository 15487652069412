.list {
  display: none;
  position: absolute;
  z-index: 1;
  background: var(--white);
  left: -1px;
  top: 4px;
  border-radius: var(--tfd-fieldlabel-radius);
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  list-style-type: none;
  border: 1px solid var(--kale-3);
  box-sizing: content-box;
}

.list:focus {
  outline: none;
}

.list.isOpen {
  display: block;
}

.list::-webkit-scrollbar {
  width: 0px;
  appearance: none;
}

.list::-webkit-scrollbar-thumb {
  appearance: none;
}
