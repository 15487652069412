.navContainer {
  background-color: var(--chickpea-0);
}

.navbar {
  background-color: var(--chickpea-0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 var(--tfd-spacing-md) 0 var(--tfd-spacing-md);
  overflow: auto;
}

@media (--viewport-lg) {
  .navbar {
    margin: 0;
  }
}

.navbar .actionsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (--viewport-lg) {
  .navbar .actionsContainer {
    width: initial;
  }
}

.navbar {
  /* with this margin we will make sure a space is reserved for the scrollbar */
  margin-bottom: 8px;
}
@media (--viewport-lg) {
  .navbar {
    margin-bottom: 0;
  }
}
/* just target ios devices */
@supports (-webkit-touch-callout: none) {
  .navbar {
    /* with this padding we will make sure a space is reserved for the scrollbar on ios */
    padding-bottom: 6px;
  }
}

/* Scrollbar properties */
/* When changing this properties is needed to set all the other properties for the scrollbar */
.navbar::-webkit-scrollbar {
  /* 5px will be the width of the scrollbar of horizontal position, this will make sure the navbar does not grow up*/
  height: 4px;
}

/* Track */
.navbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
/* setup for the scrollbar */
.navbar:hover::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136, 0.8);
  border-radius: 8px;
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.navbar .actionsContainer .navList {
  display: flex;
  align-items: baseline;
  flex: 1;
  column-gap: var(--tfd-spacing-md);
  margin: 0;
  padding: 0;
}

@media (--viewport-lg) {
  .navbar .actionsContainer .navList {
    justify-content: center;
    padding-left: 0%;
  }
}

.navbar li {
  list-style: none;
  color: var(--charcoal-0);
  height: 100%;
  display: flex;
  box-sizing: border-box;
}

.navbar div.activeUnderline {
  height: 4px;
  margin: 0;
  padding: 0;
  width: 100%;
  border: 0;
  background: var(--kale-3);
  transition: all var(--tfd-animation-standard);
}

.navItem {
  display: flex;
  align-items: center;
  padding-top: calc(var(--tfd-spacing-lg) / 2);
  /* 4px is the height of the activeUnderline element */
  padding-bottom: calc(var(--tfd-spacing-lg) / 2 - 4px);
}
