.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--tfd-spacing-md);
  margin: 0 var(--margin-lg);
  padding-top: var(--tfd-spacing-lg);

  @media (--viewport-md) {
    width: 588px;
    margin: 0 auto;
  }
}

.container h1 {
  text-align: center;
}

.container form {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--tfd-spacing-md);
}

.container form > button {
  display: block;
  width: 100%;

  @media (--viewport-md) {
    margin: 0 auto;
    width: auto;
  }
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.button {
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
}
