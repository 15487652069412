.menu {
  background-color: var(--white);
}
.menuContainer {
  padding: var(--tfd-spacing-md);
  height: calc(100dvh - var(--tfd-appnav-height));
  overflow: auto;
  padding-bottom: 74px; /* ~ the height of the call to action  */
}
.listItem {
  padding: var(--tfd-spacing-sm) 0;
  border-bottom: 1px solid var(--oyster-1);
}
.listItem:last-child {
  border-bottom: 0;
}

.navItem {
  display: block;
  width: 100%;
  padding: var(--tfd-spacing-sm) var(--tfd-spacing-sm);
}

.cta {
  background-color: var(--white);
  position: fixed;
  left: var(--tfd-spacing-sm);
  bottom: 0;
  right: var(--tfd-spacing-sm);
  display: flex;
  justify-content: center;
  padding: var(--tfd-spacing-sm) 0;
}

.cta > button,
.cta > a {
  /* 
  Making the assumption the button is the only child
  To remove the flex behavior wrap the cta into a `<div /` 
  */
  flex: 1;
}
