.container {
  padding: var(--tfd-spacing-md);
  border-radius: 2px;
  border: 1px solid var(--oyster-2);
  background: var(--white);

  @media (--viewport-lg) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
  }
}

.titleAndImage {
  display: flex;
  justify-content: space-between;
}

.title > h4,
.title > h5 {
  margin: 0;
}

.title > h5 {
  margin-top: var(--tfd-spacing-xs);
}

.image {
  margin-left: 20px;
}

.indicationsList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  margin-top: var(--tfd-spacing-sm);
  align-items: last baseline;
}

.indicationsList > li {
  margin: 0 var(--tfd-spacing-sm) var(--tfd-spacing-sm) 0;
}

.indication {
  border-radius: 24px;
  border: 0.5px solid var(--oyster-2);
  background: var(--oyster-0);
  padding: var(--tfd-spacing-xxs) var(--tfd-spacing-sm);
}

.ingredients {
  margin-top: var(--tfd-spacing-sm);
}

.seeDetails {
  margin-top: var(--tfd-spacing-md);
}

.link {
  color: inherit;
}

.underline {
  text-decoration: underline;
}
