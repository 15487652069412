.container + .container {
  margin-left: 12px;
}

@media (--viewport-lg) {
  .container + .container {
    margin-left: 30px;
  }
}

.fullWidth {
  flex-shrink: 0;
  flex-basis: 100%;
}
