.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.background {
  padding: 0;
}

.top-wave {
  margin-bottom: -1px;
  width: 100%;
  display: block;
  transform: translateY(1px);
}

.bottom-wave {
  margin-top: -1px;
  width: 100%;
  display: block;
  transform: rotate(180deg) translateY(1px);
}

.margin-xxs {
  padding: var(--margin-xxs) 0;
}

.margin-xs {
  padding: var(--margin-xs) 0;
}

.margin-sm {
  padding: var(--margin-sm) 0;
}

.margin-md {
  padding: var(--margin-md) 0;
}

.margin-lg {
  padding: var(--margin-lg) 0;
}

.margin-xl {
  padding: var(--margin-xl) 0;
}

.margin-xxl {
  padding: var(--margin-xxl) 0;
}

.margin-3xl {
  padding: var(--margin-3xl) 0;
}

.margin-4xl {
  padding: var(--margin-4xl) 0;
}

.kale-3 .top-wave,
.kale-3 .bottom-wave {
  fill: var(--kale-3);
}

.kale-3 .background {
  background: var(--kale-3);
}

.white .top-wave,
.white .bottom-wave {
  fill: var(--white);
}

.white .background {
  background: var(--white);
}

.charcoal-3 .top-wave,
.charcoal-3 .bottom-wave {
  fill: var(--charcoal-3);
}

.charcoal-3 .background {
  background: var(--charcoal-3);
}

.charcoal-1 .top-wave,
.charcoal-1 .bottom-wave {
  fill: var(--charcoal-1);
}

.charcoal-1 .background {
  background: var(--charcoal-1);
}

.charcoal-0 .top-wave,
.charcoal-0 .bottom-wave {
  fill: var(--charcoal-0);
}

.charcoal-0 .background {
  background: var(--charcoal-0);
}

.oyster-0 .top-wave,
.oyster-0 .bottom-wave {
  fill: var(--oyster-0);
}

.oyster-0 .background {
  background: var(--oyster-0);
}

.kale-1 .top-wave,
.kale-1 .bottom-wave {
  fill: var(--kale-1);
  background: var(--kale-1);
}

.kale-1 .background {
  background: var(--kale-1);
}

.carrot-1 .top-wave,
.carrot-1 .bottom-wave {
  fill: var(--carrot-1);
}

.carrot-1 .background {
  background: var(--carrot-1);
}

.chickpea-2 .top-wave,
.chickpea-2 .bottom-wave {
  fill: var(--chickpea-2);
}

.chickpea-2 .background {
  background: var(--chickpea-2);
}

.chickpea-1 .top-wave,
.chickpea-1 .bottom-wave {
  fill: var(--chickpea-1);
}

.chickpea-1 .background {
  background: var(--chickpea-1);
}
