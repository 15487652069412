.root {
  background: var(--blueberry-0);
  border: 1px solid var(--blueberry-3);
  border-radius: var(--tfd-spacing-xs);
  margin: var(--tfd-spacing-xl) 0 var(--tfd-spacing-md) 0;
  padding: var(--tfd-spacing-sm);
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.promo {
  background-color: var(--kale-3);
  border-color: var(--kale-3);
  color: var(--white);
}

.positive {
  background-color: var(--kale-0);
  border-color: var(--kale-3);
  color: var(--kale-3);
}

.neutral {
  background-color: var(--blueberry-0);
  border-color: var(--blueberry-3);
  color: var(--blueberry-3);
}

.negative {
  background-color: var(--error-red-bg);
  border-color: var(--error-red-dark);
  color: var(--error-red-dark);
}

.icon {
  margin-right: var(--tfd-spacing-xs);
}

.promo .icon svg {
  fill: var(--white);
}

.positive .icon svg {
  fill: var(--kale-3);
}

.neutral .icon svg {
  fill: var(--blueberry-3);
}

.negative .icon svg {
  fill: var(--error-red-dark);
}
