.size-21 {
  font-size: 21px;
  line-height: var(--tfd-article-21-line-height);
}

 .size-20 {
  font-size: 20px;
  line-height: var(--tfd-article-20-line-height);
}

.size-16 {
  font-size: 16px;
  line-height: var(--tfd-article-16-line-height);
}

.size-12 {
  font-size: 12px;
  line-height: var(--tfd-article-12-line-height);
}

.size-1 {
  font-size: 18px;
  line-height: 1.67;
}

.size-2 {
  font-size: 14px;
  line-height: 1.71;
}

.size-3 {
  font-size: 12px;
  line-height: 1.5;
}
