.container {
  display: grid;
  margin: 0;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--tfd-spacing-lg);
}

@media (min-width: 500px) {
  .container {
    gap: var(--tfd-spacing-md);
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (--viewport-md) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }

  .container.diyContainer {
    gap: var(--tfd-spacing-lg);
  }
}

@media (--viewport-lg) {
  .container {
    grid-template-columns: repeat(4, 1fr);
  }
  .diyContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

.section {
  display: flex;
  align-content: start;
  gap: var(--tfd-spacing-md);
  flex-direction: row;

  @media (--viewport-lg) {
    flex-direction: column;
  }
}

.section .icon {
  height: 72px;
  width: 72px;
}

.section > div:last-child {
  flex: 1;
}
