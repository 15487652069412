.container,
.container *,
.container *::before,
.container *::after {
  box-sizing: border-box;
}

.container {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}

.container + .container {
  margin-left: var(--margin-md);
}

.wrap {
  white-space: unset;
}
