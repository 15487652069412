@media (--viewport-xs) {
  .justify-content-xs-flex-start {
    justify-content: flex-start;
  }
  .justify-content-xs-flex-end {
    justify-content: flex-end;
  }
  .justify-content-xs-space-between {
    justify-content: space-between;
  }
  .justify-content-xs-space-around {
    justify-content: space-around;
  }
  .justify-content-xs-center {
    justify-content: center;
  }

  .flex-direction-xs-row {
    flex-direction: row;
  }
  .flex-direction-xs-column {
    flex-direction: column;
  }
  .flex-direction-xs-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-direction-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .align-items-xs-flex-start {
    align-items: flex-start;
  }
  .align-items-xs-flex-end {
    align-items: flex-end;
  }
  .align-items-xs-center {
    align-items: center;
  }
  .align-items-xs-baseline {
    align-items: baseline;
  }
  .align-items-xs-stretch {
    align-items: stretch;
  }

  .grow-xs-1 > * {
    flex-grow: 1;
  }
  .grow-xs-0 > * {
    flex-grow: 0;
  }
}

@media (--viewport-sm) {
  .justify-content-sm-flex-start {
    justify-content: flex-start;
  }
  .justify-content-sm-flex-end {
    justify-content: flex-end;
  }
  .justify-content-sm-space-between {
    justify-content: space-between;
  }
  .justify-content-sm-space-around {
    justify-content: space-around;
  }
  .justify-content-sm-center {
    justify-content: center;
  }

  .flex-direction-sm-row {
    flex-direction: row;
  }
  .flex-direction-sm-column {
    flex-direction: column;
  }
  .flex-direction-sm-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-direction-sm-column-reverse {
    flex-direction: column-reverse;
  }

  .align-items-sm-flex-start {
    align-items: flex-start;
  }
  .align-items-sm-flex-end {
    align-items: flex-end;
  }
  .align-items-sm-center {
    align-items: center;
  }
  .align-items-sm-baseline {
    align-items: baseline;
  }
  .align-items-sm-stretch {
    align-items: stretch;
  }
  .grow-sm-1 > * {
    flex-grow: 1;
  }
  .grow-sm-0 > * {
    flex-grow: 0;
  }
}

@media (--viewport-md) {
  .justify-content-md-flex-start {
    justify-content: flex-start;
  }
  .justify-content-md-flex-end {
    justify-content: flex-end;
  }
  .justify-content-md-space-between {
    justify-content: space-between;
  }
  .justify-content-md-space-around {
    justify-content: space-around;
  }
  .justify-content-md-center {
    justify-content: center;
  }

  .flex-direction-md-row {
    flex-direction: row;
  }
  .flex-direction-md-column {
    flex-direction: column;
  }
  .flex-direction-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-direction-md-column-reverse {
    flex-direction: column-reverse;
  }

  .align-items-md-flex-start {
    align-items: flex-start;
  }
  .align-items-md-flex-end {
    align-items: flex-end;
  }
  .align-items-md-center {
    align-items: center;
  }
  .align-items-md-baseline {
    align-items: baseline;
  }
  .align-items-md-stretch {
    align-items: stretch;
  }

  .grow-md-1 > * {
    flex-grow: 1;
  }
  .grow-md-0 > * {
    flex-grow: 0;
  }
}

@media (--viewport-lg) {
  .justify-content-lg-flex-start {
    justify-content: flex-start;
  }
  .justify-content-lg-flex-end {
    justify-content: flex-end;
  }
  .justify-content-lg-space-between {
    justify-content: space-between;
  }
  .justify-content-lg-space-around {
    justify-content: space-around;
  }
  .justify-content-lg-center {
    justify-content: center;
  }

  .flex-direction-lg-row {
    flex-direction: row;
  }
  .flex-direction-lg-column {
    flex-direction: column;
  }
  .flex-direction-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-direction-lg-column-reverse {
    flex-direction: column-reverse;
  }

  .align-items-lg-flex-start {
    align-items: flex-start;
  }
  .align-items-lg-flex-end {
    align-items: flex-end;
  }
  .align-items-lg-center {
    align-items: center;
  }
  .align-items-lg-baseline {
    align-items: baseline;
  }
  .align-items-lg-stretch {
    align-items: baseline;
  }

  .grow-lg-1 > * {
    flex-grow: 1;
  }
  .grow-lg-0 > * {
    flex-grow: 0;
  }
}
@media (--viewport-xl) {
  .justify-content-xl-flex-start {
    justify-content: flex-start;
  }
  .justify-content-xl-flex-end {
    justify-content: flex-end;
  }
  .justify-content-xl-space-between {
    justify-content: space-between;
  }
  .justify-content-xl-space-around {
    justify-content: space-around;
  }
  .justify-content-xl-center {
    justify-content: center;
  }

  .flex-direction-xl-row {
    flex-direction: row;
  }
  .flex-direction-xl-column {
    flex-direction: column;
  }
  .flex-direction-xl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-direction-xl-column-reverse {
    flex-direction: column-reverse;
  }

  .align-items-xl-flex-start {
    align-items: flex-start;
  }
  .align-items-xl-flex-end {
    align-items: flex-end;
  }
  .align-items-xl-center {
    align-items: center;
  }
  .align-items-xl-baseline {
    align-items: baseline;
  }
  .align-items-xl-stretch {
    align-items: stretch;
  }

  .grow-xl-1 > * {
    flex-grow: 1;
  }
  .grow-xl-0 > * {
    flex-grow: 0;
  }
}
