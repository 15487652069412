.width-constraint {
  --width-constraint: auto;
  margin: 0 auto;
  max-width: var(--width-constraint);
}

@media (--viewport-xs) {
  .xs-width-constraint-sm {
    --width-constraint: var(--tfd-constraint-width-sm);
  }

  .xs-width-constraint-md {
    --width-constraint: var(--tfd-constraint-width-md);
  }

  .xs-width-constraint-lg {
    --width-constraint: var(--tfd-constraint-width-lg);
  }
}

@media (--viewport-sm) {
  .sm-width-constraint-sm {
    --width-constraint: var(--tfd-constraint-width-sm);
  }

  .sm-width-constraint-md {
    --width-constraint: var(--tfd-constraint-width-md);
  }

  .sm-width-constraint-lg {
    --width-constraint: var(--tfd-constraint-width-lg);
  }
}

@media (--viewport-md) {
  .md-width-constraint-sm {
    --width-constraint: var(--tfd-constraint-width-sm);
  }

  .md-width-constraint-md {
    --width-constraint: var(--tfd-constraint-width-md);
  }

  .md-width-constraint-lg {
    --width-constraint: var(--tfd-constraint-width-lg);
  }
}

@media (--viewport-lg) {
  .lg-width-constraint-sm {
    --width-constraint: var(--tfd-constraint-width-sm);
  }

  .lg-width-constraint-md {
    --width-constraint: var(--tfd-constraint-width-md);
  }

  .lg-width-constraint-lg {
    --width-constraint: var(--tfd-constraint-width-lg);
  }
}

@media (--viewport-xl) {
  .xl-width-constraint-sm {
    --width-constraint: var(--tfd-constraint-width-sm);
  }

  .xl-width-constraint-md {
    --width-constraint: var(--tfd-constraint-width-md);
  }

  .xl-width-constraint-lg {
    --width-constraint: var(--tfd-constraint-width-lg);
  }
}
