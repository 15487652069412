.card {
  padding: var(--tfd-spacing-md);
  @media (--viewport-lg) {
    padding: var(--tfd-spacing-xl);
  }
}

.sharp {
  border-radius: 2px;

  padding: var(--tfd-spacing-md);
  @media (--viewport-lg) {
    padding: var(--tfd-spacing-lg);
  }
}
