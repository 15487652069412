.toast {
  width: 100%;
  margin: 12px 10px;
  min-height: 26px;
  padding: var(--margin-sm) var(--margin-md);
  border-radius: 4px;
  border: 2px solid transparent;

  /* Restore pointer event overridden by toast container */
  pointer-events: auto;
}

@media (--viewport-sm) {
  .toast {
    margin: 12px 30px;
  }
}

@media (--viewport-md) {
  .toast {
    margin: 12px auto;
    max-width: 630px;
  }
}

@media (--viewport-lg) {
  .toast {
    margin: 12px auto;
    max-width: 720px;
  }
}

.toast + .toast {
  margin-top: 12px;
}

.neutral {
  border-color: var(--chickpea-2);
  background: var(--chickpea-1);
}

.negative {
  border-color: #e0aa9f;
  background: var(--carrot-1);
}

.positive {
  border-color: #9bb5a0;
  background: var(--kale-1);
}
