/** 
 * Use this class to create a visually hidden element that is still available to
 * screen readers. For more information read on the w3 website
 *
 * https://www.w3.org/WAI/tutorials/forms/labels/#note-on-hiding-elements
 */
.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
