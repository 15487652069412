.container {
  background: var(--oyster-0);
  border-radius: 12px;
  padding: var(--margin-md);
  box-sizing: border-box;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 30%;
  padding-bottom: var(--margin-md);
  border-bottom: 4px dotted var(--charcoal-1);
}

.header .link {
  text-decoration: underline;
  cursor: pointer;
}

.information {
  margin-top: var(--margin-md);
}

.information div {
  margin-top: var(--margin-sm);
  display: flex;
  flex-direction: column;
  column-gap: var(--margin-xs);
}

.informationItem {
  margin-top: var(--margin-xs);
  width: 100%;
}
