.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  padding-bottom: var(--margin-md);
}

.socialList .item {
  margin-right: var(--margin-md);
}

.link {
  margin-bottom: var(--margin-md);
  display: inline-block;
  width: 100%;
}

.socialList {
  display: flex;
}

.contactLink {
  display: flex;
}

.contactLink .icon {
  width: 22px;
  height: 22px;
  margin-right: var(--margin-xxs);
  vertical-align: middle;
}
