.container {
  width: 100%;
  height: calc(
    var(--tfd-fieldlabel-height) + var(--tfd-fieldlabel-text-offset)
  );
  box-sizing: border-box;
  padding-top: var(--tfd-fieldlabel-text-offset);
  display: inline-block;
}

.containerNoLabel {
  height: var(--tfd-fieldlabel-height);
  padding-top: 0;
}

.label {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: var(--tfd-fieldlabel-radius);
  border: solid 1px var(--charcoal-0);
  transition: all 100ms ease-in;
  transition-property: border-color;
  background-color: var(--white);
  white-space: nowrap;
  text-align: left;
}

.normal {
  border-color: var(--charcoal-0);
}

.focus {
  border-color: var(--kale-3);
}

.invalid {
  border-color: var(--error-red);
}

.disabled {
  border-color: var(--charcoal-0);
  background-color: var(--oyster-0);
}

.highlighted {
  border-color: var(--kale-1);
  outline: 1px solid var(--kale-1);
}
