.container {
    max-width: 588px;
    margin: 0 auto;
    padding-top: var(--tfd-spacing-lg);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--tfd-spacing-md);
    margin: 0 var(--margin-lg);

    @media (--viewport-md) {
        margin: 0 auto;

    }
}

section.practiceSelector {
    background: transparent;
}

section.practiceSelector>div:first-child {
    padding-top: 0;
}

section.practiceSelector>div {
    padding-left: 0;
    padding-right: 0;
}

.mobile {
    display: block;

    @media (--viewport-lg) {
        display: none;
    }
}

.desktop {
    display: none;

    @media (--viewport-lg) {
        display: block;
    }
}
