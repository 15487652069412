.button {
  height: 48px;
  width: 48px;
  border-radius: var(--tfd-border-radius-sm);
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button.default {
  background: none;
}

.button.white-bg {
  border-radius: 50%;
  background: var(--white);
}
