.button {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
  vertical-align: middle;
  cursor: pointer;
  appearance: none;
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgb(from var(--carrot-2) r g b / 25%);
}
