.speechBubble {
  margin: 0 auto;
  position: relative;
  text-align: center;
  max-width: 276px;
  padding: var(--margin-sm) var(--margin-md);
}

.kale {
  background-color: #e5efe7;
}

.white {
  background-color: white;
  border: 1px solid var(--carrot-2);
}

.carrot {
  background-color: var(--carrot-0);
  border: 1px solid var(--carrot-3);
}

@media (--viewport-lg) {
  .speechBubble {
    max-width: fit-content;
  }
}
