.nav {
  align-items: center;
  display: flex;
  height: var(--tfd-appnav-height);
  justify-content: center;
  margin: 0 var(--tfd-spacing-md);
}

.logo, .cta {
  /* TODO: Fix this in a more sensible way */
  z-index: calc(var(--tfd-appnav-z-index) + 1);
}

@media (--viewport-lg) {
  .nav {
    margin: 0 var(--tfd-spacing-xl);
  }
}

/* TODO: fix using @media(--viewport-md) instead of @media with max-width */
@media (max-width: 1024px) {
  .cta + .logo {
    display: none;
  }
}

@media (--viewport-lg) {
  .cta {
    display: none;
  }
}

.navItem {
  display: inline-block;
  position: relative;
  margin: 0 calc(var(--tfd-spacing-lg) / 2);
}

.largeNav {
  display: none;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
  margin-left: calc(var(--tfd-spacing-lg) / 2);
}

@media (--viewport-lg) {
  .largeNav {
    display: flex;
  }
}

.largeNavLeft {
  display: flex;
  overflow: hidden;
  align-items: center;
}
.largeNavRight {
  display: flex;
  justify-items: flex-end;
  align-items: center;
}

.largeNavRight span:last-child > button,
.largeNavRight span:last-child > a,
.largeNavRight span:last-child > span {
  margin-right: 0;
}

.smallNav {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--tfd-appnav-height);
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--tfd-appnav-z-index);
  padding: 0 var(--tfd-spacing-md);
}

.smallNav span:last-child a {
  margin-right: 0;
}
.smallNav > div {
  display: flex;
  align-items: center;
}
@media (--viewport-lg) {
  .smallNav {
    display: none;
  }
}
