.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.background {
  padding: 0;
}

.top-wave {
  margin-bottom: -1px;
  width: 100%;
  display: block;
  transform: translateY(1px);
}

.bottom-wave {
  margin-top: -1px;
  width: 100%;
  display: block;
  transform: rotate(180deg) translateY(1px);
}

.margin-xxs {
  padding: var(--margin-xxs) 0;
}

.margin-xs {
  padding: var(--margin-xs) 0;
}

.margin-sm {
  padding: var(--margin-sm) 0;
}

.margin-md {
  padding: var(--margin-md) 0;
}

.margin-lg {
  padding: var(--margin-lg) 0;
}

.margin-xl {
  padding: var(--margin-xl) 0;
}

.margin-xxl {
  padding: var(--margin-xxl) 0;
}

.margin-3xl {
  padding: var(--margin-3xl) 0;
}

.margin-4xl {
  padding: var(--margin-4xl) 0;
}
