.subtitle {
  margin: var(--margin-xs) var(--margin-md) var(--margin-md);

  @media (--viewport-lg) {
    margin: var(--margin-sm) 0 var(--margin-md);
  }
}

.container {
  display: block;
}
