/* todo: remove this workaround when migrated to AppNav which has a default z-index lower than the Toast component */

.appBarWrapper > * {
  /* Or calc(var(--tfd-toast-z-index) - 1); */
  z-index: var(--tfd-appnav-z-index);
  transition: all 0.3s ease-in-out;
}

div[data-name='AppBar'] div[data-name='CallToAction'] {
  min-width: max-content;
}
