.link {
  color: var(--kale-2);
  font-family: var(--tfd-font-family);
  font-size: 16px;
  text-decoration: underline;
}

.accordionCard {
  padding-top: var(--margin-sm);
}

/* accordion heading override */
.accordion h3 {
  color: var(--blueberry-3);
  font-weight: bold;
}

/* accordion panel override */
.accordion > div {
  border-top: unset;
  border-bottom: 1px solid var(--blueberry-1) !important;
}
