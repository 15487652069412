.container {
  position: absolute;
  pointer-events: none;
  right: 4px;
  top: 6px;
  line-height: 0;
}


.caret.left,
.caret.right {
  position: absolute;
  width: 4px;
  height: 100%;
  background: var(--charcoal-0);
}

.caret.right {
  right: 0;
  transform: rotate(-135deg);
}

.caret.left {
  left: 0;
  transform: rotate(135deg);
}

.chevron {
  transform: rotate(90deg);
}
