.content {
  --spacing-lg: 20px;
  --spacing-xl: 30px;

  /* Up to medium viewports it should cover the entire container */
  --content-width-xs: 100%;
  --content-width-sm: 100%;
  --content-width-md: 100%;

  /* From large viewports restrict the content width. (TODO: how are these calculated) */
  --content-width-lg: 300px;
  --content-width-xl: 375px;
}

.content {
  width: var(--content-width-xs);
  box-sizing: border-box;
}

.content.start {
  margin-left: 0;
}

.content.end {
  margin-right: 0;
  margin-left: auto;
}

.content.center {
  margin-left: auto;
  margin-right: auto;
}

@media (--viewport-sm) {
  .content {
    width: var(--content-width-sm);
  }
}

@media (--viewport-md) {
  .content {
    width: var(--content-width-md);
  }
}

@media (--viewport-lg) {
  .content,
  .content.fr-1 {
    width: var(--content-width-lg);
  }

  .content.fr-2 {
    width: calc(var(--content-width-lg) * 2 + var(--spacing-lg));
  }

  .content.fr-3 {
    width: 100%;
  }

  .content.withPadding {
    padding: 0 var(--spacing-lg);
  }

  .content.withGutter {
    margin-right: var(--spacing-lg);
  }
}

@media (--viewport-xl) {
  .content,
  .content.fr-1 {
    width: var(--content-width-xl);
  }

  .content.fr-2 {
    width: calc(var(--content-width-xl) * 2 + var(--spacing-xl));
  }

  .content.fr-3 {
    width: 100%;
  }

  .content.withPadding {
    padding: 0 var(--spacing-xl);
  }

  .content.withGutter {
    margin-right: var(--spacing-xl);
  }
}
