.section {
  width: 100%;
}

.infoContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: var(--tfd-spacing-md);
}

.membersContainer {
  display: flex;
  column-gap: var(--tfd-spacing-md);
  flex-direction: column;
  align-items: center;
  row-gap: var(--tfd-spacing-lg);

  @media (--viewport-lg) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.signupLink {
  text-decoration: underline;
  color: var(--kale-2);
}
