@import './color/color-variables.css';

/* Ex @farmersdog/corgi variables added for backward compatibility. */
/* Do not use these variables in corgi-x. See https://app.shortcut.com/farmersdog/story/79291 */
:root {
  /* Margins (now named "spacings") */
  --margin-xxs: 2px;
  --margin-xs: 6px;
  --margin-sm: 12px;
  --margin-md: 24px;
  --margin-lg: 36px;
  --margin-xl: 48px;
  --margin-xxl: 72px;
  --margin-3xl: 96px;
  --margin-4xl: 120px;

  /* Animation timing */
  --animation-standard: 300ms ease-in-out;
  --animation-standard-timing-function: ease-in-out;
  --animation-standard-duration: 300ms;
}

/* --tfd variables. Use these variables in the corgi-x components. */
/* The list is still incomplete and meant to change. */
:root {
  --tfd-font-family: 'neue-haas-grotesk-display', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  --tfd-font-family-text: 'neue-haas-grotesk-text', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  --tfd-font-family-serif: tiempos-text, serif;
  --tfd-font-family-serif-headline: tiempos-headline, serif;

  --tfd-border-radius-xs: 2px;
  --tfd-border-radius-sm: 4px;
  --tfd-border-radius-md: 6px;
  --tfd-border-radius-lg: 20px;

  --tfd-background: var(--chickpea-1);

  /* Use these variables to set max width constraints. See Constraints.tsx for the values. */
  --tfd-constraint-width-sm: 375px;
  --tfd-constraint-width-md: 800px;
  --tfd-constraint-width-lg: 1200px;

  /* Old constraints that should not be used. See https://app.shortcut.com/farmersdog/story/109645. */
  --tfd-max-width-sm: 800px;
  --tfd-max-width: 1200px;

  /* Use this as box-shadow for focus outlines */
  --tfd-outline-box-shadow: 0 0 0 2px var(--charcoal-0);

  /* Use these variables to set the spacing between elements. If you need to access to these values in TypeScript, use the `Spacing` enum instead. */
  --tfd-spacing-xxs: 2px;
  --tfd-spacing-xs: 6px;
  --tfd-spacing-sm: 12px;
  --tfd-spacing-md: 24px;
  --tfd-spacing-lg: 36px;
  --tfd-spacing-xl: 48px;
  --tfd-spacing-xxl: 72px;
  --tfd-spacing-3xl: 96px;
  --tfd-spacing-4xl: 120px;

  --tfd-animation-standard: 300ms ease-in-out;
  --tfd-animation-standard-timing-function: ease-in-out;
  --tfd-animation-standard-duration: 300ms;

  /* Components vars that can be overridden by consumers. */
  --tfd-appnav-z-index: 4;
  --tfd-appnav-height: 74px;
  --tfd-toast-z-index: 5;
  --tfd-lightbox-z-index: auto;
  --tfd-banner-min-height: 52px; /* Height of the banner of a one line of text */

  --tfd-fieldlabel-text-offset: 4px;
  --tfd-fieldlabel-padding: 12px;
  --tfd-fieldlabel-height: 50px;
  --tfd-fieldlabel-radius: 4px;

  --tfd-styledselect-height: 50px;

  /* Override typography in legacy corgi. */
  --tfd-article-72-line-height: 84px;
  --tfd-article-52-line-height: 62px;
  --tfd-article-40-line-height: 48px;
  --tfd-article-28-line-height: 34px;
  --tfd-article-20-line-height: 32px;
  --tfd-article-16-line-height: 26px;
  --tfd-article-12-line-height: 20px;
  --tfd-heading-72-line-height: 84px;
  --tfd-heading-52-line-height: 62px;
  --tfd-heading-40-line-height: 48px;
  --tfd-heading-28-line-height: 34px;
  --tfd-heading-22-line-height: 30px;
  --tfd-heading-16-line-height: 22px;
  --tfd-heading-12-line-height: 18px;
  --tfd-dynamic-bold-heading: 900;
  --tfd-togglebox-badge-padding: 3px var(--margin-sm);
}
