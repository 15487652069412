@media (--viewport-lg) {
  .stickyTitle {
    position: sticky;
    top: 87px;
    display: flex;
    flex-direction: column;
    row-gap: var(--margin-md);
  }
}

.section {
  width: 100%;
  text-align: center;
  background-color: var(--kale-0);
  padding: var(--margin-xxl) 0;

  @media (--viewport-lg) {
    text-align: left;
  }
}

.description {
  margin: var(--margin-sm) auto var(--margin-xl) auto;

  @media (--viewport-md) {
    max-width: 330px;
  }

  @media (--viewport-lg) {
    max-width: unset;
    margin: 0;
  }
}

.benefitsContainer {
  display: flex;
  flex-direction: column;
  row-gap: var(--margin-lg);
  margin-top: var(--margin-md);

  @media (--viewport-lg) {
    margin-top: unset;
  }
}

a.button {
  display: block;
  margin: 0 auto;

  @media (--viewport-md) {
    width: 50%;
  }

  @media (--viewport-lg) {
    margin: 0;
  }
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
