.container {
  height: 32px;
  box-sizing: border-box;
  padding: var(--tfd-spacing-xs);
  background-color: white;
  border-radius: 48px;
  border: 1px solid var(--oyster-1);
}

.pill {
  background-color: var(--carrot-2);
  height: 18px;
  width: 18px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
}

.title {
  flex: 1;
}
