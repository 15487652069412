.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  position: relative;
}

.horizontalSnap {
  flex: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.next {
  margin-left: 12px;
}

.previous {
  margin-right: 12px;
}

.blank {
  min-height: 100%;
  min-width: 1px;
  display: block;
  opacity: 0;
}
