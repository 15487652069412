.container {
  background: var(--oyster-0);
  width: 100%;
  border-radius: 12px;
  padding: var(--tfd-spacing-md);
  box-sizing: border-box;
}

.container h2 {
  margin: 0;
}

.container .addressTitle {
  margin-top: var(--tfd-spacing-sm);
}

.button {
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
}
