.container {
  display: flex;
  justify-content: center;
}

.padding {
  padding: var(--margin-md) 0;
}

@media (--viewport-md) {
  .padding {
    padding: var(--margin-md) 18px;
  }
}

.link {
  min-height: 0;
  text-align: center;
}

.fullWidth {
  width: 100%;
}
