.profileAndTitleSection {
  text-align: center;
}

.titleSection {
  margin-top: var(--tfd-spacing-md);
}

.name {
  margin: 0 0;
}

.education {
  margin: 0 0;
}

.position {
  margin-top: var(--tfd-spacing-xs);
  margin-bottom: var(--tfd-spacing-lg);

  @media (--viewport-lg) {
    margin-bottom: var(--tfd-spacing-xl);
  }
}
