.heroContainer {
  padding-left: 0;
  padding-bottom: 0;
  text-align: center;
  background-color: var(--kale-3);
  @media (--viewport-lg) {
    text-align: left;
    padding: var(--margin-3xl);
    padding: var(--margin-3xl) var(--margin-3xl) 0 var(--margin-3xl);
    min-height: 500px;
  }
}

.list {
  width: 100%;
  margin: 0 auto;
  max-width: 327px;
  padding: 0;
  list-style: none;
  display: inline-flex;
  flex-direction: column;
  gap: var(--margin-sm);
  margin-top: var(--margin-xl);
  @media (--viewport-lg) {
    width: auto;
    max-width: none;
    margin-top: 0;
    gap: var(--margin-md);
    align-self: center;
  }
}

.listItem {
  display: flex;
  align-items: start;
  gap: var(--margin-sm);

  @media (--viewport-lg) {
    align-items: center;
  }
}

.listItemText {
  margin: 0;
  letter-spacing: 0.5;
  text-align: left;
}

.checkIcon {
  flex-shrink: 0;
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.textContainer {
  padding: 0 var(--margin-md);
  max-width: 327px;
  margin: 0 auto;

  @media (--viewport-lg) {
    padding: 0;
    max-width: none;
  }
}

.registerButton {
  width: 100%;
  max-width: 327px;
  box-sizing: border-box;
  @media (--viewport-lg) {
    width: auto;
  }
}
