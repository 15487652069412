.button {
  height: var(--tfd-styledselect-height);
  margin: 0;
  padding: 0 0 0 var(--margin-sm);
  width: 100%;
  height: 100%;
  display: block;
  background: transparent;
  border: none;
  appearance: none;
  border-radius: var(--tfd-fieldlabel-radius);
  box-sizing: border-box;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button:focus {
  outline: none;
}

.buttonText,
.buttonIcon {
  display: block;
}

.buttonText {
  width: 0;
  flex: 1 0;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.buttonIcon {
  pointer-events: none;
}

.buttonIcon.isOpen {
  transform: rotate(180deg);
}
.chevron.buttonIcon {
  transform: rotate(90deg);
}
.chevron.buttonIcon.isOpen {
  transform: rotate(-90deg);
}

.container {
  display: inline-block;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.boldSelectedValue {
  letter-spacing: 0;
  line-height: 26px;
}
