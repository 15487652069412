.root {
  position: relative;
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.options > label {
  /* TOFIX: this var never worked. Should it be `var(--tfd-constraint-width-sm)` instead? */
  max-width: var(--viewport-sm);
  flex: 1;
  margin-top: var(--tfd-spacing-sm);
}

.activeImage {
  text-align: center;
}

.activeImage picture img {
  margin-top: var(--tfd-spacing-lg);
  height: 144px;
}

@media (--viewport-lg) {
  .activeImage {
    display: none;
  }
}

.description {
  padding: 0 var(--margin-sm);
  text-align: center;
}
