.entering {
  transform: scale(1);
  opacity: 1;
}

.entered {
  transform: scale(1);
  opacity: 1;
}

.exiting {
  transform: scale(0.8);
  opacity: 0;
}

.exited {
  transform: scale(0.8);
  opacity: 0;
}
