.select {
  /** Remove the inheritance of text transform in Firefox. */
  text-transform: none;
  /** Remove the margin in Firefox and Safari */
  margin: 0;
  padding: 12px;
  padding-right: 48px;
  width: 100%;
  height: 100%;
  display: block;
  background: transparent;
  border: none;
  appearance: none;
  border-radius: 4px;
  color: var(--kale-3);
  font-size: 16px;
}

.select:focus {
  outline: none;
}

.select:-webkit-autofill {
  box-shadow: inset 0 0 0 9999px var(--white);
}

/** Hide IE 11 select arrow */
.select::-ms-expand {
  display: none;
}

.select.regular {
  font-family: var(--tfd-font-family);
  letter-spacing: 0.5px;
}

.select.bold {
  font-family: var(--tfd-font-family-text);
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
}

.iconRoot {
  position: absolute;
  pointer-events: none;
  right: 4px;
  top: 6px;
  line-height: 0;
}

.caret.left,
.caret.right {
  position: absolute;
  width: 4px;
  height: 100%;
  background: var(--charcoal-0);
}

.caret.right {
  right: 0;
  transform: rotate(-135deg);
}

.caret.left {
  left: 0;
  transform: rotate(135deg);
}

.chevron {
  transform: rotate(90deg);
}
