.facts {
  margin: var(--margin-xl) var(--margin-md) 0 var(--margin-md);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (--viewport-lg) {
    margin-top: calc(var(--margin-xxl) + var(--margin-md));
    max-width: 860px;
  }
}

.registerLink {
  text-decoration: underline;
}

.secondaryNavBar {
  position: sticky;
  z-index: 100;
  display: block;
  top: 0;
}

.secondaryNavBar a.smallerFont {
  font-size: var(--tfd-spacing-sm);
}
