.container {
  max-width: 480px;
  display: flex;
  flex-direction: column;
  row-gap: var(--tfd-spacing-xs);
  text-align: center;

  @media (--viewport-lg) {
    text-align: left;
  }
}

.icon {
  position: relative;
  top: 4px;
  max-width: 21.75px;
  margin-right: var(--tfd-spacing-xs);
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
