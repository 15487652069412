.button,
a.button,
button.button {
  font-family: var(--tfd-font-family);
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 22px;
}

.button:not(.plain-text) {
  text-align: center;
  border-radius: var(--tfd-border-radius-sm);
}

.disabled,
a.button.disabled {
  cursor: default;
}

.button.primary {
  font-size: 16px;
  padding: 14px 24px;
  background-color: var(--carrot-2);
  color: var(--white);
}

.button.primary-mini {
  font-size: 16px;
  padding: 7px 12px;
  background-color: var(--carrot-2);
  color: var(--white);
}

.button.secondary {
  border: solid 2px var(--carrot-2);
  background-color: var(--white);
  color: var(--carrot-2);
  font-size: 16px;
  padding: 12px 22px;
}

.button.secondary-mini {
  font-size: 16px;
  padding: 5px 12px;
  border: solid 2px var(--carrot-2);
  background-color: var(--white);
  color: var(--carrot-2);
}

.button.special {
  background-color: var(--carrot-2);
  color: var(--white);
  font-size: 16px;
  border-radius: 30px;
  padding: 14px 36px;
}

.button.special-kale-experimental {
  color: var(--white);
  border-radius: 30px;
  padding: var(--tfd-spacing-xs, 6px) var(--tfd-spacing-sm, 12px);
  background-color: var(--kale-2);
  font-size: 12px;
}

.button.primary-mini-kale-experimental {
  padding: 7px 12px;
  background-color: var(--kale-2);
  color: var(--white);
}

.button.secondary-mini-kale-experimental {
  padding: 5px 12px;
  border: solid 2px var(--kale-2);
  background-color: var(--white);
  color: var(--kale-2);
}

.button.plain-text {
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  transition: color var(--animation-standard);
  color: var(--carrot-2);
}

.button.plain-text.loading,
.button.plain-text.loading:hover {
  color: transparent !important;
}

.button.plain-text[aria-disabled='true'].white,
.button.plain-text.disabled.white {
  color: var(--charcoal-1);
}

/* Font sizes are enabled only for plain-text variant. */
.button.plain-text.lg {
  font-size: 22px;
}
.button.plain-text.md {
  font-size: 16px;
}
.button.plain-text.sm {
  font-size: 12px;
}

/* Font settings are enabled only for plain-text variant. */
.button.plain-text.underline {
  text-decoration: underline;
}
.button.plain-text.bold {
  font-weight: bold;
}
.button.plain-text.normal {
  font-weight: normal;
}

/* Colors are enabled only for plain-text variant. */
.button.plain-text.carrot {
  color: var(--carrot-2);
}
.button.plain-text.carrot:hover,
.button.plain-text.carrot.hover {
  color: var(--carrot-3);
}
.button.plain-text.carrot:active,
.button.plain-text.carrot.active {
  color: var(--carrot-2);
}
.button.plain-text.kale {
  color: var(--kale-2);
}
.button.plain-text.kale .indicator svg {
  stroke: var(--kale-2);
}
.button.plain-text.kale:hover,
.button.plain-text.kale.hover {
  color: var(--kale-3);
}
.button.plain-text.kale:active,
.button.plain-text.kale.active {
  color: var(--kale-2);
}

.plain-text.kale-dark {
  color: var(--kale-3);
}
.plain-text.kale-dark .indicator svg {
  stroke: var(--kale-3);
}
.plain-text.kale-dark:hover,
.plain-text.kale-dark.hover {
  color: var(--carrot-2);
}
.plain-text.kale-dark:active,
.plain-text.kale-dark.active {
  color: var(--carrot-2);
}

.button.plain-text.white {
  color: var(--white);
}
.button.plain-text.white .indicator svg {
  stroke: var(--white);
}

.button.plain-text.white:hover,
.button.plain-text.white.hover {
  color: var(--white);
}
.button.plain-text.white:active,
.button.plain-text.white.active {
  color: var(--white);
}

.button.plain-text:not(.loading)[aria-disabled='true'],
.button.plain-text:not(.loading).disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
  color: var(--charcoal-2);
}

.button.primary:hover,
.button.primary.hover,
.button.primary-mini:hover,
.button.primary-mini.hover,
.button.special:hover,
.button.special.hover {
  background-color: var(--carrot-3);
}

.button.special-kale-experimental:hover,
.button.special-kale-experimental.hover,
.button.primary-mini-kale-experimental:hover,
.button.primary-mini-kale-experimental.hover {
  background-color: var(--carrot-2);
}

.button.primary:active,
.button.primary.active,
.button.primary-mini:active,
.button.primary-mini.active,
.button.special:active,
.button.special.active {
  background-color: var(--carrot-3);
}

.button.special-kale-experimental.active,
.button.primary-mini-kale-experimental.active {
  background-color: var(--carrot-2);
}

.button.primary.disabled,
.button.primary-mini.disabled,
.button.special.disabled,
.button.special-kale-experimental.disabled,
.button.primary-mini-kale-experimental.disabled,
.button.secondary-mini-kale-experimental.disabled {
  background-color: var(--charcoal-2);
}

.button.primary.loading,
.button.primary-mini.loading,
.button.special.loading {
  background-color: var(--carrot-3);
}

.button.secondary-mini-kale-experimental:hover,
.button.secondary-mini-kale-experimental.hover {
  border-color: var(--kale-3);
  background-color: var(--white);
  color: var(--kale-3);
}

.button.secondary-mini-kale-experimental:active,
.button.secondary-mini-kale-experimental.active {
  border-color: var(--kale-3);
  background-color: var(--white);
  color: var(--kale-3);
}

.button.secondary-mini-kale-experimental.disabled {
  border-color: var(--charcoal-2);
  background-color: var(--white);
  color: var(--charcoal-2);
}

.button.secondary:hover,
.button.secondary.hover,
.button.secondary-mini:hover,
.button.secondary-mini.hover {
  border-color: var(--carrot-3);
  background-color: var(--white);
  color: var(--carrot-3);
}

.button.secondary:active,
.button.secondary.active,
.button.secondary-mini:active,
.button.secondary-mini.active {
  border-color: var(--carrot-3);
  background-color: var(--white);
  color: var(--carrot-3);
}

.button.secondary.disabled,
.button.secondary-mini.disabled {
  border-color: var(--charcoal-2);
  background-color: var(--white);
  color: var(--charcoal-2);
}

.button.secondary.loading,
.button.secondary-mini.loading {
  border-color: var(--carrot-3);
  background-color: var(--white);
  color: var(--carrot-3);
}

/* Loading state */
.loading {
  position: relative;
  display: inline-flex; /* Align activity indicator */
  min-width: 58px; /* Make sure button is large enough for the activity indicator */
}

/* Activity indicator is placed inside this container to cover the button. */
.indicator {
  font-size: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
}
