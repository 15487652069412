.size-21 {
  font-size: 21px;
  line-height: 1.52;
  letter-spacing: normal;
}

.size-16 {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
}

.size-12 {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: normal;
}

.size-1 {
  font-size: 18px;
  line-height: 1.66;
  letter-spacing: normal;
}

.size-2 {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: normal;
}

.size-3 {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: normal;
}