.container {
  margin-top: var(--margin-xs);
  padding: 0 var(--margin-sm);
  color: var(--carrot-2);
  text-align: center;
}

.position {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}
