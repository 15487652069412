.button {
  overflow: hidden; /* fixes outline issues in Safari desktop on focus */
}

.bar {
  width: var(--tfd-spacing-md);
  height: 2px;
  border-radius: 1px;
  background-color: var(--charcoal-3);
  position: relative;

  transition: all ease-in-out 200ms;
  transition-property: opacity, transform, background-color;
}

.bar.darkTheme {
  background-color: var(--white);
}

.bar {
  margin: var(--tfd-spacing-xs) 0;
}

.isOpen .bar:nth-child(1) {
  transform: translateY(calc(var(--tfd-spacing-xs) + 2px)) rotate(135deg);
}

.isOpen .bar:nth-child(2) {
  opacity: 0;
}

.isOpen .bar:nth-child(3) {
  transform: translateY(calc(-1 * var(--tfd-spacing-xs) - 2px)) rotate(-135deg);
}
