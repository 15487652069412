.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (--viewport-lg) {
  .container {
    flex: 1;
    height: 164px;
  }
}

.link {
  margin-top: var(--tfd-spacing-md);
}

@media (--viewport-lg) {
  .link {
    margin-top: auto;
  }
}

.svg {
  /* these are the dimensions of the svg's, this needs to be hardcoded since at build time webpack is not recognizing the svg dimensions */
  width: 24px;
  height: 32px;
}
