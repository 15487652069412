.portal {
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100vh;
  z-index: var(--tfd-toast-z-index);
  /* Make sure elements behind can be clicked */
  pointer-events: none;
}

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.toast {
  margin: var(--tfd-spacing-sm);
  min-height: 26px;
  padding: var(--tfd-spacing-sm) var(--tfd-spacing-md);
  border: 2px solid transparent;
  border-radius: var(--tfd-border-radius-sm);
  box-sizing: border-box;
  /* Restore pointer event overridden by toast container */
  pointer-events: auto;
}

@media (--viewport-sm) {
  .toast {
    margin: var(--tfd-spacing-sm) var(--tfd-spacing-lg);
  }
}

@media (--viewport-md) {
  .toast {
    margin: var(--tfd-spacing-sm) auto;
    max-width: 630px;
  }
}

@media (--viewport-lg) {
  .toast {
    margin: var(--tfd-spacing-sm) auto;
    max-width: 720px;
  }
}

.toast + .toast {
  margin-top: var(--tfd-spacing-sm);
}

.neutral {
  border-color: var(--chickpea-2);
  background: var(--chickpea-1);
}

.negative {
  border-color: #e0aa9f;
  background: var(--carrot-1);
}

.positive {
  border-color: #9bb5a0;
  background: var(--kale-1);
}

.document {
  display: flex;
  flex-direction: row;
}

.message {
  flex: 1;
}
