.input,
.button {
  width: 100%;
}

.button {
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;

  @media (--viewport-lg) {
    width: auto;
  }
}

.mobile {
  display: block;
  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;
  @media (--viewport-lg) {
    display: block;
  }
}
