@media (--viewport-xs) {
  .xs-top-padding-none {
    padding-top: 0;
  }
  .xs-top-padding-xxs {
    padding-top: var(--tfd-spacing-xxs);
  }
  .xs-top-padding-xs {
    padding-top: var(--tfd-spacing-xs);
  }
  .xs-top-padding-sm {
    padding-top: var(--tfd-spacing-sm);
  }
  .xs-top-padding-md {
    padding-top: var(--tfd-spacing-md);
  }
  .xs-top-padding-lg {
    padding-top: var(--tfd-spacing-lg);
  }
  .xs-top-padding-xl {
    padding-top: var(--tfd-spacing-xl);
  }
  .xs-top-padding-xxl {
    padding-top: var(--tfd-spacing-xxl);
  }
  .xs-top-padding-3xl {
    padding-top: var(--tfd-spacing-3xl);
  }
  .xs-top-padding-4xl {
    padding-top: var(--tfd-spacing-4xl);
  }

  .xs-bottom-padding-none {
    padding-bottom: 0;
  }
  .xs-bottom-padding-xxs {
    padding-bottom: var(--tfd-spacing-xxs);
  }
  .xs-bottom-padding-xs {
    padding-bottom: var(--tfd-spacing-xs);
  }
  .xs-bottom-padding-sm {
    padding-bottom: var(--tfd-spacing-sm);
  }
  .xs-bottom-padding-md {
    padding-bottom: var(--tfd-spacing-md);
  }
  .xs-bottom-padding-lg {
    padding-bottom: var(--tfd-spacing-lg);
  }
  .xs-bottom-padding-xl {
    padding-bottom: var(--tfd-spacing-xl);
  }
  .xs-bottom-padding-xxl {
    padding-bottom: var(--tfd-spacing-xxl);
  }
  .xs-bottom-padding-3xl {
    padding-bottom: var(--tfd-spacing-3xl);
  }
  .xs-bottom-padding-4xl {
    padding-bottom: var(--tfd-spacing-4xl);
  }

  .xs-left-padding-none {
    padding-left: 0;
  }
  .xs-left-padding-xxs {
    padding-left: var(--tfd-spacing-xxs);
  }
  .xs-left-padding-xs {
    padding-left: var(--tfd-spacing-xs);
  }
  .xs-left-padding-sm {
    padding-left: var(--tfd-spacing-sm);
  }
  .xs-left-padding-md {
    padding-left: var(--tfd-spacing-md);
  }
  .xs-left-padding-lg {
    padding-left: var(--tfd-spacing-lg);
  }
  .xs-left-padding-xl {
    padding-left: var(--tfd-spacing-xl);
  }
  .xs-left-padding-xxl {
    padding-left: var(--tfd-spacing-xxl);
  }
  .xs-left-padding-3xl {
    padding-left: var(--tfd-spacing-3xl);
  }
  .xs-left-padding-4xl {
    padding-left: var(--tfd-spacing-4xl);
  }

  .xs-right-padding-none {
    padding-right: 0;
  }
  .xs-right-padding-xxs {
    padding-right: var(--tfd-spacing-xxs);
  }
  .xs-right-padding-xs {
    padding-right: var(--tfd-spacing-xs);
  }
  .xs-right-padding-sm {
    padding-right: var(--tfd-spacing-sm);
  }
  .xs-right-padding-md {
    padding-right: var(--tfd-spacing-md);
  }
  .xs-right-padding-lg {
    padding-right: var(--tfd-spacing-lg);
  }
  .xs-right-padding-xl {
    padding-right: var(--tfd-spacing-xl);
  }
  .xs-right-padding-xxl {
    padding-right: var(--tfd-spacing-xxl);
  }
  .xs-right-padding-3xl {
    padding-right: var(--tfd-spacing-3xl);
  }
  .xs-right-padding-4xl {
    padding-right: var(--tfd-spacing-4xl);
  }
}

@media (--viewport-sm) {
  .sm-top-padding-none {
    padding-top: 0;
  }
  .sm-top-padding-xxs {
    padding-top: var(--tfd-spacing-xxs);
  }
  .sm-top-padding-xs {
    padding-top: var(--tfd-spacing-xs);
  }
  .sm-top-padding-sm {
    padding-top: var(--tfd-spacing-sm);
  }
  .sm-top-padding-md {
    padding-top: var(--tfd-spacing-md);
  }
  .sm-top-padding-lg {
    padding-top: var(--tfd-spacing-lg);
  }
  .sm-top-padding-xl {
    padding-top: var(--tfd-spacing-xl);
  }
  .sm-top-padding-xxl {
    padding-top: var(--tfd-spacing-xxl);
  }
  .sm-top-padding-3xl {
    padding-top: var(--tfd-spacing-3xl);
  }
  .sm-top-padding-4xl {
    padding-top: var(--tfd-spacing-4xl);
  }

  .sm-bottom-padding-none {
    padding-bottom: 0;
  }
  .sm-bottom-padding-xxs {
    padding-bottom: var(--tfd-spacing-xxs);
  }
  .sm-bottom-padding-xs {
    padding-bottom: var(--tfd-spacing-xs);
  }
  .sm-bottom-padding-sm {
    padding-bottom: var(--tfd-spacing-sm);
  }
  .sm-bottom-padding-md {
    padding-bottom: var(--tfd-spacing-md);
  }
  .sm-bottom-padding-lg {
    padding-bottom: var(--tfd-spacing-lg);
  }
  .sm-bottom-padding-xl {
    padding-bottom: var(--tfd-spacing-xl);
  }
  .sm-bottom-padding-xxl {
    padding-bottom: var(--tfd-spacing-xxl);
  }
  .sm-bottom-padding-3xl {
    padding-bottom: var(--tfd-spacing-3xl);
  }
  .sm-bottom-padding-4xl {
    padding-bottom: var(--tfd-spacing-4xl);
  }

  .sm-right-padding-none {
    padding-right: 0;
  }
  .sm-right-padding-xxs {
    padding-right: var(--tfd-spacing-xxs);
  }
  .sm-right-padding-xs {
    padding-right: var(--tfd-spacing-xs);
  }
  .sm-right-padding-sm {
    padding-right: var(--tfd-spacing-sm);
  }
  .sm-right-padding-md {
    padding-right: var(--tfd-spacing-md);
  }
  .sm-right-padding-lg {
    padding-right: var(--tfd-spacing-lg);
  }
  .sm-right-padding-xl {
    padding-right: var(--tfd-spacing-xl);
  }
  .sm-right-padding-xxl {
    padding-right: var(--tfd-spacing-xxl);
  }
  .sm-right-padding-3xl {
    padding-right: var(--tfd-spacing-3xl);
  }
  .sm-right-padding-4xl {
    padding-right: var(--tfd-spacing-4xl);
  }

  .sm-left-padding-none {
    padding-left: 0;
  }
  .sm-left-padding-xxs {
    padding-left: var(--tfd-spacing-xxs);
  }
  .sm-left-padding-xs {
    padding-left: var(--tfd-spacing-xs);
  }
  .sm-left-padding-sm {
    padding-left: var(--tfd-spacing-sm);
  }
  .sm-left-padding-md {
    padding-left: var(--tfd-spacing-md);
  }
  .sm-left-padding-lg {
    padding-left: var(--tfd-spacing-lg);
  }
  .sm-left-padding-xl {
    padding-left: var(--tfd-spacing-xl);
  }
  .sm-left-padding-xxl {
    padding-left: var(--tfd-spacing-xxl);
  }
  .sm-left-padding-3xl {
    padding-left: var(--tfd-spacing-3xl);
  }
  .sm-left-padding-4xl {
    padding-left: var(--tfd-spacing-4xl);
  }
}

@media (--viewport-md) {
  .md-top-padding-none {
    padding-top: 0;
  }
  .md-top-padding-xxs {
    padding-top: var(--tfd-spacing-xxs);
  }
  .md-top-padding-xs {
    padding-top: var(--tfd-spacing-xs);
  }
  .md-top-padding-sm {
    padding-top: var(--tfd-spacing-sm);
  }
  .md-top-padding-md {
    padding-top: var(--tfd-spacing-md);
  }
  .md-top-padding-lg {
    padding-top: var(--tfd-spacing-lg);
  }
  .md-top-padding-xl {
    padding-top: var(--tfd-spacing-xl);
  }
  .md-top-padding-xxl {
    padding-top: var(--tfd-spacing-xxl);
  }
  .md-top-padding-3xl {
    padding-top: var(--tfd-spacing-3xl);
  }
  .md-top-padding-4xl {
    padding-top: var(--tfd-spacing-4xl);
  }

  .md-bottom-padding-none {
    padding-bottom: 0;
  }
  .md-bottom-padding-xxs {
    padding-bottom: var(--tfd-spacing-xxs);
  }
  .md-bottom-padding-xs {
    padding-bottom: var(--tfd-spacing-xs);
  }
  .md-bottom-padding-sm {
    padding-bottom: var(--tfd-spacing-sm);
  }
  .md-bottom-padding-md {
    padding-bottom: var(--tfd-spacing-md);
  }
  .md-bottom-padding-lg {
    padding-bottom: var(--tfd-spacing-lg);
  }
  .md-bottom-padding-xl {
    padding-bottom: var(--tfd-spacing-xl);
  }
  .md-bottom-padding-xxl {
    padding-bottom: var(--tfd-spacing-xxl);
  }
  .md-bottom-padding-3xl {
    padding-bottom: var(--tfd-spacing-3xl);
  }
  .md-bottom-padding-4xl {
    padding-bottom: var(--tfd-spacing-4xl);
  }

  .md-right-padding-none {
    padding-right: 0;
  }
  .md-right-padding-xxs {
    padding-right: var(--tfd-spacing-xxs);
  }
  .md-right-padding-xs {
    padding-right: var(--tfd-spacing-xs);
  }
  .md-right-padding-sm {
    padding-right: var(--tfd-spacing-sm);
  }
  .md-right-padding-md {
    padding-right: var(--tfd-spacing-md);
  }
  .md-right-padding-lg {
    padding-right: var(--tfd-spacing-lg);
  }
  .md-right-padding-xl {
    padding-right: var(--tfd-spacing-xl);
  }
  .md-right-padding-xxl {
    padding-right: var(--tfd-spacing-xxl);
  }
  .md-right-padding-3xl {
    padding-right: var(--tfd-spacing-3xl);
  }
  .md-right-padding-4xl {
    padding-right: var(--tfd-spacing-4xl);
  }

  .md-left-padding-none {
    padding-left: 0;
  }
  .md-left-padding-xxs {
    padding-left: var(--tfd-spacing-xxs);
  }
  .md-left-padding-xs {
    padding-left: var(--tfd-spacing-xs);
  }
  .md-left-padding-sm {
    padding-left: var(--tfd-spacing-sm);
  }
  .md-left-padding-md {
    padding-left: var(--tfd-spacing-md);
  }
  .md-left-padding-lg {
    padding-left: var(--tfd-spacing-lg);
  }
  .md-left-padding-xl {
    padding-left: var(--tfd-spacing-xl);
  }
  .md-left-padding-xxl {
    padding-left: var(--tfd-spacing-xxl);
  }
  .md-left-padding-3xl {
    padding-left: var(--tfd-spacing-3xl);
  }
  .md-left-padding-4xl {
    padding-left: var(--tfd-spacing-4xl);
  }
}

@media (--viewport-lg) {
  .lg-top-padding-none {
    padding-top: 0;
  }
  .lg-top-padding-xxs {
    padding-top: var(--tfd-spacing-xxs);
  }
  .lg-top-padding-xs {
    padding-top: var(--tfd-spacing-xs);
  }
  .lg-top-padding-sm {
    padding-top: var(--tfd-spacing-sm);
  }
  .lg-top-padding-md {
    padding-top: var(--tfd-spacing-md);
  }
  .lg-top-padding-lg {
    padding-top: var(--tfd-spacing-lg);
  }
  .lg-top-padding-xl {
    padding-top: var(--tfd-spacing-xl);
  }
  .lg-top-padding-xxl {
    padding-top: var(--tfd-spacing-xxl);
  }
  .lg-top-padding-3xl {
    padding-top: var(--tfd-spacing-3xl);
  }
  .lg-top-padding-4xl {
    padding-top: var(--tfd-spacing-4xl);
  }

  .lg-bottom-padding-none {
    padding-bottom: 0;
  }
  .lg-bottom-padding-xxs {
    padding-bottom: var(--tfd-spacing-xxs);
  }
  .lg-bottom-padding-xs {
    padding-bottom: var(--tfd-spacing-xs);
  }
  .lg-bottom-padding-sm {
    padding-bottom: var(--tfd-spacing-sm);
  }
  .lg-bottom-padding-md {
    padding-bottom: var(--tfd-spacing-md);
  }
  .lg-bottom-padding-lg {
    padding-bottom: var(--tfd-spacing-lg);
  }
  .lg-bottom-padding-xl {
    padding-bottom: var(--tfd-spacing-xl);
  }
  .lg-bottom-padding-xxl {
    padding-bottom: var(--tfd-spacing-xxl);
  }
  .lg-bottom-padding-3xl {
    padding-bottom: var(--tfd-spacing-3xl);
  }
  .lg-bottom-padding-4xl {
    padding-bottom: var(--tfd-spacing-4xl);
  }

  .lg-right-padding-none {
    padding-right: 0;
  }
  .lg-right-padding-xxs {
    padding-right: var(--tfd-spacing-xxs);
  }
  .lg-right-padding-xs {
    padding-right: var(--tfd-spacing-xs);
  }
  .lg-right-padding-sm {
    padding-right: var(--tfd-spacing-sm);
  }
  .lg-right-padding-md {
    padding-right: var(--tfd-spacing-md);
  }
  .lg-right-padding-lg {
    padding-right: var(--tfd-spacing-lg);
  }
  .lg-right-padding-xl {
    padding-right: var(--tfd-spacing-xl);
  }
  .lg-right-padding-xxl {
    padding-right: var(--tfd-spacing-xxl);
  }
  .lg-right-padding-3xl {
    padding-right: var(--tfd-spacing-3xl);
  }
  .lg-right-padding-4xl {
    padding-right: var(--tfd-spacing-4xl);
  }

  .lg-left-padding-none {
    padding-left: 0;
  }
  .lg-left-padding-xxs {
    padding-left: var(--tfd-spacing-xxs);
  }
  .lg-left-padding-xs {
    padding-left: var(--tfd-spacing-xs);
  }
  .lg-left-padding-sm {
    padding-left: var(--tfd-spacing-sm);
  }
  .lg-left-padding-md {
    padding-left: var(--tfd-spacing-md);
  }
  .lg-left-padding-lg {
    padding-left: var(--tfd-spacing-lg);
  }
  .lg-left-padding-xl {
    padding-left: var(--tfd-spacing-xl);
  }
  .lg-left-padding-xxl {
    padding-left: var(--tfd-spacing-xxl);
  }
  .lg-left-padding-3xl {
    padding-left: var(--tfd-spacing-3xl);
  }
  .lg-left-padding-4xl {
    padding-left: var(--tfd-spacing-4xl);
  }
}
@media (--viewport-xl) {
  .xl-top-padding-none {
    padding-top: 0;
  }
  .xl-top-padding-xxs {
    padding-top: var(--tfd-spacing-xxs);
  }
  .xl-top-padding-xs {
    padding-top: var(--tfd-spacing-xs);
  }
  .xl-top-padding-sm {
    padding-top: var(--tfd-spacing-sm);
  }
  .xl-top-padding-md {
    padding-top: var(--tfd-spacing-md);
  }
  .xl-top-padding-lg {
    padding-top: var(--tfd-spacing-lg);
  }
  .xl-top-padding-xl {
    padding-top: var(--tfd-spacing-xl);
  }
  .xl-top-padding-xxl {
    padding-top: var(--tfd-spacing-xxl);
  }
  .xl-top-padding-3xl {
    padding-top: var(--tfd-spacing-3xl);
  }
  .xl-top-padding-4xl {
    padding-top: var(--tfd-spacing-4xl);
  }

  .xl-bottom-padding-none {
    padding-bottom: 0;
  }
  .xl-bottom-padding-xxs {
    padding-bottom: var(--tfd-spacing-xxs);
  }
  .xl-bottom-padding-xs {
    padding-bottom: var(--tfd-spacing-xs);
  }
  .xl-bottom-padding-sm {
    padding-bottom: var(--tfd-spacing-sm);
  }
  .xl-bottom-padding-md {
    padding-bottom: var(--tfd-spacing-md);
  }
  .xl-bottom-padding-lg {
    padding-bottom: var(--tfd-spacing-lg);
  }
  .xl-bottom-padding-xl {
    padding-bottom: var(--tfd-spacing-xl);
  }
  .xl-bottom-padding-xxl {
    padding-bottom: var(--tfd-spacing-xxl);
  }
  .xl-bottom-padding-3xl {
    padding-bottom: var(--tfd-spacing-3xl);
  }
  .xl-bottom-padding-4xl {
    padding-bottom: var(--tfd-spacing-4xl);
  }

  .xl-right-padding-none {
    padding-right: 0;
  }
  .xl-right-padding-xxs {
    padding-right: var(--tfd-spacing-xxs);
  }
  .xl-right-padding-xs {
    padding-right: var(--tfd-spacing-xs);
  }
  .xl-right-padding-sm {
    padding-right: var(--tfd-spacing-sm);
  }
  .xl-right-padding-md {
    padding-right: var(--tfd-spacing-md);
  }
  .xl-right-padding-lg {
    padding-right: var(--tfd-spacing-lg);
  }
  .xl-right-padding-xl {
    padding-right: var(--tfd-spacing-xl);
  }
  .xl-right-padding-xxl {
    padding-right: var(--tfd-spacing-xxl);
  }
  .xl-right-padding-3xl {
    padding-right: var(--tfd-spacing-3xl);
  }
  .xl-right-padding-4xl {
    padding-right: var(--tfd-spacing-4xl);
  }

  .xl-left-padding-none {
    padding-left: 0;
  }
  .xl-left-padding-xxs {
    padding-left: var(--tfd-spacing-xxs);
  }
  .xl-left-padding-xs {
    padding-left: var(--tfd-spacing-xs);
  }
  .xl-left-padding-sm {
    padding-left: var(--tfd-spacing-sm);
  }
  .xl-left-padding-md {
    padding-left: var(--tfd-spacing-md);
  }
  .xl-left-padding-lg {
    padding-left: var(--tfd-spacing-lg);
  }
  .xl-left-padding-xl {
    padding-left: var(--tfd-spacing-xl);
  }
  .xl-left-padding-xxl {
    padding-left: var(--tfd-spacing-xxl);
  }
  .xl-left-padding-3xl {
    padding-left: var(--tfd-spacing-3xl);
  }
  .xl-left-padding-4xl {
    padding-left: var(--tfd-spacing-4xl);
  }
}
