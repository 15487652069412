.hideMenuButton button {
  visibility: hidden;
}

.navContainer {
  position: relative;
  z-index: calc(var(--tfd-toast-z-index) - 1);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
}

.nav
  div[role='presentation']:last-child
  span[role='listitem']:nth-last-child(2) {
  @media (--viewport-md) and (max-width: 1264px) {
    display: none;
  }
}

.logoContainer {
  transform: translateY(-12px);
  min-width: 150px;
}

.logo {
  max-width: 50px;
}

.title {
  text-transform: uppercase;
}

.discount-request-link {
  @media (--viewport-md) {
    display: none !important;
  }
}

/* custom styling that should be added to corgi eventually */
.customerLoginLink {
  background-color: var(--blueberry-3) !important;

  @media (--viewport-lg) {
    margin: 0 calc(var(--tfd-spacing-lg) / 2) !important;
  }
}

.customerLoginLink:hover {
  background-color: var(--kale-2) !important;
}
