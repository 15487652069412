.container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 135px;
  max-width: 733px;
  margin: auto;
}

.pageWidth {
  display: flex;
  justify-content: center;
}

.bowlImage {
  width: 100%;
  height: 100%;
}
