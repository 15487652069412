.step {
  margin: 0;
  padding: var(--margin-md);
  text-align: center;
  box-sizing: border-box;
  width: 100%;

  @media (--viewport-lg) {
    padding: var(--margin-lg) var(--margin-md);
    width: 248px;
  }
}

.step div h4 {
  font-size: 28px;
  margin: 0px;
  color: var(--blueberry-3);

  @media (--viewport-lg) {
    font-size: 40px;
  }
}

.step p {
  margin: 0;
}
