.navItem {
  display: inline-block;
  white-space: nowrap;
}

.navItem + .navItem {
  margin-left: var(--tfd-spacing-md);
}

.navItem.button {
  color: var(--kale-3);
}
.navItem.link {
  font-weight: bold;
  font-family: var(--tfd-font-family);
}

[data-overlay-mode='dark'] .navItem.link {
  color: var(--white);
}
