.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

@media (--viewport-xs) {
  .xs-left {
    text-align: left;
  }
  .xs-center {
    text-align: center;
  }
  .xs-right {
    text-align: right;
  }
}
@media (--viewport-sm) {
  .sm-left {
    text-align: left;
  }
  .sm-center {
    text-align: center;
  }
  .sm-right {
    text-align: right;
  }
}
@media (--viewport-md) {
  .md-left {
    text-align: left;
  }
  .md-center {
    text-align: center;
  }
  .md-right {
    text-align: right;
  }
}
@media (--viewport-lg) {
  .lg-left {
    text-align: left;
  }
  .lg-center {
    text-align: center;
  }
  .lg-right {
    text-align: right;
  }
}
@media (--viewport-xl) {
  .xl-left {
    text-align: left;
  }
  .xl-center {
    text-align: center;
  }
  .xl-right {
    text-align: right;
  }
}
