.loading-modal {
  --modal-width: 100%;
}

@media (--viewport-md) {
  .loading-modal {
    --modal-width: 630px;
  }
}

@media (--viewport-lg) {
  .loading-modal {
    --modal-width: 716px;
  }
}

.loading-modal {
  width: calc(var(--modal-width) - var(--tfd-spacing-sm));
  padding: var(--tfd-spacing-xl);
  border-radius: var(--tfd-border-radius-sm);
  display: flex;
  flex-direction: column;
  align-items: center;
}
