.container {
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
}

.bar {
  width: var(--margin-md);
  height: 2px;
  border-radius: 1px;
  background-color: var(--charcoal-3);
  position: relative;

  transition: all ease-in-out 200ms;
  transition-property: opacity, transform, background-color;
}

.bar.darkTheme {
  background-color: var(--white);
}

.bar {
  margin: var(--margin-xs) 0;
}

.x .bar:nth-child(1) {
  transform: translateY(calc(var(--margin-xs) + 2px)) rotate(135deg);
}

.x .bar:nth-child(2) {
  opacity: 0;
}

.x .bar:nth-child(3) {
  transform: translateY(calc(-1 * var(--margin-xs) - 2px)) rotate(-135deg);
}
