.Blueberry0 {
  color: var(--blueberry-0);
}
.Blueberry1 {
  color: var(--blueberry-1);
}

.blueberry-2,
.Blueberry2 {
  color: var(--blueberry-2);
}

.blueberry-3,
.Blueberry3 {
  color: var(--blueberry-3);
}

.Carrot0 {
  color: var(--carrot-0);
}

.Carrot1 {
  color: var(--carrot-1);
}

.carrot-2,
.Carrot2 {
  color: var(--carrot-2);
}

.carrot-3,
.Carrot3 {
  color: var(--carrot-3);
}

.Charcoal0 {
  color: var(--charcoal-0);
}

.charcoal-1,
.Charcoal1 {
  color: var(--charcoal-1);
}

.charcoal-2,
.Charcoal2 {
  color: var(--charcoal-2);
}

.charcoal-3,
.Charcoal3 {
  color: var(--charcoal-3);
}

.Chickpea0 {
  color: var(--chickpea-0);
}

.Chickpea1 {
  color: var(--chickpea-1);
}

.Chickpea2 {
  color: var(--chickpea-2);
}

.Chickpea3 {
  color: var(--chickpea-3);
}

.Kale0 {
  color: var(--kale-0);
}

.Kale1 {
  color: var(--kale-1);
}

.kale-2,
.Kale2 {
  color: var(--kale-2);
}

.kale-3,
.Kale3 {
  color: var(--kale-3);
}

.Oyster0 {
  color: var(--oyster-0);
}

.Oyster1 {
  color: var(--oyster-1);
}

.Oyster2 {
  color: var(--oyster-2);
}

.Oyster3 {
  color: var(--oyster-3);
}

.white,
.White {
  color: var(--white);
}

.error-red,
.ErrorRed {
  color: var(--error-red);
}
