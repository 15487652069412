.link {
  cursor: pointer;
  display: block;
  padding: 12px 0;
  border-bottom: 1px solid var(--oyster-1);
}

.list {
  margin: 0;
  padding: 0;
  margin-bottom: var(--margin-lg);
  max-width: 282px;
  list-style: none;
}
