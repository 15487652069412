.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--tfd-spacing-md);
  margin: 0 var(--margin-lg);
  padding-top: var(--tfd-spacing-lg);

  @media (--viewport-md) {
    width: 588px;
    margin: 0 auto;
  }
}

.container h1 {
  text-align: center;
}

.container .email {
  text-decoration: underline;
}

.container form {
  display: flex;
  flex-direction: column;
  gap: var(--tfd-spacing-md);
}

.container .formContent {

  column-gap: var(--tfd-spacing-md);

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.container .formContent>div {
  width: 100%;
  max-width: inherit;

}

.container .formContent section {
  grid-column: auto/span 2;
  margin-top: var(--margin-md);
  padding: var(--tfd-spacing-md);
  background: var(--oyster-0);
  border-radius: 12px;
}

.container .formContent section h5 {
  margin: 0;
}

.container form button {
  display: block;
  width: 100%;

  @media (--viewport-md) {
    margin: 0 auto;
    width: auto;
  }
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.button {
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
}

