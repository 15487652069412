.container {
  display: block;
  padding: var(--margin-lg);
}

.container + .container {
  margin-top: var(--margin-lg);
}

@media (--viewport-lg) {
  .container {
    padding: var(--margin-xl);
  }
}
