.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: var(--tfd-border-radius-sm);
}

.input:focus-visible + .container {
  outline: none;
  /* Replace with var(--tfd-outline-box-shadow)
   when https://github.com/farmersdog/beethoven/pull/5355/files is merged */
  box-shadow: 0 0 0 2px var(--charcoal-0);
}

.label {
  color: inherit;
  line-height: 1.3em;
  text-align: center;
}

.description {
  text-align: center;
  margin-top: var(--tfd-spacing-xs);
}

.description > * {
  opacity: 0;
  color: var(--carrot-2);
}

.container:hover .description > *,
.input:checked + .container .description > *,
.input:focus + .container .description > * {
  opacity: 1;
}

.picture {
  display: block;
  height: 144px;
  width: auto;
  margin-top: var(--tfd-spacing-lg);
}

.picture.large {
  display: none;
}

@media (--viewport-lg) {
  .picture {
    height: 144px;
  }
  .picture.small {
    display: none;
  }

  .picture.large {
    display: block;
  }
}
