.root {
  box-sizing: border-box;
  text-align: center;
  color: var(--white);
  font-family: var(--tfd-font-family);
  font-size: 16px;
  position: relative;
  padding: var(--margin-sm) var(--margin-md);
  min-height: var(--tfd-banner-min-height);
}

.root a {
  color: inherit;
}

.default {
  background: var(--kale-3);
}

.kale-2 {
  background: var(--kale-2);
}

.oyster-0 {
  background: var(--oyster-0);
}

.white {
  background: var(--white);
}

.chickpea-0 {
  background: var(--chickpea-0);
}
