.headingsContainer {
  display: flex;
  align-items: center;
}

.headingsContainer:hover {
  cursor: pointer;
}

.svgContainerExtraSmall,
.svgContainerExtraSmall svg {
  width: 36px;
  height: 36px;
}

.svgContainerExtraSmall {
  display: inline-flex;
}

.svgContainerExtraSmall svg {
  width: 36px;
  height: 36px;
}

.text {
  font-size: 16px;
}

@media (--viewport-md) {
  .text {
    font-size: 16px;
  }
}

@media (min-width: 1100px) {
  .text {
    font-size: 18px;
  }
}

@media (min-width: 1150px) and (max-width: 1200px) {
  .text {
    font-size: 20px;
  }
}

@media (min-width: 1201px) {
  .text {
    font-size: 22px;
  }
}
.linkContainer {
  padding: 0;
}

.link {
  list-style: none;
  margin-bottom: var(--tfd-spacing-sm);
}
.link a {
  text-decoration: none;
}
