.drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: auto;
  height: 0; /* Start with zero height */
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(100%); /* Start off-screen */
  transition:
    transform 0.5s ease-in-out,
    height 0.5s ease-in-out;
  z-index: 1000;
  padding: var(--tfd-spacing-md);
  border-radius: 20px 20px 0 0;
}

/* When the modal is open */
.drawer.open {
  transform: translateY(0); /* Slide into view */
  height: auto; /* Expand to show content */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(57, 56, 60, 0.94);
  z-index: 999;
}

.closeIcon {
  position: absolute;
  padding: 0;
  right: 28px;
}

.closeIcon:hover {
  opacity: 0.5;
}
