.section {
  background-color: var(--blueberry-3);
  width: 100%;
  margin: 0 auto;

  @media (--viewport-lg) {
    width: 92%;
  }
}

.reasonsContainer {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 var(--margin-sm);

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
  }

  @media (--viewport-xl) {
    padding: 0 var(--margin-xl);
  }
}

.section>div {
  padding: 0 var(--margin-md);

  @media (--viewport-xl) {
    padding: 0;
  }
}

.title {
  text-align: center;
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
