.section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--margin-lg) 0;
  height: 558px;
  position: relative;
  overflow: hidden;
}

@media (min-height: 737px) {
  .section {
    height: 572px;
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-left: 10px;
  margin-right: 10px;
}

.title {
  margin: 0;
  text-align: center;
  font-size: 40px;
  line-height: 1.33;
  letter-spacing: .5px;
}

.subtitle {
  margin-top: var(--margin-sm);
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 0.5px;
}

a.cta {
  margin-top: var(--margin-md);
}

@media (--viewport-sm) {
  .content {
    margin-left: 30px;
    margin-right: 30px;
    max-width: none;
  }
}

@media (--viewport-md) {
  .content {
    margin-left: auto;
    margin-right: auto;
    max-width: 630px;
  }
}

@media (--viewport-lg) {
  .content {
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
  }

  .title {
    font-size: 52px;
    line-height: 1.33;
  }

  .subtitle {
    font-size: 21px;
    line-height: 1.52;
  }
}
