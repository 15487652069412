.container {
  width: 100%;
  position: relative;
}

.horizontalSnap {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
  overflow-y: hidden;
}

.dots {
  display: flex;
  justify-content: center;
}

.dots.outside {
  margin-top: var(--margin-md);
}

.dots.inside {
  position: absolute;
  bottom: var(--margin-md);
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0;
}

.previous {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(
    calc((var(--arrow-button-size) / -2) - var(--margin-lg)),
    -50%
  );
}

.next {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(
    calc((var(--arrow-button-size) / 2) + var(--margin-lg)),
    -50%
  );
}

.previous.inside {
  transform: translate(
    calc((var(--arrow-button-size) / -2) + var(--margin-xl)),
    -50%
  );
}

.next.inside {
  transform: translate(
    calc((var(--arrow-button-size) / 2) - var(--margin-xl)),
    -50%
  );
}
