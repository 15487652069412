.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 24px;
  width: 100%;
  min-height: 108px;
  border: solid 1px var(--kale-3);
  border-radius: 4px;
  cursor: pointer;
  transition-timing-function: var(--animation-standard-timing-function);
  transition-duration: var(--animation-standard-duration);
  transition-property: border, background-color;
  position: relative;
  margin-bottom: var(--margin-sm);
}

.normal {
  border-color: var(--charcoal-0);
}

.label:hover {
  border-color: var(--kale-3);
}

.label:hover > span {
  border: solid 1px var(--kale-3);
}

.label:hover > .badge {
  background-color: var(--kale-3);
}

.label:hover > .badge.recommended {
  background-color: var(--carrot-2);
}

.label:hover > .checkbox > span {
  border-color: var(--kale-3);
}

.label.disabled:hover > .checkbox > span {
  border-color: var(--charcoal-0);
}

.label:hover > .badge.disabled {
  background-color: var(--charcoal-0);
}

.label.focus {
  box-shadow: 0 0 0 3px rgb(from var(--kale-3) r g b / 25%);
}

.label.disabled {
  border-color: var(--oyster-0);
}

.label.disabled > span {
  border-color: var(--oyster-0);
}

.label.checked {
  border-color: var(--kale-3);
}

.checkbox {
  margin-left: var(--margin-sm);
}

.badge {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  padding: var(--tfd-listselect-badge-padding);
  background-color: var(--charcoal-3);
  color: var(--white);
  left: var(--margin-md);
  border-radius: 2px;
  transition-timing-function: var(--animation-standard-timing-function);
  transition-duration: var(--animation-standard-duration);
  transition-property: background-color;
}

.badge.checked {
  background-color: var(--kale-3);
  color: var(--white);
}

.badge.recommended {
  background-color: var(--carrot-2);
  color: var(--white);
}

.badge.disabled {
  background-color: var(--charcoal-0);
  color: var(--charcoal-3);
}

.badge .badge-text {
  vertical-align: middle;
}

.input {
  width: 100%;
}
