.blueberry-2 {
  color: var(--blueberry-2);
}

.carrot-2 {
  color: var(--carrot-2);
}

.carrot-3 {
  color: var(--carrot-3);
}

.charcoal-1 {
  color: var(--charcoal-1);
}

.charcoal-2 {
  color: var(--charcoal-2);
}

.charcoal-3 {
  color: var(--charcoal-3);
}

.kale-2 {
  color: var(--kale-2);
}

.white {
  color: var(--white);
}
