:root {
  --radio-input-size: 32px;
}

.radio {
  position: relative;
  display: block;
  height: var(--radio-input-size);
  width: var(--radio-input-size);
  background-color: var(--white);
  border: 1px solid var(--charcoal-0);
  border-radius: 50%;
  transition-timing-function: var(--animation-standard-timing-function);
  transition-duration: var(--animation-standard-duration);
  transition-property: border-color, background-color;
}

.radio:after {
  content: '';
  opacity: 0;

  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;

  border: 1px solid var(--white);
  border-radius: 50%;

  transition-timing-function: var(--animation-standard-timing-function);
  transition-duration: var(--animation-standard-duration);
  transition-property: opacity;
}

input:checked ~ .radio,
.checked {
  background-color: var(--kale-3);
  border-color: var(--kale-3);
}

input:checked ~ .radio:after,
.checked:after {
  opacity: 1;
}

input:checked ~ .radio.disabled {
  background-color: var(--charcoal-0);
  border-color: var(--charcoal-0);
}

.focus {
  border-color: var(--kale-3);
  box-shadow: 0 0 0 2px var(--kale-3);
}

.checked.focus {
  box-shadow: inset 0 0 0 2px var(--white), 0 0 0 2px var(--kale-3);
}
