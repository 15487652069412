.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.label {
  transition-timing-function: var(--animation-standard-timing-function);
  transition-duration: var(--animation-standard-duration);
  transition-property: opacity;

  text-align: center;
  opacity: 0.5;
}

input:checked ~ * .label {
  opacity: 1;
}

.icon {
  transition-timing-function: var(--animation-standard-timing-function);
  transition-duration: var(--animation-standard-duration);
  transition-property: opacity;

  opacity: 0.5;
}

input:checked ~ * .icon {
  opacity: 1;
}

.input {
  width: 13px;
  height: 13px;
}

.largeInput {
  width: 36px;
  height: 36px;
}
