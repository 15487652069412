.container {
  padding: var(--margin-md);
  background-color: var(--kale-0);
}

.grid {
  margin: 0 auto;
  max-width: 750px;
  text-align: center;
  @media (--viewport-lg) {
    text-align: left;
  }
}

.button {
  width: 100%;
  max-width: 327px;
  box-sizing: border-box;
  @media (--viewport-lg) {
    width: auto;
  }
}
