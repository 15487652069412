@media (--viewport-xs) {
  .xs-top-margin-none {
    margin-top: 0;
  }
  .xs-top-margin-xxs {
    margin-top: var(--tfd-spacing-xxs);
  }
  .xs-top-margin-xs {
    margin-top: var(--tfd-spacing-xs);
  }
  .xs-top-margin-sm {
    margin-top: var(--tfd-spacing-sm);
  }
  .xs-top-margin-md {
    margin-top: var(--tfd-spacing-md);
  }
  .xs-top-margin-lg {
    margin-top: var(--tfd-spacing-lg);
  }
  .xs-top-margin-xl {
    margin-top: var(--tfd-spacing-xl);
  }
  .xs-top-margin-xxl {
    margin-top: var(--tfd-spacing-xxl);
  }
  .xs-top-margin-3xl {
    margin-top: var(--tfd-spacing-3xl);
  }
  .xs-top-margin-4xl {
    margin-top: var(--tfd-spacing-4xl);
  }

  .xs-bottom-margin-none {
    margin-bottom: 0;
  }
  .xs-bottom-margin-xxs {
    margin-bottom: var(--tfd-spacing-xxs);
  }
  .xs-bottom-margin-xs {
    margin-bottom: var(--tfd-spacing-xs);
  }
  .xs-bottom-margin-sm {
    margin-bottom: var(--tfd-spacing-sm);
  }
  .xs-bottom-margin-md {
    margin-bottom: var(--tfd-spacing-md);
  }
  .xs-bottom-margin-lg {
    margin-bottom: var(--tfd-spacing-lg);
  }
  .xs-bottom-margin-xl {
    margin-bottom: var(--tfd-spacing-xl);
  }
  .xs-bottom-margin-xxl {
    margin-bottom: var(--tfd-spacing-xxl);
  }
  .xs-bottom-margin-3xl {
    margin-bottom: var(--tfd-spacing-3xl);
  }
  .xs-bottom-margin-4xl {
    margin-bottom: var(--tfd-spacing-4xl);
  }

  .xs-left-margin-none {
    margin-left: 0;
  }
  .xs-left-margin-xxs {
    margin-left: var(--tfd-spacing-xxs);
  }
  .xs-left-margin-xs {
    margin-left: var(--tfd-spacing-xs);
  }
  .xs-left-margin-sm {
    margin-left: var(--tfd-spacing-sm);
  }
  .xs-left-margin-md {
    margin-left: var(--tfd-spacing-md);
  }
  .xs-left-margin-lg {
    margin-left: var(--tfd-spacing-lg);
  }
  .xs-left-margin-xl {
    margin-left: var(--tfd-spacing-xl);
  }
  .xs-left-margin-xxl {
    margin-left: var(--tfd-spacing-xxl);
  }
  .xs-left-margin-3xl {
    margin-left: var(--tfd-spacing-3xl);
  }
  .xs-left-margin-4xl {
    margin-left: var(--tfd-spacing-4xl);
  }

  .xs-right-margin-none {
    margin-right: 0;
  }
  .xs-right-margin-xxs {
    margin-right: var(--tfd-spacing-xxs);
  }
  .xs-right-margin-xs {
    margin-right: var(--tfd-spacing-xs);
  }
  .xs-right-margin-sm {
    margin-right: var(--tfd-spacing-sm);
  }
  .xs-right-margin-md {
    margin-right: var(--tfd-spacing-md);
  }
  .xs-right-margin-lg {
    margin-right: var(--tfd-spacing-lg);
  }
  .xs-right-margin-xl {
    margin-right: var(--tfd-spacing-xl);
  }
  .xs-right-margin-xxl {
    margin-right: var(--tfd-spacing-xxl);
  }
  .xs-right-margin-3xl {
    margin-right: var(--tfd-spacing-3xl);
  }
  .xs-right-margin-4xl {
    margin-right: var(--tfd-spacing-4xl);
  }
}

@media (--viewport-sm) {
  .sm-top-margin-none {
    margin-top: 0;
  }
  .sm-top-margin-xxs {
    margin-top: var(--tfd-spacing-xxs);
  }
  .sm-top-margin-xs {
    margin-top: var(--tfd-spacing-xs);
  }
  .sm-top-margin-sm {
    margin-top: var(--tfd-spacing-sm);
  }
  .sm-top-margin-md {
    margin-top: var(--tfd-spacing-md);
  }
  .sm-top-margin-lg {
    margin-top: var(--tfd-spacing-lg);
  }
  .sm-top-margin-xl {
    margin-top: var(--tfd-spacing-xl);
  }
  .sm-top-margin-xxl {
    margin-top: var(--tfd-spacing-xxl);
  }
  .sm-top-margin-3xl {
    margin-top: var(--tfd-spacing-3xl);
  }
  .sm-top-margin-4xl {
    margin-top: var(--tfd-spacing-4xl);
  }

  .sm-bottom-margin-none {
    margin-bottom: 0;
  }
  .sm-bottom-margin-xxs {
    margin-bottom: var(--tfd-spacing-xxs);
  }
  .sm-bottom-margin-xs {
    margin-bottom: var(--tfd-spacing-xs);
  }
  .sm-bottom-margin-sm {
    margin-bottom: var(--tfd-spacing-sm);
  }
  .sm-bottom-margin-md {
    margin-bottom: var(--tfd-spacing-md);
  }
  .sm-bottom-margin-lg {
    margin-bottom: var(--tfd-spacing-lg);
  }
  .sm-bottom-margin-xl {
    margin-bottom: var(--tfd-spacing-xl);
  }
  .sm-bottom-margin-xxl {
    margin-bottom: var(--tfd-spacing-xxl);
  }
  .sm-bottom-margin-3xl {
    margin-bottom: var(--tfd-spacing-3xl);
  }
  .sm-bottom-margin-4xl {
    margin-bottom: var(--tfd-spacing-4xl);
  }

  .sm-right-margin-none {
    margin-right: 0;
  }
  .sm-right-margin-xxs {
    margin-right: var(--tfd-spacing-xxs);
  }
  .sm-right-margin-xs {
    margin-right: var(--tfd-spacing-xs);
  }
  .sm-right-margin-sm {
    margin-right: var(--tfd-spacing-sm);
  }
  .sm-right-margin-md {
    margin-right: var(--tfd-spacing-md);
  }
  .sm-right-margin-lg {
    margin-right: var(--tfd-spacing-lg);
  }
  .sm-right-margin-xl {
    margin-right: var(--tfd-spacing-xl);
  }
  .sm-right-margin-xxl {
    margin-right: var(--tfd-spacing-xxl);
  }
  .sm-right-margin-3xl {
    margin-right: var(--tfd-spacing-3xl);
  }
  .sm-right-margin-4xl {
    margin-right: var(--tfd-spacing-4xl);
  }

  .sm-left-margin-none {
    margin-left: 0;
  }
  .sm-left-margin-xxs {
    margin-left: var(--tfd-spacing-xxs);
  }
  .sm-left-margin-xs {
    margin-left: var(--tfd-spacing-xs);
  }
  .sm-left-margin-sm {
    margin-left: var(--tfd-spacing-sm);
  }
  .sm-left-margin-md {
    margin-left: var(--tfd-spacing-md);
  }
  .sm-left-margin-lg {
    margin-left: var(--tfd-spacing-lg);
  }
  .sm-left-margin-xl {
    margin-left: var(--tfd-spacing-xl);
  }
  .sm-left-margin-xxl {
    margin-left: var(--tfd-spacing-xxl);
  }
  .sm-left-margin-3xl {
    margin-left: var(--tfd-spacing-3xl);
  }
  .sm-left-margin-4xl {
    margin-left: var(--tfd-spacing-4xl);
  }
}

@media (--viewport-md) {
  .md-top-margin-none {
    margin-top: 0;
  }
  .md-top-margin-xxs {
    margin-top: var(--tfd-spacing-xxs);
  }
  .md-top-margin-xs {
    margin-top: var(--tfd-spacing-xs);
  }
  .md-top-margin-sm {
    margin-top: var(--tfd-spacing-sm);
  }
  .md-top-margin-md {
    margin-top: var(--tfd-spacing-md);
  }
  .md-top-margin-lg {
    margin-top: var(--tfd-spacing-lg);
  }
  .md-top-margin-xl {
    margin-top: var(--tfd-spacing-xl);
  }
  .md-top-margin-xxl {
    margin-top: var(--tfd-spacing-xxl);
  }
  .md-top-margin-3xl {
    margin-top: var(--tfd-spacing-3xl);
  }
  .md-top-margin-4xl {
    margin-top: var(--tfd-spacing-4xl);
  }

  .md-bottom-margin-none {
    margin-bottom: 0;
  }
  .md-bottom-margin-xxs {
    margin-bottom: var(--tfd-spacing-xxs);
  }
  .md-bottom-margin-xs {
    margin-bottom: var(--tfd-spacing-xs);
  }
  .md-bottom-margin-sm {
    margin-bottom: var(--tfd-spacing-sm);
  }
  .md-bottom-margin-md {
    margin-bottom: var(--tfd-spacing-md);
  }
  .md-bottom-margin-lg {
    margin-bottom: var(--tfd-spacing-lg);
  }
  .md-bottom-margin-xl {
    margin-bottom: var(--tfd-spacing-xl);
  }
  .md-bottom-margin-xxl {
    margin-bottom: var(--tfd-spacing-xxl);
  }
  .md-bottom-margin-3xl {
    margin-bottom: var(--tfd-spacing-3xl);
  }
  .md-bottom-margin-4xl {
    margin-bottom: var(--tfd-spacing-4xl);
  }

  .md-right-margin-none {
    margin-right: 0;
  }
  .md-right-margin-xxs {
    margin-right: var(--tfd-spacing-xxs);
  }
  .md-right-margin-xs {
    margin-right: var(--tfd-spacing-xs);
  }
  .md-right-margin-sm {
    margin-right: var(--tfd-spacing-sm);
  }
  .md-right-margin-md {
    margin-right: var(--tfd-spacing-md);
  }
  .md-right-margin-lg {
    margin-right: var(--tfd-spacing-lg);
  }
  .md-right-margin-xl {
    margin-right: var(--tfd-spacing-xl);
  }
  .md-right-margin-xxl {
    margin-right: var(--tfd-spacing-xxl);
  }
  .md-right-margin-3xl {
    margin-right: var(--tfd-spacing-3xl);
  }
  .md-right-margin-4xl {
    margin-right: var(--tfd-spacing-4xl);
  }

  .md-left-margin-none {
    margin-left: 0;
  }
  .md-left-margin-xxs {
    margin-left: var(--tfd-spacing-xxs);
  }
  .md-left-margin-xs {
    margin-left: var(--tfd-spacing-xs);
  }
  .md-left-margin-sm {
    margin-left: var(--tfd-spacing-sm);
  }
  .md-left-margin-md {
    margin-left: var(--tfd-spacing-md);
  }
  .md-left-margin-lg {
    margin-left: var(--tfd-spacing-lg);
  }
  .md-left-margin-xl {
    margin-left: var(--tfd-spacing-xl);
  }
  .md-left-margin-xxl {
    margin-left: var(--tfd-spacing-xxl);
  }
  .md-left-margin-3xl {
    margin-left: var(--tfd-spacing-3xl);
  }
  .md-left-margin-4xl {
    margin-left: var(--tfd-spacing-4xl);
  }
}

@media (--viewport-lg) {
  .lg-top-margin-none {
    margin-top: 0;
  }
  .lg-top-margin-xxs {
    margin-top: var(--tfd-spacing-xxs);
  }
  .lg-top-margin-xs {
    margin-top: var(--tfd-spacing-xs);
  }
  .lg-top-margin-sm {
    margin-top: var(--tfd-spacing-sm);
  }
  .lg-top-margin-md {
    margin-top: var(--tfd-spacing-md);
  }
  .lg-top-margin-lg {
    margin-top: var(--tfd-spacing-lg);
  }
  .lg-top-margin-xl {
    margin-top: var(--tfd-spacing-xl);
  }
  .lg-top-margin-xxl {
    margin-top: var(--tfd-spacing-xxl);
  }
  .lg-top-margin-3xl {
    margin-top: var(--tfd-spacing-3xl);
  }
  .lg-top-margin-4xl {
    margin-top: var(--tfd-spacing-4xl);
  }

  .lg-bottom-margin-none {
    margin-bottom: 0;
  }
  .lg-bottom-margin-xxs {
    margin-bottom: var(--tfd-spacing-xxs);
  }
  .lg-bottom-margin-xs {
    margin-bottom: var(--tfd-spacing-xs);
  }
  .lg-bottom-margin-sm {
    margin-bottom: var(--tfd-spacing-sm);
  }
  .lg-bottom-margin-md {
    margin-bottom: var(--tfd-spacing-md);
  }
  .lg-bottom-margin-lg {
    margin-bottom: var(--tfd-spacing-lg);
  }
  .lg-bottom-margin-xl {
    margin-bottom: var(--tfd-spacing-xl);
  }
  .lg-bottom-margin-xxl {
    margin-bottom: var(--tfd-spacing-xxl);
  }
  .lg-bottom-margin-3xl {
    margin-bottom: var(--tfd-spacing-3xl);
  }
  .lg-bottom-margin-4xl {
    margin-bottom: var(--tfd-spacing-4xl);
  }

  .lg-right-margin-none {
    margin-right: 0;
  }
  .lg-right-margin-xxs {
    margin-right: var(--tfd-spacing-xxs);
  }
  .lg-right-margin-xs {
    margin-right: var(--tfd-spacing-xs);
  }
  .lg-right-margin-sm {
    margin-right: var(--tfd-spacing-sm);
  }
  .lg-right-margin-md {
    margin-right: var(--tfd-spacing-md);
  }
  .lg-right-margin-lg {
    margin-right: var(--tfd-spacing-lg);
  }
  .lg-right-margin-xl {
    margin-right: var(--tfd-spacing-xl);
  }
  .lg-right-margin-xxl {
    margin-right: var(--tfd-spacing-xxl);
  }
  .lg-right-margin-3xl {
    margin-right: var(--tfd-spacing-3xl);
  }
  .lg-right-margin-4xl {
    margin-right: var(--tfd-spacing-4xl);
  }

  .lg-left-margin-none {
    margin-left: 0;
  }
  .lg-left-margin-xxs {
    margin-left: var(--tfd-spacing-xxs);
  }
  .lg-left-margin-xs {
    margin-left: var(--tfd-spacing-xs);
  }
  .lg-left-margin-sm {
    margin-left: var(--tfd-spacing-sm);
  }
  .lg-left-margin-md {
    margin-left: var(--tfd-spacing-md);
  }
  .lg-left-margin-lg {
    margin-left: var(--tfd-spacing-lg);
  }
  .lg-left-margin-xl {
    margin-left: var(--tfd-spacing-xl);
  }
  .lg-left-margin-xxl {
    margin-left: var(--tfd-spacing-xxl);
  }
  .lg-left-margin-3xl {
    margin-left: var(--tfd-spacing-3xl);
  }
  .lg-left-margin-4xl {
    margin-left: var(--tfd-spacing-4xl);
  }
}
@media (--viewport-xl) {
  .xl-top-margin-none {
    margin-top: 0;
  }
  .xl-top-margin-xxs {
    margin-top: var(--tfd-spacing-xxs);
  }
  .xl-top-margin-xs {
    margin-top: var(--tfd-spacing-xs);
  }
  .xl-top-margin-sm {
    margin-top: var(--tfd-spacing-sm);
  }
  .xl-top-margin-md {
    margin-top: var(--tfd-spacing-md);
  }
  .xl-top-margin-lg {
    margin-top: var(--tfd-spacing-lg);
  }
  .xl-top-margin-xl {
    margin-top: var(--tfd-spacing-xl);
  }
  .xl-top-margin-xxl {
    margin-top: var(--tfd-spacing-xxl);
  }
  .xl-top-margin-3xl {
    margin-top: var(--tfd-spacing-3xl);
  }
  .xl-top-margin-4xl {
    margin-top: var(--tfd-spacing-4xl);
  }

  .xl-bottom-margin-none {
    margin-bottom: 0;
  }
  .xl-bottom-margin-xxs {
    margin-bottom: var(--tfd-spacing-xxs);
  }
  .xl-bottom-margin-xs {
    margin-bottom: var(--tfd-spacing-xs);
  }
  .xl-bottom-margin-sm {
    margin-bottom: var(--tfd-spacing-sm);
  }
  .xl-bottom-margin-md {
    margin-bottom: var(--tfd-spacing-md);
  }
  .xl-bottom-margin-lg {
    margin-bottom: var(--tfd-spacing-lg);
  }
  .xl-bottom-margin-xl {
    margin-bottom: var(--tfd-spacing-xl);
  }
  .xl-bottom-margin-xxl {
    margin-bottom: var(--tfd-spacing-xxl);
  }
  .xl-bottom-margin-3xl {
    margin-bottom: var(--tfd-spacing-3xl);
  }
  .xl-bottom-margin-4xl {
    margin-bottom: var(--tfd-spacing-4xl);
  }

  .xl-right-margin-none {
    margin-right: 0;
  }
  .xl-right-margin-xxs {
    margin-right: var(--tfd-spacing-xxs);
  }
  .xl-right-margin-xs {
    margin-right: var(--tfd-spacing-xs);
  }
  .xl-right-margin-sm {
    margin-right: var(--tfd-spacing-sm);
  }
  .xl-right-margin-md {
    margin-right: var(--tfd-spacing-md);
  }
  .xl-right-margin-lg {
    margin-right: var(--tfd-spacing-lg);
  }
  .xl-right-margin-xl {
    margin-right: var(--tfd-spacing-xl);
  }
  .xl-right-margin-xxl {
    margin-right: var(--tfd-spacing-xxl);
  }
  .xl-right-margin-3xl {
    margin-right: var(--tfd-spacing-3xl);
  }
  .xl-right-margin-4xl {
    margin-right: var(--tfd-spacing-4xl);
  }

  .xl-left-margin-none {
    margin-left: 0;
  }
  .xl-left-margin-xxs {
    margin-left: var(--tfd-spacing-xxs);
  }
  .xl-left-margin-xs {
    margin-left: var(--tfd-spacing-xs);
  }
  .xl-left-margin-sm {
    margin-left: var(--tfd-spacing-sm);
  }
  .xl-left-margin-md {
    margin-left: var(--tfd-spacing-md);
  }
  .xl-left-margin-lg {
    margin-left: var(--tfd-spacing-lg);
  }
  .xl-left-margin-xl {
    margin-left: var(--tfd-spacing-xl);
  }
  .xl-left-margin-xxl {
    margin-left: var(--tfd-spacing-xxl);
  }
  .xl-left-margin-3xl {
    margin-left: var(--tfd-spacing-3xl);
  }
  .xl-left-margin-4xl {
    margin-left: var(--tfd-spacing-4xl);
  }
}
