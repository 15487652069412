.root {
  text-decoration: none;
  transition: color var(--animation-standard);
  background-color: transparent;
  color: var(--carrot-2);
}

.root:hover {
  color: var(--carrot-3);
  text-decoration: none;
}

.root:active {
  color: var(--carrot-3);
}

.root[aria-disabled='true'] {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.carrot {
  color: var(--carrot-2);
}

.kale {
  color: var(--kale-3);
}

.blueberry {
  color: var(--blueberry-3);
}

.white {
  color: var(--white);
}
