.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.discountRequested {
  margin-bottom: calc(var(--tfd-spacing-md) * -1);
}

.coreTenets {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--tfd-spacing-sm);

  @media (--viewport-lg) {
    gap: var(--tfd-spacing-md);
    grid-template-columns: repeat(4, 1fr);
  }
}
