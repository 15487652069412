.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--tfd-spacing-xl);
  min-height: 85vh;
  margin: auto;
  justify-content: space-between;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85vh;
  margin: auto;
  justify-content: center;
}

.title {
  text-align: center;

  @media (--viewport-lg) {
    font-size: 20px;
  }
}

.link {
  text-decoration: underline;
  color: var(--kale-2);

  &:hover {
    color: var(--kale-2);
    text-decoration: underline;
  }
}
