.nav {
  composes: nav from './AppBar.css';
  justify-content: left;
}

.nav > * {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
}

.items {
  display: flex;
  align-items: center;
  list-style: none;
  padding-left: 0;
}

.items.left {
  flex: 1;
}

.items.left .link {
  margin-left: var(--margin-lg);
}

.items.right {
  justify-content: flex-end;
}

.items.right .link,
.items.callToAction {
  margin-right: var(--margin-lg);
}

.link.active {
  color: var(--carrot-3);
}

.horizontalNavContainer {
  padding: var(--margin-sm) 0;
  margin-top: var(--margin-sm);
  white-space: nowrap;
}

.horizontalNavContentRow {
  display: flex;
}
