.normal {
  font-weight: 400;
}

.roman {
  font-weight: 400;
}

.bold,
.bold strong {
  font-weight: 700;
}

