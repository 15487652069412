.educationItemsContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--tfd-spacing-md);
}

@media (--viewport-md) {
  .educationItemsContainer {
    grid-template-columns: 1fr 1fr;
  }
}
