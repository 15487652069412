:root {
  --article: tiempos, serif;
}

@font-face {
  font-family: tiempos;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/tiempos-text-web-regular.woff2) format('woff2'),
    url(../assets/tiempos-text-web-regular.woff) format('woff');
}

@font-face {
  font-family: tiempos;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/tiempos-text-web-regular-italic.woff2) format('woff2'),
    url(../assets/tiempos-text-web-regular-italic.woff) format('woff');
}

@font-face {
  font-family: tiempos;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../assets/tiempos-text-web-bold.woff2) format('woff2'),
    url(../assets/tiempos-text-web-bold.woff) format('woff');
}

@font-face {
  font-family: tiempos;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../assets/tiempos-text-web-bold-italic.woff2) format('woff2'),
    url(../assets/tiempos-text-web-bold-italic.woff) format('woff');
}

.family {
  font-family: var(--article);
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}
