.button {
  font-family: var(--tfd-font-family);
  padding: 0 var(--tfd-spacing-xs);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--kale-3);
  border-radius: var(--tfd-fieldlabel-radius);
  margin-right: var(--tfd-fieldlabel-padding);
  margin-top: var(--tfd-fieldlabel-padding);
  color: var(--white);
}

.icon {
  margin-left: var(--tfd-spacing-xs);
  pointer-events: none;
}
