.root {
  border: 0;
}

.horizontal {
  min-width: 100%;
  border-top-width: 2px;
}

.vertical {
  border-left-width: 2px;
  min-height: 100%;
  display: inline-block;
}
