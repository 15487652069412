.title {
  color: var(--kale-3);
  margin-top: var(--margin-lg);
  margin-bottom: 0;
}

.subtitle {
  margin-top: 0;
  margin-bottom: 0;
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
