.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 var(--margin-md);
  padding-top: var(--margin-lg);

  @media (--viewport-md) {
    width: 588px;
    margin: 0 auto;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (--viewport-lg) {
    max-width: 600px;
  }
}

.formsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--margin-xl);
}

.termsAndConditions > div:first-child {
  margin-top: 0;
}

.signupFormCard {
  margin-bottom: var(--margin-md) !important;
}

.registerButtonContainer {
  @media (--viewport-md) {
    width: min-content;
    margin: auto;
  }
}

.submitButton {
  margin-bottom: var(--margin-xl);
  box-sizing: border-box;
  width: 100%;
  margin-top: var(--margin-lg);
  /* override default padding for ux overhaul feature */
  padding: 14px 24px !important;

  @media (--viewport-md) {
    width: 160px;
  }
}

.loading,
.submitted {
  display: flex;
  flex-direction: column;
  padding-top: var(--margin-lg);
  height: 100vh;
}

.submitted .logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.loadingPractice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 206px;
}

.activityIndicator {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  margin-bottom: var(--margin-lg);
}

.willEatAnything {
  width: 155px;
  height: 88px;
}

.conferenceSpacing {
  margin-bottom: var(--margin-md);
}

.standardSpacing {
  margin-bottom: var(--margin-xl);
}

.subtitleLink {
  display: block;
}
