.button {
  composes: button from '../../styles/button.css';
  position: relative;

  padding: 6px;
  cursor: pointer;
  background: none;
  transition: opacity 0.15s ease-in-out;
  padding: 0 var(--margin-md);
  height: var(--margin-xl);
}

.button:hover {
  opacity: 0.5;
}

.button svg {
  width: 20px;
  height: 20px;
  fill: var(--slate-grey);
}
