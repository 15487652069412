.container {
    margin: var(--tfd-spacing-xl) auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    row-gap: var(--tfd-spacing-md);

    @media (--viewport-md) {
        padding: 0;
        width: 587px;
    }
}

.title {
    text-align: center;
}

.container>div {
    margin-top: 0;
}

.container>span {
    width: 100%;
    text-align: center;

    @media (--viewport-md) {
        width: 384px;
    }
}



.svgContainer {
    height: 72px;
    width: 67.96px;
}

.attestationMessage label {
    align-items: inherit;

}


.mobile {
    display: block;
    padding: 0 var(--tfd-spacing-md);

    @media (--viewport-lg) {
        display: none;
    }
}

.desktop {
    display: none;

    @media (--viewport-lg) {
        display: block;
    }
}
