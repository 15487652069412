.heading {
  @media (--viewport-lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.otherFormulaCardsContainer {
  display: flex;
  flex-direction: column;
  margin-top: var(--tfd-spacing-lg);
  margin-bottom: var(--tfd-spacing-lg);
  gap: var(--tfd-spacing-md);

  @media (--viewport-lg) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.otherFormulaCardContainer {
  text-align: left;

  @media (--viewport-lg) {
    width: 100%;
  }
}

.aboutOurFormulas {
  margin-top: var(--tfd-spacing-xl);
  margin-bottom: var(--tfd-spacing-lg);
}

.tenetsContainer {
  display: grid;
  width: 100%;
  gap: var(--tfd-spacing-md);
  grid-template-columns: 1fr;

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
  }
}

a.browseMoreOptionsBottom {
  display: flex;
  align-items: center;
}

a.guide {
  display: flex;
  column-gap: var(--tfd-spacing-xs);
}

.svg {
  height: 20px;
  width: 20px;
  fill: currentColor;
}
