/* accordion panel override */
.accordion > div {
  border-top: unset;
  border-bottom: 1px solid var(--blueberry-1) !important;
}

.accordion > div:first-child > button:first-child {
  margin-top: 0;
}

.accordion button {
  text-align: left;
}
.panel {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--tfd-spacing-md);
}

@media (--viewport-md) {
  .panel {
    grid-template-columns: repeat(2, 1fr);
    column-gap: var(--tfd-spacing-md);
    align-items: start;
  }
}
