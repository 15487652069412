.login {
  padding: var(--margin-xl) 0;
}

.loginButton {
  min-width: 160px !important;
  /* override default padding for ux overhaul feature */
  padding: 14px 24px !important;
  box-sizing: border-box;
}
