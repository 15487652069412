:root {
  --input-padding: var(--margin-sm);
}

.input {
  /** Show the overflow in IE */
  overflow: visible;
  box-sizing: border-box;
  /** Remove the margin in Firefox and Safari */
  margin: 0;
  padding: var(--input-padding);
  width: 100%;
  height: 100%;
  display: block;
  background: transparent;
  border: none;
  appearance: none;
  /* This is needed or else autofill box shadow cuts off the border */
  border-radius: 4px;
  color: var(--kale-3);
}

.input:focus {
  outline: none;
}

.input:-webkit-autofill {
  box-shadow: inset 0 0 0 9999px var(--white);
}
