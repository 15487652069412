.container {
  display: flex;
  justify-content: center;
}

.padding {
  padding: var(--margin-md) 0;
}

@media (--viewport-md) {
  .padding {
    padding: var(--margin-md);
  }
}

.small,
.small.active {
  font-size: 12px;
  font-weight: normal;
  color: var(--charcoal-3);
}
