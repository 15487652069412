.lightbox {
  border-radius: var(--margin-xs);
  text-align: center;
}

.message {
  margin-top: var(--margin-sm);
}

.activity-indicator {
  margin: var(--margin-md) 0;
}
