.container,
.mobile,
.desktop {
  width: 100%;
}

.container {
  text-align: center;
}

.termsText {
  text-decoration: underline;
}

.button {
  width: 100%;
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
}

.buttons {
  margin-bottom: var(--margin-lg);
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.dogSvg {
  margin: 0 auto;
  width: 189px;
  height: 96px;

  @media (--viewport-sm) {
    width: 206px;
    height: 104px;
  }
}
