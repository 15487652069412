.cardContainer {
  padding: var(--tfd-spacing-md);
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.memberCardContainer > div,
.memberCardContainer > div > div {
  height: 100%;
}

.sharp {
  border-radius: 2px;
}

.openRolesCard {
  padding: var(--tfd-spacing-lg);
  background-color: var(--kale-2);
}

.dogIntimateSvg {
  display: flex;
  align-items: center;

  @media (--viewport-lg) {
    padding: 0px 66px 0px 0.149px;
  }
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
