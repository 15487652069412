.thumbnail {
  width: 100%;
  max-width: 279px;

  @media (--viewport-lg) {
    max-width: 120px;
  }
}

.textContainer {
  @media (--viewport-lg) {
    padding-left: var(--margin-md);
  }
}

.chickpeaBackground {
  background-color: var(--chickpea-0);
}
