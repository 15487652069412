.container {
  display: flex;
  flex-direction: column;
}

.percentage {
  height: 72px;
  width: 72px;
  border-radius: 36px;
  border: 1px solid var(--chickpea-2);
  background: var(--chickpea-0);
  display: flex;
  align-items: center;
  justify-content: center;
}
