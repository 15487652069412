.icon {
  display: block;
  margin: 0 auto;
  max-width: 184px;
}

@media (--viewport-lg) {
  .container {
    overflow-y: scroll;
    height: 100vh;
  }
  .icon {
    margin: 0;
  }
  .nav {
    position: sticky;
    top: 0;
  }
  .questionsContainer {
    max-width: 588px;
    position: relative;
    height: 100vh;
    top: 0;
  }
}

.card {
  padding-bottom: var(--tfd-spacing-md);
}
