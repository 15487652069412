.gap {
  --column-gap: 0px;
  --row-gap: 0px;
}

.col-gap {
  column-gap: var(--column-gap);
}

.row-gap {
  row-gap: var(--row-gap);
}

@media (--viewport-xs) {
  .xs-col-none {
    --column-gap: 0px;
  }
  .xs-col-xxs {
    --column-gap: var(--tfd-spacing-xxs);
  }
  .xs-col-xs {
    --column-gap: var(--tfd-spacing-xs);
  }
  .xs-col-sm {
    --column-gap: var(--tfd-spacing-sm);
  }
  .xs-col-md {
    --column-gap: var(--tfd-spacing-md);
  }
  .xs-col-lg {
    --column-gap: var(--tfd-spacing-lg);
  }
  .xs-col-xl {
    --column-gap: var(--tfd-spacing-xl);
  }
  .xs-col-xxl {
    --column-gap: var(--tfd-spacing-xxl);
  }
  .xs-col-3xl {
    --column-gap: var(--tfd-spacing-3xl);
  }
  .xs-col-4xl {
    --column-gap: var(--tfd-spacing-4xl);
  }

  .xs-row-none {
    --row-gap: 0px;
  }
  .xs-row-xxs {
    --row-gap: var(--tfd-spacing-xxs);
  }
  .xs-row-xs {
    --row-gap: var(--tfd-spacing-xs);
  }
  .xs-row-sm {
    --row-gap: var(--tfd-spacing-sm);
  }
  .xs-row-md {
    --row-gap: var(--tfd-spacing-md);
  }
  .xs-row-lg {
    --row-gap: var(--tfd-spacing-lg);
  }
  .xs-row-xl {
    --row-gap: var(--tfd-spacing-xl);
  }
  .xs-row-xxl {
    --row-gap: var(--tfd-spacing-xxl);
  }
  .xs-row-3xl {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .xs-row-4xl {
    --row-gap: var(--tfd-spacing-4xl);
  }
}

@media (--viewport-sm) {
  .sm-col-none {
    --column-gap: 0px;
  }
  .sm-col-xxs {
    --column-gap: var(--tfd-spacing-xxs);
  }
  .sm-col-xs {
    --column-gap: var(--tfd-spacing-xs);
  }
  .sm-col-sm {
    --column-gap: var(--tfd-spacing-sm);
  }
  .sm-col-md {
    --column-gap: var(--tfd-spacing-md);
  }
  .sm-col-lg {
    --column-gap: var(--tfd-spacing-lg);
  }
  .sm-col-xl {
    --column-gap: var(--tfd-spacing-xl);
  }
  .sm-col-xxl {
    --column-gap: var(--tfd-spacing-xxl);
  }
  .sm-col-3xl {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .sm-col-4xl {
    --row-gap: var(--tfd-spacing-4xl);
  }

  .sm-row-none {
    --row-gap: 0px;
  }
  .sm-row-xxs {
    --row-gap: var(--tfd-spacing-xxs);
  }
  .sm-row-xs {
    --row-gap: var(--tfd-spacing-xs);
  }
  .sm-row-sm {
    --row-gap: var(--tfd-spacing-sm);
  }
  .sm-row-md {
    --row-gap: var(--tfd-spacing-md);
  }
  .sm-row-lg {
    --row-gap: var(--tfd-spacing-lg);
  }
  .sm-row-xl {
    --row-gap: var(--tfd-spacing-xl);
  }
  .sm-row-xxl {
    --row-gap: var(--tfd-spacing-xxl);
  }
  .sm-row-3xl {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .sm-row-4xl {
    --row-gap: var(--tfd-spacing-4xl);
  }
}

@media (--viewport-md) {
  .md-col-none {
    --column-gap: 0px;
  }
  .md-col-xxs {
    --column-gap: var(--tfd-spacing-xxs);
  }
  .md-col-xs {
    --column-gap: var(--tfd-spacing-xs);
  }
  .md-col-sm {
    --column-gap: var(--tfd-spacing-sm);
  }
  .md-col-md {
    --column-gap: var(--tfd-spacing-md);
  }
  .md-col-lg {
    --column-gap: var(--tfd-spacing-lg);
  }
  .md-col-xl {
    --column-gap: var(--tfd-spacing-xl);
  }
  .md-col-xxl {
    --col-gap: var(--tfd-spacing-xxl);
  }
  .md-col-3xl {
    --col-gap: var(--tfd-spacing-3xl);
  }
  .md-col-4xl {
    --col-gap: var(--tfd-spacing-4xl);
  }

  .md-row-none {
    --row-gap: 0px;
  }
  .md-row-xxs {
    --row-gap: var(--tfd-spacing-xxs);
  }
  .md-row-xs {
    --row-gap: var(--tfd-spacing-xs);
  }
  .md-row-sm {
    --row-gap: var(--tfd-spacing-sm);
  }
  .md-row-md {
    --row-gap: var(--tfd-spacing-md);
  }
  .md-row-lg {
    --row-gap: var(--tfd-spacing-lg);
  }
  .md-row-xl {
    --row-gap: var(--tfd-spacing-xl);
  }
  .md-row-xxl {
    --row-gap: var(--tfd-spacing-xxl);
  }
  .md-row-3xl {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .md-row-4xl {
    --row-gap: var(--tfd-spacing-4xl);
  }
}

@media (--viewport-lg) {
  .lg-col-none {
    --colum-gap: 0px;
  }
  .lg-col-xxs {
    --column-gap: var(--tfd-spacing-xxs);
  }
  .lg-col-xs {
    --column-gap: var(--tfd-spacing-xs);
  }
  .lg-col-sm {
    --column-gap: var(--tfd-spacing-sm);
  }
  .lg-col-md {
    --column-gap: var(--tfd-spacing-md);
  }
  .lg-col-lg {
    --column-gap: var(--tfd-spacing-lg);
  }
  .lg-col-xl {
    --column-gap: var(--tfd-spacing-xl);
  }
  .lg-col-xxl {
    --row-gap: var(--tfd-spacing-xxl);
  }
  .lg-col-3xl {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .lg-col-4xl {
    --row-gap: var(--tfd-spacing-4xl);
  }

  .lg-row-none {
    --row-gap: 0px;
  }
  .lg-row-xxs {
    --row-gap: var(--tfd-spacing-xxs);
  }
  .lg-row-xs {
    --row-gap: var(--tfd-spacing-xs);
  }
  .lg-row-sm {
    --row-gap: var(--tfd-spacing-sm);
  }
  .lg-row-md {
    --row-gap: var(--tfd-spacing-md);
  }
  .lg-row-lg {
    --row-gap: var(--tfd-spacing-lg);
  }
  .lg-row-xl {
    --row-gap: var(--tfd-spacing-xl);
  }
  .lg-row-xxl {
    --row-gap: var(--tfd-spacing-xxl);
  }
  .lg-row-3xl {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .lg-row-4xl {
    --row-gap: var(--tfd-spacing-4xl);
  }
}
@media (--viewport-xl) {
  .xl-col-none {
    --column-gap: 0px;
  }
  .xl-col-xxs {
    --column-gap: var(--tfd-spacing-xxs);
  }
  .xl-col-xs {
    --column-gap: var(--tfd-spacing-xs);
  }
  .xl-col-sm {
    --column-gap: var(--tfd-spacing-sm);
  }
  .xl-col-md {
    --column-gap: var(--tfd-spacing-md);
  }
  .xl-col-lg {
    --column-gap: var(--tfd-spacing-lg);
  }
  .xl-col-xl {
    --column-gap: var(--tfd-spacing-xl);
  }
  .xl-col-xxl {
    --column-gap: var(--tfd-spacing-xxl);
  }
  .xl-col-3xl {
    --column-gap: var(--tfd-spacing-3xl);
  }
  .xl-col-4xl {
    --column-gap: var(--tfd-spacing-4xl);
  }

  .xl-row-none {
    --row-gap: 0px;
  }
  .xl-row-xxs {
    --row-gap: var(--tfd-spacing-xxs);
  }
  .xl-row-xs {
    --row-gap: var(--tfd-spacing-xs);
  }
  .xl-row-sm {
    --row-gap: var(--tfd-spacing-sm);
  }
  .xl-row-md {
    --row-gap: var(--tfd-spacing-md);
  }
  .xl-row-lg {
    --row-gap: var(--tfd-spacing-lg);
  }
  .xl-row-xl {
    --row-gap: var(--tfd-spacing-xl);
  }
  .xl-row-xxl {
    --row-gap: var(--tfd-spacing-xxl);
  }
  .xl-row-3xl {
    --row-gap: var(--tfd-spacing-3xl);
  }
  .xl-row-4xl {
    --row-gap: var(--tfd-spacing-4xl);
  }
}
