.container {
  overflow: visible;
  z-index: var(--z-index-app-bar);
  position: sticky;
  top: 0;
}

.container.static {
  position: static;
}

.container.isOpen {
  position: fixed;
  width: 100%;
}

.container.isOpen {
  z-index: calc(var(--z-index-modal) + 1);
}

.nav {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 74px;
  transition: background-color ease-in-out 300ms;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height ease-in-out 300ms;
}
