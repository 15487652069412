.container,
.container *,
.container *::before,
.container *::after {
  box-sizing: border-box;
}

.container {
  cursor: pointer;
  position: relative;
  flex: 1;
}
