.page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
}

.loadingContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
