.container {
  display: flex;
  width: 384px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  text-align: center;
  gap: var(--tfd-spacing-md);
}

.container h1 {
  margin: 0;
}

.registerButton {
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
}
