.title {
  margin-top: 0;
  margin-bottom: var(--margin-sm);
}

.subtitle {
  margin: 0;
}

.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.titlesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
