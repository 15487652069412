.root {
  --default-color: var(--charcoal-1);
  --hover-color: var(--kale-3);
  --checked-color: var(--kale-3);
  --focused-color: var(--kale-3);
  --disabled-color: var(--oyster-3);

  display: block;
  max-width: 327px;
  box-sizing: content-box;
  position: relative;

  /* Padding for the badge */
  padding-top: calc(var(--tfd-spacing-sm) / 2);
}

.root:focus-visible {
  outline: none;
}

.root:hover {
  --default-color: var(--hover-color);
}

.root.badge-default {
  --badge-color: var(--charcoal-3);
}

.root:hover.badge-default {
  --badge-color: var(--hover-color);
}

.root.badge-recommended {
  --badge-color: var(--carrot-2);
}

.root + .root {
  margin-top: calc(var(--tfd-spacing-md) - (var(--tfd-spacing-sm) / 2));
}

.root [role='status'] {
  position: absolute;
  top: calc(var(--tfd-spacing-sm) / 2);
  left: var(--tfd-spacing-md);
  transform: translateY(-50%);
}

.container {
  transition: border-color var(--tfd-animation-standard-duration)
    var(--tfd-animation-standard-timing-function);
  border: 1px solid var(--default-color);
  border-radius: var(--tfd-border-radius-sm);
  cursor: pointer;
  padding: var(--tfd-spacing-md);
}

.grid {
  display: flex;
  align-items: center;
}

.grid > div:first-child {
  flex-grow: 1;
  overflow: hidden;
}

.grid > div:last-child {
  flex-grow: 0;
  display: flex;
}

.input:focus-visible ~ .container {
  --default-color: var(--focused-color);
  border: 1px solid var(--checked-color);
  box-shadow: var(--tfd-outline-box-shadow);
}

.input:focus-visible ~ [role='status'] {
  box-shadow: var(--tfd-outline-box-shadow);
}

.input:disabled ~ [role='status'] {
  --badge-color: var(--disabled-color);
}

.input:checked ~ .container {
  border: 1px solid var(--checked-color);
  --default-color: var(--checked-color) !important;
}

.root.badge-default .input:checked ~ [role='status'] {
  --badge-color: var(--checked-color);
}

.root.badge-default .input:focus-visible ~ [role='status'] {
  --badge-color: var(--focused-color);
}

.input:disabled ~ .container {
  border: 1px solid var(--disabled-color);
  cursor: default;
}

.input:disabled ~ .container .label,
.input:disabled ~ .container .supportCopy {
  color: var(--disabled-color);
}

.label {
  display: block;
  line-height: 32px;
  color: var(--checked-color);
  white-space: nowrap;
  overflow: hidden;
  padding-right: var(--tfd-spacing-sm);
  text-overflow: ellipsis;
}

.supportCopy {
  line-height: 26px;
  color: var(--charcoal-3);
  padding-right: var(--tfd-spacing-sm);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
