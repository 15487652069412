.container {
  display: grid;

  column-gap: var(--tfd-spacing-md);
  text-align: left;
  align-items: center;

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.container div:first-child {
  grid-column: auto / span 2;
}

.container div:nth-child(2) {
  grid-column: auto / span 3;
}

.video {
  min-height: auto;
  max-height: 150px;
}

.video div {
  max-height: 150px;
}

.videoLink button {
  border: 1px solid var(--blueberry-2);
}

.video img {
  width: 100% !important;
  height: 100% !important;
  left: 0% !important;
  top: 0% !important;
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--tfd-spacing-xs);
  height: 100%;
}

.viewDetailsButton {
  text-align: left;
}
