/* 
Use these variables for using our color palette. Use Color.ts to access these values in TypeScript. 
We keep these variables in a single file so that we parse it to test it matches the `Color` enum.
*/
:root {
  --blueberry-0: #e6ecf5;
  --blueberry-1: #b7c6e6;
  --blueberry-2: #385a9d;
  --blueberry-3: #03184e;

  --carrot-0: #fff4f1;
  --carrot-1: #ffd9cc;
  --carrot-2: #f2674b;
  --carrot-3: #d2563d;

  --charcoal-0: #c2c3c5;
  --charcoal-1: #969799;
  --charcoal-2: #717377;
  --charcoal-3: #39383c;

  --chickpea-0: #fffaf3;
  --chickpea-1: #fff3e1;
  --chickpea-2: #f2e1c8;
  --chickpea-3: #d3bfa2;

  --kale-0: #e5efe7;
  --kale-1: #b5d3ba;
  --kale-2: #417c67;
  --kale-3: #173b33;
  --kale-4: #3c725e;

  --oyster-0: #f6f5f3;
  --oyster-1: #efedea;
  --oyster-2: #dbd6d1;
  --oyster-3: #c6bfb7;

  --white: #ffffff;
  --error-red: #e31c3d;
  --error-red-dark: #df0b1c;
  --error-red-bg: #fef1f1;
  --tfd-logo: #f5694d;

  --twitter: #1da1f2;
  --facebook: #3b5998;
  --instagram: #6ba5d5;
}
