.container {
  display: grid;
  margin-top: var(--tfd-spacing-lg);
  grid-template-columns: 1fr 1fr;
  row-gap: var(--tfd-spacing-md);

  list-style: none;
  padding: 0;
}

@media (--viewport-lg) {
  .container {
    grid-template-columns: repeat(4, 1fr);
  }
}
