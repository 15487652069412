.solution {
  max-width: 371px;
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--oyster-2);
  padding: var(--margin-lg) var(--margin-md);
  @media (--viewport-lg) {
    max-width: 233px;
  }
}

.icon {
  left: 50%;
  top: -36px;
  background: white;
  padding: 7px;
  width: 55px;
  position: absolute;
  transform: translateX(-50%);
}

.title {
  margin-top: var(--margin-sm);
  margin-bottom: var(--margin-md);
}
