.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 var(--margin-md);
  padding-top: var(--margin-lg);
  text-align: center;
  @media (--viewport-lg) {
    padding-top: var(--margin-xxl);
  }
}
