.root {
  display: inline-block;
  border-radius: var(--tfd-border-radius-xs);
  background-color: var(--badge-color);
  padding: var(--tfd-togglebox-badge-padding);
  color: var(--white);
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: background-color;
}
