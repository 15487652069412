.button {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  transition: background 100ms ease;
}

.button + .button {
  margin-left: var(--margin-xs);
}

.button.dark {
  background: var(--oyster-0);
  border: 1px solid var(--charcoal-0);
}

.button.dark.active {
  background: var(--charcoal-0);
  border: 1px solid var(--charcoal-0);
}

.button.darker {
  background: transparent;
  border: 1px solid var(--kale-3);
}

.button.darker.active {
  background: var(--kale-3);
}

.button.light {
  background: var(--oyster-0);
  border: 1px solid var(--oyster-0);
}

.button.light.active {
  background: var(--kale-1);
  border: 1px solid var(--kale-1);
}

.button.green {
  width: 10px;
  height: 10px;
  background: var(--white);
  border: 1px solid var(--white);
}

.button.green.active {
  background: var(--kale-3);
  border: 1px solid var(--kale-3);
}

.button.green + .button.green {
  margin-left: 5px;
}
