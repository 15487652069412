.title {
  margin: var(--margin-sm) auto;

  max-width: 333px;

  @media (--viewport-lg) {
    margin: 0 0 var(--margin-sm) 0;
  }

}

.subtitle {
  margin: 0;
}

.container {
  margin: auto;

  @media (--viewport-lg) {
    display: flex;
    text-align: left;
    max-width: 580px;
    gap: var(--margin-md);
  }
}

.icon {
  max-height: 80px;

  @media (--viewport-lg) {
    width: 80px;
  }
}
