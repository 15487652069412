.name {
  margin: 0 0;
}

.education {
  margin: 0 0;
}

.position {
  margin: var(--tfd-spacing-xs) 0 0 0;
}

.profileAndTitle {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;

  @media (--viewport-lg) {
    min-height: 96px;
  }
}

.profile {
  width: auto;
}

.title {
  padding-right: var(--tfd-spacing-md);
}

.shortDescription {
  margin-top: var(--tfd-spacing-md);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
