/* Please note this CSS uses variables set for the parent */
.root {
  /* -webkit-transform fixes a glitch in Safari, causing the svg content to subtly move when transitioning the opacity */
  -webkit-transform: translate3d(0, 0, 0);
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: var(--tfd-border-radius-sm);
  /* Note that --default-color changes when the ToggleBox root is hovered */
  border: solid 1px var(--default-color);
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: border-color, background-color, box-shadow;
}

.root > svg {
  opacity: 0;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: opacity;
}

.root.checked {
  border-color: var(--checked-color);
  background: var(--checked-color);
}

.root.checked > svg {
  opacity: 1;
}

.root.disabled {
  border-color: var(--disabled-color);
}

.root.disabled.checked {
  background: var(--disabled-color);
}
