.container {
  --logo-width: 40px;
  --logo-height: 25px;
}

.logoContainer {
  margin: 0 var(--tfd-fieldlabel-padding);
  flex: 0 0 var(--logo-width);
  display: flex;
  align-items: center;
}

.logoContainer img {
  width: var(--logo-width);
  height: var(--logo-height);
}

.amex {
  content: url('./assets/american-express.png');
}

.diners {
  content: url('./assets/diners-club.png');
}

.discover {
  content: url('./assets/discover.png');
}

.jcb {
  content: url('./assets/jcb.png');
}

.unknown {
  content: url('./assets/unknown.png');
}

.unionpay {
  content: url('./assets/unionpay.png');
}

.visa {
  content: url('./assets/visa.png');
}

.mastercard {
  content: url('./assets/mastercard.png');
}
