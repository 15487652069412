.root {
  display: inline-flex;
  flex-direction: column;
  margin-right: var(--tfd-spacing-xs);
}
.root > button {
  flex: 1 0 0;
  height: 22px;
  padding: 3px 6px;
  display: flex;
}

.root > button path {
  fill: var(--kale-3);
}

.root > button:first-child {
  align-items: flex-end;
}

.root.disabled > button path {
  fill: var(--charcoal-0);
}
