.button {
  border-radius: 4px;
  letter-spacing: 0.5px;
}

.button.primary {
  line-height: 22px;
  padding: 14px var(--margin-md);
}

.button.secondary {
  line-height: 22px;
  padding: 12px var(--margin-md);
}

.mini.primary {
  padding: 7px var(--margin-sm);
}

.mini.secondary {
  padding: 5px var(--margin-sm);
}

.pill {
  border-radius: var(--margin-md);
}

/* Variant: primary */

.button.primary {
  background-color: var(--carrot-2);
  color: var(--white);
}

/* `.hover` and `.active` classes are used only for testing purposes */
.button.primary:hover,
.button.primary.hover {
  background-color: var(--carrot-3);
}

.button.primary:active,
.button.primary.active {
  background-color: var(--carrot-3);
}

/**
 * Since we use `disabled={true}` for the loading state, to style disabled
 * buttons we will target `.disabled` instead of the pseudo-selector.
 */
.button.primary.disabled {
  background-color: var(--charcoal-0);
}

/* Variant: secondary */

.button.secondary {
  border: solid 2px var(--carrot-2);
  background-color: var(--white);
  color: var(--carrot-2);
}

.button.secondary:hover,
.button.secondary.hover {
  border: solid 2px var(--carrot-3);
  background-color: var(--white);
  color: var(--carrot-3);
}

.button.secondary:active,
.button.secondary.active {
  border: solid 2px var(--carrot-3);
  background-color: var(--white);
  color: var(--carrot-3);
}

.button.secondary.disabled {
  border: solid 2px var(--charcoal-0);
  background-color: var(--white);
  color: var(--charcoal-1);
}
