.container {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  box-sizing: border-box;
  padding: var(--tfd-spacing-md);
}

@media (--viewport-md) {
  .container {
    height: 300px;
  }
}

@media (--viewport-lg) {
  .container {
    height: 282px;
    padding: var(--tfd-spacing-lg);
  }
}
