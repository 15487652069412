.container {
}

.fixedSection {
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: var(--tfd-spacing-lg);
  @media (--viewport-lg) {
    flex-direction: row;
    align-items: start;
    column-gap: var(--tfd-spacing-xxl);
  }
}

.hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--tfd-spacing-md);
}

@media (--viewport-lg) {
  .hero {
    flex-direction: column;
    width: 384px;
    position: sticky;
    top: var(--tfd-appnav-height);
  }
}

.details {
  flex: 1;
}

.otherFormulasContainer {
  background-color: var(--blueberry-0);
  padding-top: var(--tfd-spacing-xl);
  padding-bottom: var(--tfd-spacing-xl);
}
