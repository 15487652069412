.buttonReset {
  box-sizing: border-box;
  appearance: none;
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  display: inline-block;
  color: inherit;

  -moz-appearance: none;
  -webkit-appearance: none;
}
.buttonReset:disabled,
.buttonReset[aria-disabled='true'] {
  cursor: default;
  pointer-events: none;
  user-select: none;
}
