:root {
  --modal-top-padding: 58px;
  --modal-top-padding-lg: 80px;
}

.modal {
  margin: auto var(--page-margin-xs);
  width: 100%;
  /** Do not allow modal to expand. Helpful in some flexbox and nowrap situations */
  max-width: calc(100% - (var(--page-margin-xs) * 2));
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
}

.non-wave {
  padding: var(--margin-md);
  padding-top: var(--modal-top-padding);
}

.wave-section {
  background-color: var(--chickpea-1);
  padding: var(--margin-md);
  padding-top: var(--modal-top-padding);
}

.below-wave-section {
  padding: var(--margin-md);
  padding-top: 0;
}

@media (--viewport-sm) {
  .modal {
    margin: auto var(--page-margin-sm);
    /** Do not allow modal to expand. Helpful in some flexbox and nowrap situations */
    max-width: calc(100% - (var(--page-margin-sm) * 2));
  }
}

@media (--viewport-md) {
  .modal {
    margin: auto;
    max-width: 630px;
  }

  .non-wave {
    padding: var(--margin-xl);
    padding-top: var(--modal-top-padding-lg);
  }

  .wave-section {
    padding: var(--margin-xl);
    padding-top: var(--modal-top-padding-lg);
    padding-bottom: var(--margin-md);
  }

  .below-wave-section {
    padding: var(--margin-xl);
    padding-top: 0;
  }
}

@media (--viewport-lg) {
  .modal {
    margin: auto;
    max-width: 716px;
  }

  .non-wave {
    padding: var(--margin-xl);
    padding-top: var(--modal-top-padding-lg);
  }

  .wave-section {
    padding: var(--margin-xl);
    padding-top: var(--modal-top-padding-lg);
    padding-bottom: var(--margin-md);
  }

  .below-wave-section {
    padding: var(--margin-xl);
    padding-top: 0;
  }
}
