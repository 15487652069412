.input {
  width: 100%;
  margin-top: 20px;
  max-width: var(--form-element-width);
}

.inputFullWidth {
  max-width: 100%;
}

.inputSmallWidth {
  @media (--viewport-xl) {
    max-width: 150px;
    margin-left: 28px;
  }
}
