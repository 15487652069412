.mobile {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.title {
  text-align: center;
}

.containerFields.splitForms {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--tfd-spacing-md);

  @media (--viewport-md) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.splitForms > div {
  grid-column: auto / span 2;
  max-width: inherit;
}

.splitForms > div:nth-last-child(2),
.splitForms > div:nth-last-child(3) {
  grid-column: auto / span 1;
  margin-left: 0;
}

.requestDiscountBtn {
  align-self: flex-end;
  /* have to override padding for the kale variant button */
  padding: 14px 24px !important;
}

.removeMargin {
  margin: 0;
}
