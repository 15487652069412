.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--tfd-spacing-md);
}

.container > picture:first-child {
  grid-column: auto / span 2;
}

.topImage,
.leftImage,
.rightImage {
  width: 100%;
  height: 100%;
}
