.modal {
  --modal-padding: var(--tfd-spacing-lg);
  --modal-max-width: 100%;
}

@media (--viewport-md) {
  .modal {
    --modal-max-width: 630px;
  }
}

@media (--viewport-lg) {
  .modal {
    --modal-max-width: 716px;
  }
}

.modal {
  max-width: var(--modal-max-width);
}

.modal.variant-default {
  padding: var(--modal-padding);
}

.modal.variant-wave {
  padding: 0;
}

.modal h1 {
  margin-top: var(--tfd-spacing-md);
}

.modal h1 + h2 {
  margin-top: var(--tfd-spacing-md);
}

.modal.variant-default h1 + h2 {
  margin-bottom: var(--tfd-spacing-md);
}

.closeButton {
  height: 36px;
  width: 36px;
}

.nav {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.wave > div {
  padding: var(--modal-padding);
}

.modal.variant-wave .content {
  padding: var(--modal-padding);
}
