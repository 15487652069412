.container {
  position: relative;
}

.children {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.labels {
  display: flex;
  justify-content: space-between;
}
