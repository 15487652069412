.caret {
  display: inline-block;
  height: 12px;
  margin: 0 var(--margin-sm);
  transform: rotate(180deg);
  fill: var(--charcoal-0);
}

.root {
  color: var(--charcoal-1);
}

.root a {
  text-decoration: none;
  color: var(--kale-3);
  transition: color 300ms ease-in-out;
}

.root a:hover {
  color: var(--carrot-3);
}
