.logo {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: inline-block;
}

.path {
  transition: all var(--animation-standard);
  transition-property: fill;
}
