.container {
  background-color: var(--white);
  padding: var(--tfd-spacing-sm);
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (--viewport-lg) {
  .container {
    padding: var(--tfd-spacing-md);
  }
}

.titleContainer {
  display: flex;
  width: 100%;
  flex: 1;
}

.titleContainer > h4 {
  flex: 1;
}

.titleContainer svg {
  width: 16px;
  height: 16px;
  align-self: flex-end;
}

.svgContainerExtraSmall svg {
  display: inline-flex;
  width: 36px;
  height: 36px;
}
