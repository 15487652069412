.container {
  width: auto;
  border: 1px solid var(--Colors-Oyster-Oyster1, #efedea);
  background-color: var(--Colors-Oyster-Oyster0, #f6f5f3);
  border-radius: 2px;
  padding: var(--tfd-spacing-sm);
  box-sizing: content-box;
  margin: 0;
  row-gap: var(--tfd-spacing-sm);
  align-content: start;
}

.image {
  position: relative;
  height: fit-content;
}

.pill {
  position: absolute;
  top: var(--tfd-spacing-sm);
  left: var(--tfd-spacing-sm);
}

.picture {
  border-radius: 2px;
  width: 100%;
  height: 100%;
}
