@import 'https://use.typekit.net/tlf2grf.css';

:root {
  --body: neue-haas-grotesk-text, sans-serif;
}

.family {
  font-family: var(--body);
  font-stretch: normal;
}
