.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  min-height: 360px;
  outline: unset;
}
.activityIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wistiaElement {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.12)
  );
}

.controls {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  width: 100%;
  top: 63%; /* Starts at the last third of its height */
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 var(--tfd-spacing-md);
  text-align: center;
}

.interactionBlocker {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
