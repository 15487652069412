.root > main,
.root > header {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.root > nav {
  padding: var(--tfd-spacing-md);
  max-width: var(--tfd-constraint-width-md);
}

.layout-base,
.layout-centered {
  padding: var(--tfd-spacing-md);
  background-color: var(--tfd-white);
}

.layout-base > nav,
.layout-centered > nav {
  padding: 0;
  padding-bottom: var(--tfd-spacing-md);
}

/* Base Layout */
/* Header and main have the same max width */

.layout-full-width > main {
  max-width: 100%;
}

.layout-base > main,
.layout-base > header {
  max-width: var(--tfd-constraint-width-md);
}

/* Centered layout */
/* - Header is centered only from medium screens or when there's an image  */
/* - Main is restricted to 5/12 the main width */

.layout-centered.with-image > header {
  text-align: center;
}

@media (--viewport-md) {
  .layout-centered > header {
    text-align: center;
    max-width: var(--tfd-constraint-width-md);
  }
  .layout-centered > main {
    max-width: var(--tfd-constraint-width-sm);
  }
}
