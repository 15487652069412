.table {
  width: 100%;
  border-spacing: 0;
}

.table tr td:first-child {
  text-align: left;
}

.table tr td {
  text-align: right;
  background-color: var(--chickpea-0);
}

td {
  padding: var(--tfd-spacing-sm);
}

.table tr:first-child td:first-child {
  border-top-left-radius: 22px;
}

.table tr td:first-child {
  border-left: 1px solid var(--chickpea-2);
}

.table tr:first-child td {
  border-top: 1px solid var(--chickpea-2);
}

.table tr td:last-child {
  border-right: 1px solid var(--chickpea-2);
}

.table tr:last-child td {
  border-bottom: 1px solid var(--chickpea-2);
}

.table tr:first-child td:last-child {
  border-top-right-radius: 22px;
}

.table tr:last-child td:first-child {
  border-bottom-left-radius: 22px;
}

.table tr:last-child td:last-child {
  border-bottom-right-radius: 22px;
}

.table tr:nth-child(even) td {
  background-color: var(--chickpea-1);
}
