.group {
  --image-size: 100px;

  margin-top: calc(var(--image-size) / 2);

  display: inline-flex;
  width: 100%;
  min-width: var(--image-size);
  box-sizing: content-box;
  position: relative;
}

.root:focus-visible {
  outline: none;
}

.badgeContainer {
  position: absolute;
  top: calc(-1 * var(--image-size) / 4);
  left: 50%;
  transform: translateY(-50%) translateX(-100%);
}

.badge {
  display: inline-block;
  border-radius: var(--tfd-border-radius-xs);
  background-color: var(--kale-3);
  padding: 0 var(--tfd-spacing-xs);
  line-height: 18px;
  color: var(--white);
}

.container {
  width: 100%;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--white);
  border-radius: var(--tfd-border-radius-lg);
}

.imageContainer {
  align-self: center;
}

.image {
  margin: 0 auto;
  transform: translateY(-50%);
  margin-bottom: calc(-1 * var(--image-size) / 2);
  max-height: var(--image-size);
  max-width: var(--image-size);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: var(--tfd-spacing-sm);
  padding: var(--tfd-spacing-sm) var(--tfd-spacing-md);
}

.contentTexts {
  display: flex;
  flex-direction: column;
  row-gap: var(--tfd-spacing-sm);
}

.content > .contentTexts > h5,
.content > .contentTexts > p {
  margin: 0;
}

.content > .button {
  text-align: left;
  font-weight: 500;
  white-space: normal;
}

.control {
  border-top: 2px solid var(--oyster-1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--tfd-spacing-sm);
  padding: var(--tfd-spacing-sm) var(--tfd-spacing-md);
}

.label {
  line-height: 32px;
  color: var(--kale-3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
