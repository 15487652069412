.title {
  width: 60%;

  @media (--viewport-sm) {
    width: 72%;
  }
  @media (--viewport-md) {
    width: 100%;
  }
}
