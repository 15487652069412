.container {
  padding: var(--tfd-spacing-md);
  border-radius: 2px;
  border: 1px solid var(--oyster-2);
  background: var(--oyster-0);
  margin-bottom: var(--tfd-spacing-md);

  @media (--viewport-lg) {
    display: flex;
    align-items: center;
  }
}

.icon {
  display: block;
  margin-left: var(--tfd-spacing-sm);
  max-width: 72px;
}

.container h6 {
  margin-bottom: 0;
  margin-top: var(--tfd-spacing-md);

  @media (--viewport-lg) {
    margin-top: var(--tfd-spacing-sm);
  }
}

.container p {
  margin-bottom: var(--tfd-spacing-md);
  @media (--viewport-lg) {
    width: 70%;
  }
}

.container div:nth-child(1) {
  @media (--viewport-lg) {
    flex-basis: 10%;
  }
}

.container div:nth-child(2) {
  @media (--viewport-lg) {
    flex-basis: 80%;
  }
}

.container div:nth-child(3) {
  @media (--viewport-lg) {
    flex-basis: 10%;
  }
}
