.container,
.container *,
.container *::before,
.container *::after {
  box-sizing: border-box;
}

.container {
  --tfd-checkbox-input-size: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.container.disabled {
  cursor: default;
}

.container + .container {
  margin-top: var(--tfd-spacing-sm);
}

.checkbox {
  outline: none;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  height: var(--tfd-checkbox-input-size);
  width: var(--tfd-checkbox-input-size);
  border-radius: var(--tfd-border-radius-sm);
  border: solid 1px var(--charcoal-2);
  pointer-events: none;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: border-color, background-color, box-shadow;
}

.checkbox > svg {
  opacity: 0;
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: opacity;
}

.container.checked .checkbox > svg {
  opacity: 1;
}

.container:hover:not(.disabled) .checkbox {
  border-color: var(--kale-2);
}

.container.disabled .checkbox {
  border-color: var(--charcoal-1);
}

.container.focus .checkbox {
  border-color: var(--kale-2);
  box-shadow: var(--tfd-outline-box-shadow);
}

.container.checked .checkbox {
  border-color: var(--kale-2);
  background: var(--kale-2);
}

.container.checked.disabled .checkbox {
  border-color: var(--charcoal-2);
  background: var(--charcoal-2);
}

.label {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2em;
  color: var(--charcoal-3);
  transition-timing-function: var(--tfd-animation-standard-timing-function);
  transition-duration: var(--tfd-animation-standard-duration);
  transition-property: color;
  padding-left: var(--tfd-spacing-sm);
}

.container.disabled:not(.checked) .label {
  color: var(--charcoal-2);
}
