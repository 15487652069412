.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.star {
  margin: 0 2px;
  height: var(--margin-md);
}
