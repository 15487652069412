.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-top: 4px dotted var(--white);
  padding-top: var(--margin-lg);

  @media (--viewport-lg) {
    flex-direction: row;
  }
}

.light {
  background-color: var(--kale-3);
}

.copyright {
  flex: 1;
  flex-direction: column;
  margin-bottom: var(--margin-md);

  @media (--viewport-lg) {
    margin-bottom: 0;
    align-self: flex-start;
    text-align: left;
    flex-direction: row;
  }
}

.legalDetails {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0 var(--margin-xs) 0;
  padding-left: 0px;
  list-style-type: none;

  @media (--viewport-lg) {
    text-align: right;
    flex-direction: row;
  }
}

.copyrightText + .copyrightText {
  margin: var(--margin-xs) 0 0 0;

  @media (--viewport-lg) {
    margin: 0 0 0 var(--margin-md);
  }
}

.link {
  margin: 0 0 var(--margin-md) 0;

  @media (--viewport-lg) {
    margin: 0 var(--margin-md) 0 0;
  }
}

.copyrightText,
.longLiveDogs {
  width: 100%;
  display: inline-block;

  @media (--viewport-lg) {
    width: auto;
  }
}

@media (--viewport-lg) {
  .longLiveDogs {
    margin-left: var(--margin-md);
    text-align: left;
  }

  .link:last-child {
    margin-right: 0;
  }
}
