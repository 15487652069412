.slides-container {
  overflow: hidden;
}

.slides {
  display: flex;
}

.slide {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 200px;
  transition: visibility var(--tfd-animation-standard);
}

.slides-container.animate .slides {
  transition: transform var(--tfd-animation-standard);
}
.slides-container.animate .slide {
  transition: visibility var(--tfd-animation-standard);
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}
