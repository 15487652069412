.root {
  position: relative;
  background-color: var(--white);
}

.root.isOpen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--tfd-appnav-z-index);
}

.logo {
  display: inline-block;
  position: relative;
  z-index: var(--tfd-appnav-z-index);
  transition: transform var(--tfd-animation-standard);
}

.logo a {
  display: inline-block;
}

.menuBackdrop {
  background-color: var(--white);
}
