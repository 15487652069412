.strikeout {
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  text-decoration: none;
}

.strikeout::before {
  position: absolute;
  content: '';
  left: 0;
  top: calc(50% - 2px);
  right: 0;
  border-top: 2px solid;
  border-color: currentColor;
}
