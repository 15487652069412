.container {
  position: relative;
  width: 100%;
}

.message {
  display: block;
  margin-top: var(--margin-xs);
  margin-left: var(--margin-sm);
}
