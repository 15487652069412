.layout {
  --tfd-layout-v-spacing: 0px; /* set the distance from top/bottom */
  --tfd-layout-h-spacing: 0px; /* set the distance from left/right */
}

.layout[data-theme='chickpea'],
.layout[data-theme='oyster'],
.layout[data-theme='kale'] {
  --tfd-layout-v-spacing: var(--tfd-spacing-lg);
  --tfd-layout-h-spacing: var(--tfd-spacing-md);
}

@media (--viewport-lg) {
  /* On large viewports, increment the horizontal spacing. */
  .layout[data-theme='chickpea'],
  .layout[data-theme='oyster'],
  .layout[data-theme='kale'] {
    --tfd-layout-v-spacing: var(--tfd-spacing-xl);
    --tfd-layout-h-spacing: var(--tfd-spacing-xl);
  }
}

.layout {
  background-color: var(--white);
}
.layout[data-theme='chickpea'] {
  background-color: var(--chickpea-1);
}
.layout[data-theme='kale'] {
  background-color: var(--kale-0);
}
.layout[data-theme='oyster'] {
  background-color: var(--oyster-1);
}

.header {
  position: sticky;
  top: 0;
  z-index: var(--tfd-appnav-z-index);
}

.content {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
}

.layout.withAppNav .content {
  /* Consider the height of the nav when calculating the height of the content. */
  min-height: calc(
    100vh - var(--tfd-appnav-height) - var(--tfd-banner-min-height)
  );
}
@media (--viewport-md) {
  .content {
    padding: var(--tfd-layout-v-spacing) var(--tfd-layout-h-spacing);
    display: flex;
  }
  .layout.withSubNav .content {
    max-width: var(--tfd-max-width);
  }
}

.content.shiftUp {
  /* Shift the main content up to account for the sticky header. */
  margin-top: calc(var(--tfd-appnav-height) * -1);
}

.main {
  padding: var(--tfd-layout-v-spacing) var(--tfd-layout-h-spacing);
  flex: 1 0 0;
}
.layout.withMenu .main {
  margin-top: var(--tfd-appnav-height);
}

.main > div > main > *:first-child {
  /* Fix spacings from components with top margin. */
  margin-top: 0;
}
@media (--viewport-md) {
  .main {
    padding: 0;
  }
}

.aside {
  display: none;
  position: sticky;
  top: 0px;
  flex: 0 1 33%;
  z-index: 1;
  align-self: flex-start;
}

[data-theme='kale'] .aside,
[data-theme='oyster'] .aside,
[data-theme='chickpea'] .aside {
  top: calc(var(--tfd-appnav-height) + var(--tfd-layout-v-spacing));
}

@media (--viewport-md) {
  .aside {
    display: block;
  }
}
/* Fixes for the NavBar paddings */
.aside > nav {
  display: block;
  position: relative;
  overflow-y: auto;
}
.aside > nav > ul {
  margin-top: 0;
}
.aside > nav > ul > li > span > a {
  margin-right: 0;
  padding: 0 var(--tfd-spacing-sm);
}
.aside > nav > ul > li:first-child > span > a {
  padding-left: var(--tfd-spacing-md);
}
.aside > nav > ul > li:last-child > span > a {
  padding-right: var(--tfd-spacing-md);
}
