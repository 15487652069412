.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform var(--animation-standard);
  position: relative;
}

.link {
  display: flex;
}
