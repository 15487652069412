.card {
  background-color: var(--blueberry-3);
  padding: var(--margin-md) !important;
}

.container {
  row-gap: var(--margin-md);
  @media (--viewport-lg) {
    display: flex;
    gap: var(--margin-md);
    align-items: center;
  }
}

.discountGiftSvg {
  height: 72px;
  width: 72px;
}

.texts {
  @media (--viewport-lg) {
    flex: 5;
  }
}

.text {
  @media (--viewport-lg) {
    display: inline-block;
    flex: 1 0 0;
  }
  letter-spacing: 0.5px;
}
