/**
  * This is a generated file. Do not modify directly.
  * Instead use generateKeyframes.js
  */
@keyframes spring-out {
  0% { transform: translate(0px, 0px); }
  2% { transform: translate(0px, -17.24px); }
  4% { transform: translate(0px, -39.42px); }
  6% { transform: translate(0px, -61.74px); }
  8% { transform: translate(0px, -81.11px); }
  10% { transform: translate(0px, -95.99px); }
  12% { transform: translate(0px, -105.99px); }
  14% { transform: translate(0px, -111.54px); }
  16% { transform: translate(0px, -113.49px); }
  18% { transform: translate(0px, -112.91px); }
  20% { transform: translate(0px, -110.78px); }
  22% { transform: translate(0px, -107.97px); }
  24% { transform: translate(0px, -105.1px); }
  26% { transform: translate(0px, -102.59px); }
  28% { transform: translate(0px, -100.64px); }
  30% { transform: translate(0px, -99.31px); }
  32% { transform: translate(0px, -98.56px); }
  34% { transform: translate(0px, -98.28px); }
  36% { transform: translate(0px, -98.34px); }
  38% { transform: translate(0px, -98.6px); }
  40% { transform: translate(0px, -98.95px); }
  42% { transform: translate(0px, -99.32px); }
  44% { transform: translate(0px, -99.65px); }
  46% { transform: translate(0px, -99.9px); }
  48% { transform: translate(0px, -100.08px); }
  50% { transform: translate(0px, -100.18px); }
  52% { transform: translate(0px, -100.22px); }
  56% { transform: translate(0px, -100.18px); }
  58% { transform: translate(0px, -100.14px); }
  60% { transform: translate(0px, -100.09px); }
  62% { transform: translate(0px, -100.05px); }
  64% { transform: translate(0px, -100.01px); }
  66% { transform: translate(0px, -99.99px); }
  100% { transform: translate(0px, -100px); }
}
