.size-72 {
  font-size: 72px;
  line-height: var(--tfd-heading-72-line-height);
}

.size-52 {
  font-size: 52px;
  line-height: var(--tfd-heading-52-line-height);
  
}

.size-40 {
  font-size: 40px;
  line-height: var(--tfd-heading-40-line-height);
}

.size-28 {
  font-size: 28px;
  line-height: var(--tfd-heading-28-line-height);
}

.size-22 {
  font-size: 22px;
  line-height: var(--tfd-heading-22-line-height);  
}

.size-21 {
  font-size: 21px;
  line-height: var(--tfd-heading-21-line-height);
}

.size-16 {
  font-size: 16px;
  line-height: var(--tfd-heading-16-line-height); 
}

.size-12 {
  font-size: 12px;
  line-height: var(--tfd-heading-12-line-height);
}

.size-1 {
  font-size: 48px;
  line-height: 1.25;
  letter-spacing: 0.5px;
}

.size-2 {
  font-size: 36px;
  line-height: 1.33;
  letter-spacing: 0.5px;
}

.size-3,
.size-4 {
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: 0.5px;
}

.size-5 {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.5px;
}

@media (--viewport-lg) {
  .size-1 {
    font-size: 66px;
    line-height: 1.27;
    letter-spacing: 0.5px;
  }
  
  .size-2 {
    font-size: 48px;
    line-height: 1.25;
    letter-spacing: 0.5px;
  }
  
  .size-3 {
    font-size: 36px;
    line-height: 1.33;
    letter-spacing: 0.5px;
  }
  
  .size-4 {
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: 0.5px;
  }

}

