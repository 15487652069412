.formContainer,
.buttonContainer,
.messagesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonContainer {
  row-gap: var(--margin-md);
  margin: 0 0 var(--margin-xl) 0;
}

.messagesContainer {
  width: 100%;
  margin: var(--margin-md) 0;
  align-items: center;
}

.inputSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.submitButton {
  width: var(--form-element-width);
  align-self: center;
}

.loginButton {
  margin: 0;
}

.formContainer {
  align-items: center;
}
