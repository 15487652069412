.content {
  width: var(--column-xs);
  box-sizing: border-box;
}

.content.start {
  margin-left: 0;
}

.content.end {
  margin-right: 0;
  margin-left: auto;
}

.content.center {
  margin-left: auto;
  margin-right: auto;
}

@media (--viewport-sm) {
  .content {
    width: var(--column-sm);
  }
}

@media (--viewport-md) {
  .content {
    width: var(--column-md);
  }
}

@media (--viewport-lg) {
  .content,
  .content.fr-1 {
    width: var(--column-lg);
  }

  .content.fr-2 {
    width: calc(var(--column-lg) * 2 + var(--gutter-lg));
  }

  .content.fr-3 {
    width: 100%;
  }

  .content.withPadding {
    padding: 0 var(--gutter-lg);
  }

  .content.withGutter {
    margin-right: var(--gutter-lg);
  }
}

@media (--viewport-xl) {
  .content,
  .content.fr-1 {
    width: var(--column-xl);
  }

  .content.fr-2 {
    width: calc(var(--column-xl) * 2 + var(--gutter-xl));
  }

  .content.fr-3 {
    width: 100%;
  }

  .content.withPadding {
    padding: 0 var(--gutter-xl);
  }

  .content.withGutter {
    margin-right: var(--gutter-xl);
  }
}
