.caret {
  display: inline-block;
  height: 12px;
  margin: 0 var(--margin-sm);
  transform: rotate(180deg);
  fill: var(--charcoal-0);
}

.active {
  color: var(--charcoal-1);
}
