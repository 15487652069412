.arrow {
  top: -22px;
  height: 21px;
  position: absolute;
  font-size: 16px;
}

.start {
  left: 7%;
}

.end {
  right: 12%;
  transform: rotateY(180deg);
}
