.navContainer {
  margin-top: var(--margin-xl);
  width: 90%;
  border-top: 1px solid var(--kale-2);
  padding: var(--margin-md) 0;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media (--viewport-lg) {
    width: 100%;
    padding: var(--margin-lg) 0;
    margin-top: var(--margin-xxl);
  }
}

.navContainer::-webkit-scrollbar {
  display: none;
}

.navList {
  padding: 0;
  display: flex;
  list-style: none;
  gap: var(--margin-lg);
  justify-content: start;
  margin: auto 0;
  /* accommodating ipad  */
  @media (--viewport-md) {
    justify-content: space-around;
  }
}

.navListItem {
  flex-shrink: 0;
}

.navListItem a {
  color: white;
}

.link {
  letter-spacing: 0.5;
  text-decoration: underline;
}
