.label {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: var(--tfd-fieldlabel-padding);
}

/** 
 * The important statements used in this file are needed so we beat Stripe's
 * inline styles.
 */
.input {
  width: 100%;
  display: inline-block;
}
