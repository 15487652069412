:root {
  --border-label-text-offset: 4px;
  /* The padding that must be applied to the interior element */
  --border-label-padding: 12px;
  --border-label-height: 50px;
  --border-label-radius: 4px;
}

.container {
  width: 100%;
  height: calc(var(--border-label-height) + var(--border-label-text-offset));
  box-sizing: border-box;
  padding-top: var(--border-label-text-offset);
  display: inline-block;
}

.label {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: var(--border-label-radius);
  border: solid 1px var(--charcoal-0);
  transition: all 100ms ease-in;
  transition-property: border-color;
  background-color: var(--white);
}

.normal {
  border-color: var(--charcoal-0);
}

.focus {
  border-color: var(--kale-3);
}

.invalid {
  border-color: var(--error-red);
}

.disabled {
  background-color: var(--oyster-0);
  border-color: var(--charcoal-0);
}

.highlighted {
  border: solid 2px var(--kale-1);
}
