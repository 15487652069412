.fieldset {
  border: 0;
  padding: 0;
  min-width: 0;
}

.fieldset + .fieldset > legend {
  padding-top: var(--tfd-spacing-md);
}

.fieldset > legend {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: var(--tfd-spacing-sm);
}
