.small,
.large {
  margin: 0;
}

.small {
  display: unset;
  @media (--viewport-xl) {
    display: none;
  }
}

.large {
  display: none;
  @media (--viewport-xl) {
    display: unset;
  }
}

.stepsBlock {
  display: flex;
  flex-direction: column;
  margin-top: var(--margin-lg);
  @media (--viewport-xl) {
    flex-direction: row;
    align-items: baseline;
  }
}

.stepsTitle {
  flex-shrink: 0;
  margin: 0 var(--margin-md) var(--margin-sm) 0;
  @media (--viewport-xl) {
    margin-bottom: 0;
  }
}
